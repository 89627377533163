import { useState, useEffect, useCallback } from 'react';

type StorageType = 'localStorage' | 'sessionStorage';

interface PersistOptions {
  storage?: StorageType;
  serializer?: (value: any) => string;
  deserializer?: (value: string) => any;
}

const defaultOptions: PersistOptions = {
  storage: 'localStorage',
  serializer: JSON.stringify,
  deserializer: JSON.parse,
};

/**
 * A custom hook for persisting values in browser storage (localStorage or sessionStorage)
 * @template T The type of the value to be stored
 * @param {string} key The key under which to store the value
 * @param {T} initialValue The initial value if nothing is stored
 * @param {PersistOptions} options Configuration options for storage behavior
 * @returns {[T, (value: T | ((prev: T) => T)) => void]} A tuple containing the current value and a setter function
 * 
 * @example
 * // Basic usage with default options
 * const [isToolbarOpen, setIsToolbarOpen] = usePersistedValue('toolbarState', false);
 * 
 * @example
 * // Usage with custom options
 * const [user, setUser] = usePersistedValue('userData', null, {
 *   storage: 'sessionStorage',
 *   serializer: (value) => btoa(JSON.stringify(value)),
 *   deserializer: (value) => JSON.parse(atob(value))
 * });
 * 
 * @example
 * // Usage with complex types
 * interface UserPreferences {
 *   theme: 'light' | 'dark';
 *   fontSize: number;
 * }
 * const [preferences, setPreferences] = usePersistedValue<UserPreferences>('userPrefs', {
 *   theme: 'light',
 *   fontSize: 14
 * });
 */
export function usePersistedValue<T>(
  key: string,
  initialValue: T,
  options: PersistOptions = {}
): [T, (value: T | ((prev: T) => T)) => void] {
  const { storage, serializer, deserializer } = { ...defaultOptions, ...options };

  // Initialize state by trying to get the persisted value
  const [state, setState] = useState<T>(() => {
    try {
      const storageObject = window[storage];
      const persistedValue = storageObject.getItem(key);
      
      if (persistedValue) {
        return deserializer!(persistedValue);
      }
      
      // If no persisted value, store and return initial value
      const serializedInitial = serializer!(initialValue);
      storageObject.setItem(key, serializedInitial);
      return initialValue;
    } catch (error) {
      console.warn(`Error reading persisted value for key "${key}":`, error);
      return initialValue;
    }
  });

  // Update storage when state changes
  useEffect(() => {
    try {
      const storageObject = window[storage];
      const serializedValue = serializer!(state);
      storageObject.setItem(key, serializedValue);
    } catch (error) {
      console.error(`Error persisting value for key "${key}":`, error);
    }
  }, [key, state, storage, serializer]);

  return [state, setState];
}

// Type guard to check if storage is available
function isStorageAvailable(type: StorageType): boolean {
  try {
    const storage = window[type];
    const testKey = '__storage_test__';
    storage.setItem(testKey, testKey);
    storage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}

export function createPersistedValue<T>(key: string, options?: PersistOptions) {
  return (initialValue: T) => usePersistedValue<T>(key, initialValue, options);
}

/*
// Basic usage
const [isToolbarOpen, setIsToolbarOpen] = usePersistedValue('toolbarState', false);

// With custom storage type
const [sessionData, setSessionData] = usePersistedValue('sessionData', {}, {
  storage: 'sessionStorage'
});

// With custom serialization
const [sensitiveData, setSensitiveData] = usePersistedValue('sensitiveData', {}, {
  serializer: (value) => btoa(JSON.stringify(value)),
  deserializer: (value) => JSON.parse(atob(value))
});

// Create a reusable hook for specific use case
const usePersistedToolbarState = createPersistedValue<boolean>('toolbarState');
const [toolbarState, setToolbarState] = usePersistedToolbarState(false);
*/