import { useMutation, useQueryClient } from '@tanstack/react-query';
import { put } from '@truefit/http-utils';
import { GameModel } from '../types';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

// Define the type for game update
interface UpdateGameDetails {
    status?: 'recorded' | 'live' | 'scheduled';
    startTimestamp?: string;
    endTimestamp?: string;
    enableTracking?: boolean;
    trackingId?: string | null;
    trackingOffset?: string;
    enableAudio?: boolean;
    audioOffset?: string;
    camspecs?: string;
    streamId?: string;
}


const useUpdateGameDetails = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (model: UpdateGameDetails) => {
            const modelToUpdate = { ...model };
            modelToUpdate.camspecs = JSON.stringify(model.camspecs);
            await put<GameModel>(`/games/${model.uniqueId}`, modelToUpdate);
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['Games'] }),
    });
};

export default useUpdateGameDetails;