import * as React from 'react';
import MoreHorIcon from '@mui/icons-material/MoreHoriz';

import { Divider, IconButton, Menu, MenuItem, Tooltip, useTheme } from '@mui/material';

import { Tracking } from '@/shared/services/tracking';
import useClipStateContext from '@/shared/hooks/useClipStateContext';

import useCognito from '../../authentication/hooks/useCognito';
import useEnqueueClipForExportMutation from '../hooks/useEnqueueClipForExportMutation';
import { ClipModel, ClipStatus, ClipType } from '../types';
import { useSnackbar } from 'notistack';
import useCancelClipExportMutation from '../hooks/useCancelClipExportMutation';

type Props = {
  clip: ClipModel;
  visible: boolean;
  downloadCliphandler: (clipId: number) => void;
  exportClickHandler: () => void;
  cancelClipExportHandler?: () => void;
};

export default function ClipRecordContextMenu({
  clip,
  visible,
  exportClickHandler,
  downloadCliphandler,
  cancelClipExportHandler,
}: Props) {
  // react hooks
  const cognito = useCognito();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const exportClipMutation = useEnqueueClipForExportMutation();
  const cancelClipExportMutation = useCancelClipExportMutation();

  const { enqueueSnackbar } = useSnackbar();

  const open = Boolean(anchorEl);
  const theme = useTheme();

  const errorColor = theme.palette.error.main;
  const primaryColor = theme.palette.primary.main;

  const isOwnClip = cognito.userAttributes?.email === clip.clouduser?.email;
  const { handleConvertToClip, handleDelete, handleStartEditing, handleView } =
    useClipStateContext();

  // handlers
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancelClipExport = async () => {
    await cancelClipExportMutation.mutate(clip.id);
    if (cancelClipExportHandler) {
      try {
        cancelClipExportHandler();
      } catch (e) {}
    }

    Tracking.getInstance().track('Cancel Export', {
      category: 'Bookmarks/Clips',
      type: 'clip',
    });
  };

  const handleExport = async () => {
    await exportClipMutation.mutate(clip.id);
    if (exportClickHandler) {
      try {
        exportClickHandler();
      } catch (e) {}
    }
    await enqueueSnackbar(
      "Clip export is running in the background. You will receive a notification when it's complete or if any issues occur.",
    );
    Tracking.getInstance().track('Export', {
      category: 'Bookmarks/Clips',
      type: 'clip',
    });
  };

  const handleDownload = async () => {
    downloadCliphandler(clip.id);
    Tracking.getInstance().track('Download', {
      category: 'Bookmarks/Clips',
      type: 'clip',
    });
  };

  const id = `clip-record-context-menu-${clip.id}`;

  return (
    <>
      <Tooltip title="Actions" arrow>
        <IconButton
          size="small"
          id={id}
          aria-controls={open ? id : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ ml: 1, opacity: visible ? '1' : '0' }}
        >
          <MoreHorIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id={id}
        aria-labelledby={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {(clip.type === ClipType.Bookmark ||
          (clip.type === ClipType.Clip && clip.status === ClipStatus.CANCELED) ||
          (clip.type === ClipType.Clip && clip.status === ClipStatus.ERROR)) && (
          <MenuItem
            sx={{
              '&:hover': {
                backgroundColor: primaryColor,
                color: 'text.primary', // This reverts to the default text color on hover
              },
            }}
            onClick={() => {
              handleClose();
              handleStartEditing(clip);
            }}
          >
            Edit
          </MenuItem>
        )}

        {clip.type === ClipType.Bookmark && (
          <MenuItem
            sx={{
              '&:hover': {
                backgroundColor: primaryColor,
                color: 'text.primary', // This reverts to the default text color on hover
              },
            }}
            onClick={() => {
              handleClose();
              handleConvertToClip(clip);
            }}
            disabled={!isOwnClip}
          >
            Convert to Clip
          </MenuItem>
        )}

        {clip.type === ClipType.Clip && clip.status === ClipStatus.READY && (
          <MenuItem
            sx={{
              '&:hover': {
                backgroundColor: primaryColor,
                color: 'text.primary', // This reverts to the default text color on hover
              },
            }}
            onClick={() => {
              handleClose();
              handleDownload();
            }}
          >
            Download
          </MenuItem>
        )}
        
        {clip.type === ClipType.Clip &&
          (clip.status === ClipStatus.CANCELED || clip.status === ClipStatus.ERROR) && (
            <MenuItem
              sx={{
                '&:hover': {
                  backgroundColor: primaryColor,
                  color: 'text.primary',
                },
              }}
              onClick={() => {
                handleClose();
                handleExport();
              }}
            >
              Export
            </MenuItem>
          )}

        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: primaryColor,
              color: 'text.primary', // This reverts to the default text color on hover
            },
          }}
          onClick={() => {
            handleClose();
            handleView(clip);
          }}
        >
          View
          {clip.type === ClipType.Bookmark ? ' in Timeline' : ''}
        </MenuItem>
        {clip.type === ClipType.Clip &&
          [
            ClipStatus.PENDING,
            ClipStatus.INITIALIZING,
            ClipStatus.PROCESSING,
            ClipStatus.UNPROCESSED,
          ].includes(clip.status) && (
            <MenuItem
              sx={{
                color: errorColor,
                '&:hover': {
                  backgroundColor: errorColor,
                  color: 'text.primary', // This reverts to the default text color on hover
                },
              }}
              onClick={() => {
                handleClose();
                handleCancelClipExport();
              }}
            >
              Cancel Export
            </MenuItem>
          )}
        <Divider></Divider>
        <MenuItem
          sx={{
            color: errorColor,
            '&:hover': {
              backgroundColor: errorColor,
              color: 'text.primary', // This reverts to the default text color on hover
            },
          }}
          onClick={() => {
            handleClose();
            handleDelete(clip);
          }}
          disabled={!isOwnClip}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
}
