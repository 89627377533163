import { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import useRegistrationsQuery from '../hooks/useAutoIsoEntitiesQuery';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';
import { getBusinessApiEndPoint } from '../../../util';
import { useSurfaceDimensions } from './useSurfaceDimensions';
import { TrackedEntity } from './TrackedEntity';
import { CameraIcon, cameraSize } from './CameraIcon';
import { AutoIsoModel } from '../types/AutoIsoModel';
import { isEqual } from 'lodash';
import useCurrentGameQuery from '@/features/games/hooks/useCurrentGameQuery';

type Props = {
  elementSize: { width: number; height: number };
  refreshRate?: number; // Refresh rate in frames per second
  isFlipped: boolean;
};

const GamePositionsRepresentation = ({ elementSize, isFlipped }: Props) => {
  const currentGameId = useCurrentGameId();
  const gameQuery = useCurrentGameQuery();
  
  const { availableCameras, selectedCameraId, selectCamera } = useServerStateContext();
  const registrationsQuery = useRegistrationsQuery();
  const { lookup: registrationsLookup, homeTeam, awayTeam } = registrationsQuery?.data || {};
  const theme = useTheme();
  const gameTypeMetadataQuery = useGameTypeMetadataQuery();
  const { surfaceWidth, surfaceHeight, surfaceName, objectOfPlay } =
    useSurfaceDimensions(gameTypeMetadataQuery);
  const [top, setTop] = useState<string>();
  const [trackedEntities, setTrackedEntities] = useState<AutoIsoModel[]>([]);
  const prevEntitiesRef = useRef<AutoIsoModel[]>([]);
  const surfaceImageUrl = `${getBusinessApiEndPoint('surface')}/games/${currentGameId}/surface`;

  const [gameTrackingEntities, setGameTrackingEntities] = useState<AutoIsoModel[]>([]);

  const serverState = useServerStateContext();
  const gameStateManager = serverState?.gameStateManager;


  useEffect(() => {
    if (!gameStateManager) return;

    //@ts-ignore
    setGameTrackingEntities(gameStateManager.lastGameState?.tracking.entities || []);

    const handleTrackingEntitiesChange = (trackingEntities: AutoIsoModel[]) => {
      setGameTrackingEntities(trackingEntities);
    };

    gameStateManager.on('trackingEntitiesChange', handleTrackingEntitiesChange);

    return () => {
      if (gameStateManager.off)
        gameStateManager.off('trackingEntitiesChange', handleTrackingEntitiesChange);
    };
  }, [gameStateManager]);

  useEffect(() => {
    if (!registrationsLookup) return;
    if (!gameTrackingEntities) return;

    if(!Array.isArray(gameTrackingEntities)) {
      console.warn('No tracking entities', gameTrackingEntities);
      return;
    };

    let updatedEntities = [...gameTrackingEntities];
    updatedEntities = updatedEntities
      .map((autoIsoEntity) => {
        const entity = registrationsLookup[autoIsoEntity.id];
        if (!entity) return null;
        return { ...entity, ...autoIsoEntity };
      })
      .filter(Boolean);
    // Only update if entities actually changed
    if (!isEqual(updatedEntities, prevEntitiesRef.current)) {
      setTrackedEntities(updatedEntities);
      prevEntitiesRef.current = updatedEntities;
    }
  }, [gameTrackingEntities]);

  const cameraPositions = useMemo(() => {
    if (!Array.isArray(availableCameras)) return [];

    return availableCameras
      .filter((camera) => Number.isFinite(camera.x) && Number.isFinite(camera.y))
      .map((camera) => {
        const flipCoef = isFlipped ? -1 : 1;

        // Calculate percentages
        const xPercent = ((camera.x * flipCoef) / (surfaceWidth / 2) + 1) / 2.0;
        const yPercent = ((camera.y * flipCoef) / (surfaceHeight / 2) + 1) / 2.0;

        // Calculate positions
        const xVal = (elementSize.width - cameraSize) * xPercent;
        const yVal = (elementSize.height - cameraSize) * yPercent;

        // Ensure computed values are finite
        const computedX = Number.isFinite(xVal) ? xVal : 0;
        const computedY = Number.isFinite(yVal) ? yVal : 0;

        return {
          ...camera,
          computedX,
          computedY,
        };
      });
  }, [availableCameras, elementSize, surfaceWidth, surfaceHeight, isFlipped, cameraSize]);



  return (
    <Box
      sx={{
        touchAction: 'none',
        userSelect: 'none',
        width: elementSize.width,
        height: elementSize.height,
        position: 'relative',
        transition: 'transform 0.1s ease', // Smooth transition
      }}
    >
      <img
        alt={surfaceName}
        src={surfaceImageUrl}
        style={{
          width: '100%',
          position: 'absolute',
          transform: isFlipped ? 'scaleY(-1)' : 'none',
        }}
      />

      {cameraPositions.map((camera) => (
        <CameraIcon
          isFlipped={isFlipped}
          key={camera.id}
          camera={camera}
          xVal={camera.computedX}
          yVal={camera.computedY}
          theme={theme}
          isActive={selectedCameraId === camera.idx}
          onClick={() => selectCamera(camera.idx, `Virtual ${surfaceName}`)}
          enabled={camera.enabled}
        />
      ))}

      {trackedEntities &&
        trackedEntities.map((entity) => (
          <TrackedEntity
            isFlipped={isFlipped}
            key={entity.id}
            entity={entity}
            pos={entity.pos}
            elementSize={elementSize}
            surfaceWidth={surfaceWidth}
            surfaceHeight={surfaceHeight}
            objectOfPlay={objectOfPlay}
            awayTeam={awayTeam}
            homeTeam={homeTeam}
            game={gameQuery?.data}
            setTop={setTop}
            top={top}
          />
        ))}
    </Box>
  );
};

export default GamePositionsRepresentation;
