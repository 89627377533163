import { v4 as uuidv4 } from 'uuid';

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { del } from '@truefit/http-utils';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import ReconnectingEventSource from 'reconnecting-eventsource';

import CosmLogo from '@/assets/cosm_logo.png';
import CXVideoHub from '@/assets/cx_video_hub.png';
import { useCreateSession } from '@/features/authentication/hooks';
import TimeMachineStatusProvider from '@/features/timeMachine/components/TimeMachineStatusProvider';
import { ExtendSessionDialog } from '@/shared/components';
import { GetGlobalSSEUrl } from '@/util';
import useCognito from '../../authentication/hooks/useCognito';
import { TimeApiResponse, useTimeApiData } from '@/shared/services/getTimeApiDate';
import ProgressMessages from './ProgressMessages';

type Props = {
  children: ReactNode;
};

enum LocalSessionState {
  NOT_LOADED,
  LOADING,
  NO_LOCAL_SESSION,
  HAS_LOCAL_SESSION,
  AUTHENTICATED,
  READY,
}

const getSessionStateString = (state: LocalSessionState) => {
  switch (state) {
    case LocalSessionState.NOT_LOADED:
      return 'NOT_LOADED';
    case LocalSessionState.LOADING:
      return 'LOADING';
    case LocalSessionState.NO_LOCAL_SESSION:
      return 'NO_LOCAL_SESSION';
    case LocalSessionState.HAS_LOCAL_SESSION:
      return 'HAS_LOCAL_SESSION';
    case LocalSessionState.AUTHENTICATED:
      return 'AUTHENTICATED';
    case LocalSessionState.READY:
      return 'READY';
    default:
      return 'UNKNOWN';
  }
};

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export type ProgressMessage = {
  id: string;
  message: string;
  status: 'in-progress' | 'completed' | 'error';
  timestamp: number;
};

const AuthenticateRoute = ({ children }: Props) => {
  const navigate = useNavigate();
  const cognito = useCognito();
  const timeApiDataQuery = useTimeApiData();
  const interactiveSession = useCreateSession();

  const [sessionState, setSessionState] = useState<LocalSessionState>(
    cognito.authenticated ? LocalSessionState.AUTHENTICATED : LocalSessionState.NOT_LOADED,
  );
  const [loading, setLoading] = useState(true);
  const [sessionHandler, setSessionHandler] = useState('');
  const [cognitoSession, setCognitoSession] = useState<CognitoUserSession | null>(null);
  const [isExpiringSession, setIsExpiringSession] = useState(false);
  const [progressMessages, setProgressMessages] = useState<ProgressMessage[]>([]);

  const location = useLocation();
  const sse = useRef<ReconnectingEventSource | null>(null);

  const addProgressMessage = useCallback((message: string, status?: string) => {
    setProgressMessages((prev: any) => {
      // Mark previous message as completed
      const updatedPrev = prev.map((msg: ProgressMessage) => ({
        ...msg,
        status: 'completed' as const,
      }));

      // Add new message
      return [
        ...updatedPrev,
        {
          id: uuidv4(),
          message,
          status: status || 'in-progress',
          timestamp: Date.now(),
        },
      ];
    });
  }, []);

  useEffect(() => {
    addProgressMessage('Initializing.');
  }, []);

  const handleSignOut = useCallback(
    async (bypassSessionDeletion: boolean = false) => {
      try {
        // First attempt to sign out from Cognito
        await new Promise((resolve, reject) => {
          cognito.signOut({
            onSuccess: resolve,
            onFailure: reject,
          });
        });
      } catch (error) {
        console.error('Error signing out from Cognito:', error);
      }

      try {
        // Only clear session if not bypassed
        if (!bypassSessionDeletion) {
          await del('session');
        }
      } catch (error) {
        console.warn('Error deleting session:', error);
      }

      setSessionState(LocalSessionState.NO_LOCAL_SESSION);
    },
    [navigate, cognito, location.pathname],
  );

  const getRealtimeData = useCallback(
    (data: any, sessionId: string) => {
      const { event, payload } = data;
      if (event === 'initialized') {
        interactiveSession.mutate(timeApiDataQuery.data as TimeApiResponse);
        return;
      }

      if (payload?.sessionId !== sessionId) return;

      switch (event) {
        case 'session-deploy-error':
          addProgressMessage(
            // 'Additional Player Unavailable - Unable to load a new video playback server. Support has been notified.',
            payload.message,
            'error',
          );
          console.error(payload.message);
          break;
        case 'session-deploy-progress':
          addProgressMessage(payload.message);
          break;
        case 'session-ready':
          setSessionState(LocalSessionState.READY);
          break;
        case 'session-handler':
          setSessionHandler(payload.handler);
          break;
        case 'session-ended':
          setSessionState(LocalSessionState.NO_LOCAL_SESSION);
          handleSignOut(true);
          break;
        case 'session-expiring':
          setIsExpiringSession(true);
          break;
        default:
          return;
      }
    },
    [
      interactiveSession,
      timeApiDataQuery.data,
      addProgressMessage,
      setSessionState,
      setSessionHandler,
      handleSignOut,
      setIsExpiringSession,
    ],
  );

  useEffect(() => {
    if (sessionHandler && cognitoSession) {
      cognito.setHttpAuth(cognitoSession, sessionHandler);
      cognito.updateCognitoContext({ sessionHandler });
    }
  }, [sessionHandler, cognitoSession]);

  useEffect(() => {
    if (sessionState === LocalSessionState.NOT_LOADED) {
      cognito.getSession({
        onSuccess(userSession) {
          setCognitoSession(userSession);
          setSessionState(LocalSessionState.HAS_LOCAL_SESSION);
        },
        onFailure() {
          setSessionState(LocalSessionState.NO_LOCAL_SESSION);
        },
      });
    }
  }, [sessionState]);

  useEffect(() => {
    if (cognito.authenticated) {
      cognito.getSession({
        onSuccess(userSession) {
          setCognitoSession(userSession);
          if (userSession.getIdToken()) {
            const SSEHref = GetGlobalSSEUrl();
            if (!sse.current) {
              sse.current = new ReconnectingEventSource(SSEHref);
              sse.current.onmessage = (e) => {
                getRealtimeData(JSON.parse(e.data), cognito.user.getUsername());
              };
            }
          }
          setSessionState(LocalSessionState.HAS_LOCAL_SESSION);
        },
        onFailure() {
          setSessionState(LocalSessionState.NO_LOCAL_SESSION);
        },
      });
    }
    return () => {
      sse.current?.close();
      sse.current = null;
    };
  }, [cognito.authenticated]);

  if (sessionState === LocalSessionState.NOT_LOADED || sessionState === LocalSessionState.LOADING) {
    return (
      <Backdrop
        open
        aria-hidden="false"
        sx={{
          backdropFilter: 'blur(8px)', // Apply blur
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Dim background
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure it's above other elements
        }}
      >
        <Stack>
          <Item>Loading...</Item>
          <Item>
            <CircularProgress color="inherit" />
          </Item>
        </Stack>
      </Backdrop>
    );
  }

  if (sessionState === LocalSessionState.NO_LOCAL_SESSION) {
    return <Navigate to={{ pathname: '/sign-in' }} />;
  }

  if (
    sessionState === LocalSessionState.AUTHENTICATED ||
    sessionState === LocalSessionState.HAS_LOCAL_SESSION
  ) {
    return (
      <Container maxWidth="sm" fixed>
        <Box
          sx={{
            height: '100vh',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Paper
            sx={() => {
              return {
                px: 3,
                pt: 2,
                pb: 1,
                borderRadius: 2,
                minWidth: 700,
                minHeight: 700,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: 'transparent',
              };
            }}
          >
            <img src={CosmLogo} alt="Cosm" style={{ height: 40, marginTop: 15 }} />
            <img src={CXVideoHub} alt="CXVideo Hub" style={{ height: 10, marginTop: 15 }} />
            <Typography variant="subtitle2" sx={{ mb: 3, mt: 1 }}>
              with AUTO-ISO™
            </Typography>
            <Stack spacing={2}>
              <ProgressMessages messages={progressMessages} />
            </Stack>

            <Box sx={{ maxWidth: 550, textAlign: 'center', pt: 4 }}>
              <Button onClick={handleSignOut}>{loading ? 'Cancel Login' : 'Back'}</Button>
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  }

  return (
    <TimeMachineStatusProvider>
      <ExtendSessionDialog open={isExpiringSession} onClose={() => setIsExpiringSession(false)} />
      {children}
    </TimeMachineStatusProvider>
  );
};

export default AuthenticateRoute;
