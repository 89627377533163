import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

// MUI Components
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

// MUI Icons
import {
  Monitor as MonitorIcon,
  Code as CodeIcon,
  Group as GroupIcon,
  Groups as GroupsIcon,
  Key as KeyIcon,
  MenuOpen as MenuOpenIcon,
  OndemandVideo as OndemandVideoIcon,
  Sports as SportsIcon,
  VideoLibrary
} from '@mui/icons-material';

// Custom Components & Hooks
import { PageAndAccountMenu } from './OrgAndAccountMenu';
import AnimatedCosmLogo from '../AnimatedCosmLogo';
import useCognito from '@/features/authentication/hooks/useCognito';
import { UserStateContext } from '../UserStateProvider';
import { getBusinessApiEndPoint } from '@/util/configureHttp';
import { getFirstLettersOfName, getRandomPastelColor } from '@/shared/services';

interface NavigationMenuProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  width: number;
}

interface MenuItem {
  path: string;
  icon: React.ReactNode;
  label: string;
  external?: boolean;
  href?: string;
  roles?: string[];
}

interface MenuSection {
  title: string;
  items: MenuItem[];
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({ open, setOpen, width }) => {
  // Hooks
  const location = useLocation();
  const cognito = useCognito();
  const { batchedClipExport } = useFlags();
  const { currentLeague, currentLeagueLogo, hasRole } = React.useContext(UserStateContext);

  // State
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [userEmail, setUserEmail] = React.useState('');
  const [avatarBgColor, setAvatarBgColor] = React.useState('#333');

  // Constants
  const apiDocumentationUrl = `${getBusinessApiEndPoint('documentation')}/documentation/`;
  const settingsMenuOpen = Boolean(anchorEl);
  const menuId = settingsMenuOpen ? 'settings-popover' : undefined;

  // Computed Values
  const backgroundColor = React.useMemo(
    () => currentLeague?.firstColor
      ? `linear-gradient(to left, rgba(${currentLeague.firstColor},1), rgba(${currentLeague.firstColor},0.1))`
      : '#333',
    [currentLeague?.firstColor]
  );

  const menuSections: MenuSection[] = React.useMemo(() => [
    {
      title: 'Watch',
      items: [
        {
          path: '/games',
          icon: <OndemandVideoIcon />,
          label: 'Games',
          roles: ['menu:games']
        },
        ...(batchedClipExport ? [{
          path: '/clips',
          icon: <VideoLibrary />,
          label: 'Bookmarks',
          roles: ['menu:clips']
        }] : [])
      ]
    },
    {
      title: 'Clip Export API',
      items: [
        {
          path: apiDocumentationUrl,
          icon: <CodeIcon />,
          label: 'API Docs',
          external: true,
          href: apiDocumentationUrl,
          roles: ['menu:api']
        },
        {
          path: '/api-key',
          icon: <KeyIcon />,
          label: 'API Key',
          roles: ['menu:api']
        }
      ]
    },
    {
      title: 'Manage',
      items: [
        {
          path: '/manage-games',
          icon: <SportsIcon />,
          label: 'Manage Games',
          roles: ['menu:manage:games']
        },
        {
          path: '/manage-groups',
          icon: <GroupsIcon />,
          label: 'Manage Groups',
          roles: ['menu:manage:groups']
        },
        {
          path : '/manage-sessions',
          icon: <MonitorIcon/>,
          label: 'Manage Sessions',
          roles: ['menu:manage:sessions']
        },
        {
          path: '/organization-settings',
          icon: <GroupIcon />,
          label: 'Manage Users',
          roles: ['menu:manage:org']
        }
      ]
    }
  ], [apiDocumentationUrl, batchedClipExport]);

  // Effects
  React.useEffect(() => {
    if (cognito.userAttributes?.email) {
      const email = cognito.userAttributes.email;
      setUserEmail(email);
      setAvatarBgColor(getRandomPastelColor(email.charAt(0), email.charAt(1)));
    }
  }, [cognito.authenticated, cognito.userAttributes?.email]);

  // Event Handlers
  const handleCloseSettingsMenu = () => setAnchorEl(null);
  const handleOpenSettingsMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleDrawerClose = () => setOpen(false);

  // Render Methods
  const renderMenuItem = React.useCallback(({ path, icon, label, external, href, roles = [] }: MenuItem) => {
    if (!hasRole(roles)) return null;

    const commonProps = {
      sx: { paddingLeft: 5 }
    };

    const content = external ? (
      <ListItemButton
        {...commonProps}
        component="a"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListItemIcon sx={{ color: 'Background.paper' }}>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    ) : (
      <ListItemButton
        {...commonProps}
        component={Link}
        to={path}
        selected={location.pathname === path}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    );

    return (
      <ListItem key={path} disablePadding>
        {content}
      </ListItem>
    );
  }, [hasRole, location.pathname]);

  const renderMenuSection = React.useCallback(({ title, items }: MenuSection, index: number) => {
    const visibleItems = items.filter(item => hasRole(item.roles || []));
    if (visibleItems.length === 0) return null;

    return (
      <React.Fragment key={`section-${index}-${title}`}>
        <Box sx={{ paddingLeft: 2, paddingTop: 3 }}>
          <Typography variant="overline">{title}</Typography>
        </Box>
        <List sx={{ paddingTop: 0 }}>
          {visibleItems.map(renderMenuItem)}
        </List>
      </React.Fragment>
    );
  }, [hasRole, renderMenuItem]);

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={handleDrawerClose}
    >
      {/* Header */}
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          padding: theme.spacing(0, 1),
          ...theme.mixins.toolbar,
          width,
          background: backgroundColor,
        })}
      >
        <IconButton onClick={handleDrawerClose}>
          <MenuOpenIcon />
        </IconButton>
        {currentLeagueLogo && (
          <AnimatedCosmLogo leagueLogo={currentLeagueLogo} height={30} />
        )}
      </Box>

      <Divider />

      {/* Menu Sections */}
      <Box sx={{ flexGrow: 1 }}>
        {menuSections.map(renderMenuSection)}
      </Box>

      {/* User Section */}
      <Box>
        <List>
          <ListItem onClick={handleOpenSettingsMenu} sx={{ cursor: 'pointer' }}>
            <ListItemIcon>
              <Avatar sx={{ bgcolor: avatarBgColor }}>
                {getFirstLettersOfName(userEmail)}
              </Avatar>
            </ListItemIcon>
            <ListItemText secondary={userEmail} />
          </ListItem>
        </List>

        <Popover
          id={menuId}
          open={settingsMenuOpen}
          anchorEl={anchorEl}
          onClose={handleCloseSettingsMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <PageAndAccountMenu handleClose={handleCloseSettingsMenu} />
        </Popover>
      </Box>
    </Drawer>
  );
};

export default NavigationMenu;