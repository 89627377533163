
import { useMutation } from '@tanstack/react-query';
import { del } from '@truefit/http-utils';

const useCancelClipExportMutation = () =>
  useMutation({
    mutationFn: async (clipId: number) => {
      await del(`/clips/${clipId}/export`);
    },
    gcTime: 0,
  });

export default useCancelClipExportMutation;
