import { Cams, Store, StoreHookReturn } from '@/types/camspec';
import { PTZMessage } from '../../../types/control';

// Module-scoped store and cameras to track PTZ values
let store: Store = {};
const cams: Cams = {};

export const usePTZStore = (): StoreHookReturn => {
  const init = (cams: []) => {
    return;
    
  };

  const handleChange = (ptzValues: PTZMessage, idx: number) => {
    delete ptzValues.type;
    store = {
      ...store,
      [idx]: ptzValues,
    };
  };

  const setMinimum = (ptzMinValues: PTZMessage, idx: number) => {
    cams[idx].min = ptzMinValues;
  };

  const setMaximum = (ptzMaxValues: PTZMessage, idx: number) => {
    cams[idx].max = ptzMaxValues;
  };

  const getValue = (idx: number) => {
    return store[idx];
  };

  return {
    getValue,
    handleChange,
    init,
    setMinimum,
    setMaximum,
    store,
  };
};
