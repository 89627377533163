 
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { GetCurrentUserActions, SignOutActions } from '../actions';
import { UserIdentity } from '../types';

export type CurrentUserState = {
  authenticated: boolean;
  user: UserIdentity | null;  // Allow null if the user is not authenticated
};

const INITIAL: CurrentUserState = {
  authenticated: false,
  user: null,
};

const currentUserReducer = createReducer(INITIAL, (builder) => {
  builder
    .addCase(GetCurrentUserActions.Succeeded, (state, action: PayloadAction<UserIdentity>) => {
      state.authenticated = true;
      state.user = action.payload;
    })
    .addCase(SignOutActions.Completed, (state) => {
      state.authenticated = false;
      state.user = null;
    });
});

export default currentUserReducer;