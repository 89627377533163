import { Provider } from 'react-redux';
import { Store } from 'redux';

import { CognitoProvider } from './features/authentication/components';

import { App } from './features/scaffolding/components';

type Props = {
  store: Store;
};

const Root = ({ store }: Props) => (
  <Provider store={store}>
    <CognitoProvider>
      <App />
    </CognitoProvider>
  </Provider>
);
export default Root;
