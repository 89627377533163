import React, { useState } from 'react';
import { Stack } from '@mui/material';
import Slider from '@mui/material/Slider';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import { PlayerControl } from './PlayerControls';

const PRIMARY_COLOR = '#ffffff';
const SECONDARY_COLOR = '#b8b8b8';

type AudioControlsProps = {
  onVolumeChange?: (volume: number) => void;
  onMuteToggle?: (isMuted: boolean) => void;
  initialVolume?: number;
  initialMuted?: boolean;
};

const AudioControls: React.FC<AudioControlsProps> = ({
  onVolumeChange,
  onMuteToggle,
  initialVolume = 0,
  initialMuted = true,
}) => {
  const [volume, setVolume] = useState<number>(initialVolume);
  const [isMuted, setIsMuted] = useState<boolean>(initialMuted);

  const handleVolumeChange = (_event: Event, newValue: number | number[]) => {
    const newVolume = newValue as number;
    setVolume(newVolume);

    if (newVolume === 0) {
      setIsMuted(true);
      onMuteToggle?.(true);
    } else if (isMuted) {
      setIsMuted(false);
      onMuteToggle?.(false);
    }

    onVolumeChange?.(newVolume);
  };

  const handleMuteToggle = () => {
    const newMutedState = !isMuted;
    setIsMuted(newMutedState);
    onMuteToggle?.(newMutedState);
  };

  const VolumeIcon = () => {
    if (isMuted || volume === 0) return <VolumeOffIcon />;
    if (volume < 50) return <VolumeDownIcon />;
    return <VolumeUpIcon />;
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ width: 200 }}>
      <PlayerControl
        title={isMuted ? 'Unmute' : 'Mute'}
        onClick={handleMuteToggle}
        color={isMuted ? SECONDARY_COLOR : PRIMARY_COLOR}
      >
        <VolumeIcon />
      </PlayerControl>
      <Slider
        size="small"
        value={isMuted ? 0 : volume}
        onChange={handleVolumeChange}
        aria-label="Volume"
        min={0}
        max={100}
        sx={{
          width: 100,
          color: PRIMARY_COLOR,
          '& .MuiSlider-track': {
            border: 'none',
          },
          '& .MuiSlider-thumb': {
            width: 12,
            height: 12,
            backgroundColor: '#fff',
            '&:hover, &.Mui-focusVisible': {
              boxShadow: '0 0 0 8px rgba(255, 255, 255, 0.16)',
            },
          },
        }}
      />
    </Stack>
  );
};

export default AudioControls;
