import React from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogTitle, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { MoreTime } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { Tracking } from '@/shared/services/tracking';

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function ExtendSessionDialog({ open, onClose }: Props) {
  const tracking = Tracking.getInstance();
  return (
    <Dialog open={open} maxWidth="xs"    slotProps={{
      backdrop: {
        sx: {
          backdropFilter: 'blur(4px)', // Default blur effect
        }
      }
    }}>
      <DialogTitle>Your session is expiring</DialogTitle>
      <DialogContent dividers>
        <Typography>Would you like to extend your session?</Typography>
        <Box sx={{ pt: 2 }}>
          <Button
            variant="contained"
            endIcon={<MoreTime />}
            onClick={() => {
              tracking.track('Extend Session', null);
              onClose();
            }}
          >
            Extend
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
