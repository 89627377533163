import { useQuery } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';

export interface ApiKey {
    key: string;
    id: string;
    success: boolean;
}

interface ApiResponse {
    data: ApiKey;
}

export const getQueryKey = (orgId?: number) => ['apikey', orgId] as const;

const useGetApiKeyQuery = (orgId?: number) => {
    return useQuery<ApiKey, Error>({
        queryKey: getQueryKey(orgId),
        queryFn: async () => {
            const response = await get<ApiResponse>('/user/apikey');
            return response.data;
        },
        enabled: orgId !== undefined,
        gcTime: Infinity,
        placeholderData: {
            id: '',
            key: '',
            success: false
        },
    });
};

export default useGetApiKeyQuery;