import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { del } from '@truefit/http-utils';

import {
  Avatar,
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';

import useCognito from '@/features/authentication/hooks/useCognito';
import { UserStateContext } from '@/shared/components/UserStateProvider';
import { getLeagueLogo } from '@/shared/services/getLeagueLogo';
import { Tracking } from '@/shared/services/tracking';
import { getFirstLettersOfName, getRandomPastelColor } from '@/shared/services';

import { Access } from '@/types/user';

import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

export const PageAndAccountMenu = ({ handleClose }) => {

  const {
    currentOrg,
    currentLeague,
    availableOrgs,
    actions: { updateDefaultLeague, updateDefaultOrg },
    hasRole,
  } = React.useContext(UserStateContext);

  const [subMenuOpen, setSubMenuIsOpen] = useState(false);
  const [subMenuLeagueOpen, setSubMenuLeagueIsOpen] = useState(true);

  const cognito = useCognito();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleSignOut = () => {
    cognito.signOut({
      async onSuccess() {
        Tracking.getInstance().signOut();
        navigate('/sign-in');
        await del('session');
      },

      onFailure() {
        enqueueSnackbar('An error occurred while signing out', { variant: 'error' });
      },
    });
  };

  return (
    <List sx={{ paddingTop: 0, marginBottom: 4 }}>
      {currentOrg && (
        <>
          <ListItem>
            <ListItemIcon>
              <Avatar
                sx={{ bgcolor: getRandomPastelColor(currentOrg.name[0], currentOrg.name[2]) }}
              >
                {getFirstLettersOfName(currentOrg.name)}
              </Avatar>
            </ListItemIcon>
            <ListItemText primary={currentOrg.name} secondary="Organization" />
          </ListItem>
          {hasRole(['menu:manage:org']) && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/organization-settings">
                <ListItemText primary="Organization Settings" />
              </ListItemButton>
            </ListItem>
          )}

          <Divider />
        </>
      )}
      {Array.isArray(availableOrgs) && availableOrgs.length > 1 && (
        <>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setSubMenuIsOpen(!subMenuOpen);
              }}
            >
              <ListItemText primary="Switch Organization" />
              {subMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {availableOrgs.map((org) => (
                <ListItemButton
                  key={org.id}
                  sx={{ pl: 4 }}
                  onClick={() => {
                    updateDefaultOrg(org);
                    handleClose();
                  }}
                >
                  <ListItemText primary={org.name} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>

          <Divider />
        </>
      )}

      {currentOrg?.access && currentOrg.access.length > 1 && (
        <>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setSubMenuLeagueIsOpen(!subMenuLeagueOpen);
              }}
            >
              <ListItemText primary="Switch League" />
            </ListItemButton>
          </ListItem>

          <List component="div" disablePadding>
            {currentOrg.access.map((access: Access) => (
              <ListItemButton
                key={access.league.id}
                sx={{
                  pl: 4,
                  position: 'relative',
                  overflow: 'hidden', // Ensure child elements stay within the ListItemButton
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${getLeagueLogo(access.league.id)})`,
                    // backgroundSize: '80%', // Adjust size as needed
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    opacity: access.league.id === currentLeague?.id ? 0.2 : 0, // Show background if selected
                    transform: access.league.id === currentLeague?.id ? 'scale(1)' : 'scale(1.2)', // Apply transformation if selected
                    transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out', // Transition for opacity and transform
                    zIndex: 1,
                    filter: access.league.id === currentLeague?.id ? 'blur(10px)' : 'blur(30px)', // Apply blur effect
                  },
                  '&:hover::after': {
                    opacity: 1, // Show the background on hover
                    transform: 'scale(0.9)', // Scale down to normal size
                  },
                  '& .MuiListItemText-root': {
                    position: 'relative',
                    zIndex: 2, // Ensure the text is above the background
                  },
                }}
                onClick={() => {
                  updateDefaultLeague(access.league);
                  handleClose();
                }}
              >
                <ListItemText
                  primary={access.league.displayName}
                  sx={{ textShadow: ' -1px 1px 10px rgba(0, 0, 0, 0.75)' }}
                />
              </ListItemButton>
            ))}
          </List>
          <Divider />
        </>
      )}

      <ListItem disablePadding>
        <ListItemButton component={Link} to="/account-settings">
          <ListItemText primary="Account Settings & Preferences" />
        </ListItemButton>
      </ListItem>
      <Divider />
      <ListItem disablePadding>
        <ListItemButton onClick={handleSignOut}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItemButton>
      </ListItem>
    </List>
  );
};
