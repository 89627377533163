import { useEffect, useState } from 'react';
import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';

import Box from '@mui/material/Box';

import useServerStateContext from '@/shared/hooks/useServerStateContext';
import { MANUAL_TRACKING_OR_NO_TRACKING, OBJECT_OF_PLAY } from '@/shared/hooks/useServerState';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';

import { AutoIsoObjectType } from '../../autoIso/types';
import PtzControls from '../../games/components/PtzControls';
import { ViewType } from '../types/VideoSource';
import { TargetConstants } from '@/shared/types';

import AutoISOTeamSelector from '@/features/teams/components/AutoISOTeamSelector';
import useAutoIsoEntitiesQuery from '@/features/autoIso/hooks/useAutoIsoEntitiesQuery';

const AutoIsoSelect = () => {
  //
  // Queries
  const gameMetadataQuery = useGameTypeMetadataQuery();

  // Server State Context
  const {
    trackEntity,
    singleCurrentlyTrackedObjectId: selectedId,
    viewType,
    gameStateManager,
  } = useServerStateContext();

  const { data } = useAutoIsoEntitiesQuery();
  const lookup = data?.lookup;
  const list = data?.list || [];

  const selectedEntity = lookup ? lookup[selectedId] : undefined;
  const ballPuck = list.find((e) => e.type === AutoIsoObjectType.BallOrPuck);

  // State Management
  const [selectedObjectType, setSelectedObjectType] = useState<AutoIsoObjectType>(
    selectedEntity?.type || AutoIsoObjectType.None,
  );

  const [quadViewSelected, setQuadViewSelected] = useState(viewType === ViewType.MOSAIC);
  const [isSelectingPlayer, setSelectingPlayer] = useState(false);

  useEffect(() => {
    if (viewType === ViewType.MOSAIC) {
      setQuadViewSelected(true);
      if (selectedObjectType === AutoIsoObjectType.None)
        if (ballPuck) trackEntity(OBJECT_OF_PLAY, 'Automatic Switch');
    } else {
      setQuadViewSelected(false);
    }
  }, [viewType, ballPuck]);

  useEffect(() => {
    if (!gameStateManager) return;

    const handleViewTypeChange = (viewType: ViewType) => {
      setQuadViewSelected(viewType === ViewType.MOSAIC);
    };

    const handleTrackingTargetChange = (trackingTarget: any) => {
      if (trackingTarget.type === TargetConstants.MANUAL) {
        setSelectedObjectType(AutoIsoObjectType.None);
      }

      if (trackingTarget.type === TargetConstants.AUTOMATIC) {
        if ('id' in trackingTarget && trackingTarget.id === 1) {
          setSelectedObjectType(AutoIsoObjectType.BallOrPuck);
        } else {
          setSelectedObjectType(AutoIsoObjectType.Player);
        }
      }
    };

    // Set up event listener
    gameStateManager.on('viewTypeChange', handleViewTypeChange);
    gameStateManager.on('trackingTargetChange', handleTrackingTargetChange);

    // Cleanup function
    return () => {
      if (gameStateManager.off) {
        gameStateManager.off('viewTypeChange', handleViewTypeChange);
        gameStateManager.off('trackingTargetChange', handleTrackingTargetChange);
      }
    };
  }, [gameStateManager, isSelectingPlayer]);

  useEffect(() => {}, [selectedObjectType]);

  return (
    <Box mb={2}>
      <Stack flexDirection="row" sx={{ mr: 3, ml: 3, mt: 2 }} alignItems="center">
        <Box sx={{minWidth: '100px'}}> Auto-ISO</Box>
        <ToggleButtonGroup
          color="primary"
          value={selectedObjectType}
          exclusive
          onChange={(_e, group) => {
            if (group !== null) {
              setSelectedObjectType(group);
            }
          }}
          size="small"
          fullWidth
        >
          <ToggleButton
            value={AutoIsoObjectType.None}
            onClick={(e) => {
              e.preventDefault();
              setSelectingPlayer(false);
              trackEntity(MANUAL_TRACKING_OR_NO_TRACKING, 'Toggle Button');
            }}
            disabled={quadViewSelected}
            size="small"
          >
            Manual
          </ToggleButton>

          <ToggleButton
            value={AutoIsoObjectType.BallOrPuck}
            onClick={(e) => {
              e.preventDefault();
              setSelectingPlayer(false);
              trackEntity(OBJECT_OF_PLAY, 'Toggle Button');
              setSelectedObjectType(AutoIsoObjectType.BallOrPuck);
            }}
          >
            {gameMetadataQuery.data?.objectOfPlay || 'Ball/Puck'}
          </ToggleButton>
          <ToggleButton
            value={AutoIsoObjectType.Player}
            onClick={() => {
              setSelectingPlayer(true);
            }}
          >
            Player
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      {selectedObjectType === AutoIsoObjectType.Player && <AutoISOTeamSelector />}

      {selectedObjectType === AutoIsoObjectType.None && !quadViewSelected ? <PtzControls /> : null}
    </Box>
  );
};

export default AutoIsoSelect;
