import { User, UsersByGroups } from '../../../types/types';

export function flattenUsers(groups: UsersByGroups[]) {
  const flatUsers: User[] = groups.flatMap((group) =>
    group.users.map((user) => ({
      ...user,
      groups: [group.groupName],
    })),
  );

  // If a user can belong to multiple groups, you would need to merge the groups for duplicate users.
  const mergedUsers: User[] = [];

  flatUsers.forEach((user) => {
    if (user.lastSeen) {
      const existingUser = mergedUsers.find((u) => u.id === user.id);
      if (existingUser) {
        existingUser.groups.push(...user.groups);
      } else {
        mergedUsers.push(user);
      }
    }
  });

  return mergedUsers;
}

export function flattenInvitedUsers(groups: UsersByGroups[]) {
  const flatUsers: User[] = groups.flatMap((group) =>
    group.users.map((user) => ({
      ...user,
      groups: [group.groupName],
    })),
  );

  // If a user can belong to multiple groups, you would need to merge the groups for duplicate users.
  const mergedUsers: User[] = [];

  flatUsers.forEach((user) => {
    if (user.lastSeen === undefined || user.lastSeen === null) {
      const existingUser = mergedUsers.find((u) => u.id === user.id);
      if (existingUser) {
        existingUser.groups.push(...user.groups);
      } else {
        mergedUsers.push(user);
      }
    }
  });

  return mergedUsers;
}

