import { printWebRtcReport } from '@/features/games/services/webrtcReport';
import OvenPlayer, { OvenPlayerState } from 'ovenplayer';
import { useEffect, useRef, useState } from 'react';

// ovenplayerinstance is not exposed by the type definition
type OvenPlayerInstance = ReturnType<typeof OvenPlayer.create>;
const AUTO_RELOAD_INTERVAL = 2 * 1000; // 1s

export type PlayerHandlers = {
  peerConnectionPrepared?: (myPeerConnection: RTCPeerConnection) => void;
};

export type UsePlayerParams = {
  elId: string;
  streamUrl: string;
  handlers?: PlayerHandlers;
  onWebrtcStatsChange?: (stats: any) => void;
  onReady?: (peerConnection: RTCPeerConnection) => void;
};

const usePlayer = ({
  elId,
  streamUrl,
  handlers = {},
  onWebrtcStatsChange,
  onReady,
}: UsePlayerParams) => {
  const [errorState, setErrorState] = useState(false);
  const [reloadTimer, setReloadTimer] = useState(null);

  const playerRef = useRef<OvenPlayerInstance>();
  const loadStatsTimerRef = useRef<NodeJS.Timeout | null>(null);

  if (!handlers.peerConnectionPrepared) {
    handlers.peerConnectionPrepared = (peerConnection: RTCPeerConnection) => {
      if (onReady) onReady(peerConnection);
      loadStatsTimerRef.current = setInterval(async () => {
        const stats = await peerConnection.getStats();
        printWebRtcReport(stats);
        if (onWebrtcStatsChange) onWebrtcStatsChange(stats);
      }, 500);
    };
  }

  useEffect(() => {
    function createPlayer() {
      return OvenPlayer.create(elId, {
        autoFallback: true,
        autoStart: true,
        controls: false,
        disableSeekUI: true,
        expandFullScreenUI: false,
        mute: true,
        showBigPlayButton: false,
        sources: [
          {
            type: 'webrtc',
            file: streamUrl,
          },
        ],
        webrtcConfig: {
          timeoutMaxRetry: 10,
          connectionTimeout: 20000,
        },
      });
    }

    playerRef.current = createPlayer();

    playerRef.current.on('peerConnectionPrepared', handlers.peerConnectionPrepared);
    playerRef.current.on('stateChanged', handlers.stateChanged);

    setErrorState(false);

    playerRef.current.once('error', () => {
      if (reloadTimer) {
        clearTimeout(reloadTimer);
        setReloadTimer(null);
      }
      const timer = setTimeout(() => {
        setErrorState(true);
      }, AUTO_RELOAD_INTERVAL);

      setReloadTimer(timer);
    });

    return () => {
      if (loadStatsTimerRef.current) {
        clearInterval(loadStatsTimerRef.current);
      }

      try {
        playerRef.current.remove();
      } catch (e) {
        console.warn('failed to destroy the player ... ');
      }
    };
  }, [elId, streamUrl, errorState]);

  return {
    player: playerRef.current,
  };
};

export default usePlayer;
