import { groupBy } from 'lodash';

export type KeyboardShortcutGroup = 'Playback' | 'PTZ' | 'General';

export type KeyboardShortcut = {
  /** The user-friendly command name */
  command: string;
  /** The actual hotkey string provided to hotkeys-js */
  hotkey: string;
  /** Keys to display inside keyboard buttons in the UI to represent the shortcut to the user */
  keys: string[];
  /** Determines the specific list in which to place this keyboard shortcut in the modal */
  group: KeyboardShortcutGroup;
};

export const KeyboardShortcutGroups: Record<string, KeyboardShortcutGroup> = {
  General: 'General',
  Playback: 'Playback',
  PTZ: 'PTZ'
};

export const KeyboardShortcuts: Record<string, KeyboardShortcut> = {
  AddBookmark: {
    command: 'Add Bookmark',
    hotkey: 'b',
    keys: ['b'],
    group: KeyboardShortcutGroups.General,
  },
  AddClip: {
    command: 'Add Clip',
    hotkey: 'c',
    keys: ['c'],
    group: KeyboardShortcutGroups.General,
  },
  SetClipStart: {
    command: 'Set Clip Start',
    hotkey: 'i',
    keys: ['i'],
    group: KeyboardShortcutGroups.General,
  },
  SetClipEnd: {
    command: 'Set Clip End',
    hotkey: 'o',
    keys: ['o'],
    group: KeyboardShortcutGroups.General,
  },
  PlayPause: {
    command: 'Play/Pause',
    hotkey: 'k',
    keys: ['k'],
    group: KeyboardShortcutGroups.Playback,
  },
  ShuttleBackward: {
    command: 'Rewind 1 Second',
    hotkey: 'j',
    keys: ['j'],
    group: KeyboardShortcutGroups.Playback,
  },
  ShuttleForward: {
    command: 'Fast Forward 1 Second',
    hotkey: 'l',
    keys: ['l'],
    group: KeyboardShortcutGroups.Playback,
  },
  //  MoveFrameForward: {
  //    command: 'Next Frame',
  //    hotkey: '.',
  //    keys: ['.'],
  //    group: KeyboardShortcutGroups.Playback,
  //  },
  //  MoveFrameBackward: {
  //    command: 'Previous Frame',
  //    hotkey: ',',
  //    keys: [','],
  //    group: KeyboardShortcutGroups.Playback,
  //  },
  TiltUp: {
    command: 'Tilt Up',
    hotkey: 'w',
    keys: ['w'],
    group: KeyboardShortcutGroups.PTZ,
  },
  TiltDown: {
    command: 'Tilt Down',
    hotkey: 's',
    keys: ['s'],
    group: KeyboardShortcutGroups.PTZ,
  },
  PanLeft: {
    command: 'Pan Left',
    hotkey: 'a',
    keys: ['a'],
    group: KeyboardShortcutGroups.PTZ,
  },
  PanRight: {
    command: 'Pan Right',
    hotkey: 'd',
    keys: ['d'],
    group: KeyboardShortcutGroups.PTZ,
  },
  ZoomIn: {
    command: 'Zoom In',
    hotkey: 'r',
    keys: ['r'],
    group: KeyboardShortcutGroups.PTZ,
  },
  ZoomOut: {
    command: 'Zoom Out',
    hotkey: 'f',
    keys: ['f'],
    group: KeyboardShortcutGroups.PTZ,
  },
};

export const KeyboardShortcutsList = groupBy(Object.values(KeyboardShortcuts), 'group') as Record<
  KeyboardShortcutGroup,
  KeyboardShortcut[]
>;

export const KeyboardShortcutGroupTitles: Record<KeyboardShortcutGroup, string> = {
  PTZ: 'PTZ Tracking',
  Playback: 'Playback',
  General: 'General',
};

export const KeyboardShortcutGroupOrderNumber: Record<KeyboardShortcutGroup, number> = {
  Playback: 1,
  PTZ: 2,
  General: 3
};
