
export enum ViewConstants {
  SINGLE_VIEW = 'single',
  MOSAIC_VIEW = 'mosaic',
}

export enum TargetConstants {
  MANUAL = 'manual',
  AUTOMATIC = 'entity',
}

export interface GameState {
  readonly idx: number; // Index of the current _output_ frame
  readonly head: number; // Current playhead, in global ns since epoch
  readonly live: number; // Current live edge (w/ delay applied)
  readonly speed: number; // Current playback speed (1.0 normal playback, 0.0 paused)
  readonly vcam: Vcam; // Current vcam stats
  readonly tracking: Tracking; // Current tracking target
  readonly game: GameTiming; // Information about the game
  readonly view: MosaicView | SingleView; // If current view is single-cam or alternatively, if the view is a mosaic
}

export interface GameTiming {
  readonly period: number;
  readonly clock: string;
  readonly score: Score;
}

export interface Score {
  readonly home: number;
  readonly away: number;
}

export interface AutomaticTrackingEntity {
  readonly type: TargetConstants;
  readonly id: number;
}

export interface ManualTrackingEntity {
  readonly type: TargetConstants;
}

export interface Tracking {
   target: ManualTrackingEntity | AutomaticTrackingEntity;
   entities: Entity[];
}

export interface Entity {
  readonly id: number;
  readonly pos : Pos;
}

export interface Pos {
  readonly x: number;
  readonly y: number;
  readonly z: number;
}

export interface Vcam {
  readonly pan: number; // pan angle, in radians
  readonly tilt: number; // tilt angle, in radians
  readonly zoom: number; // vcam width, in radians
}

export interface SingleView {
  readonly type: ViewConstants;
  readonly idx: number | string;
}

export interface MosaicView {
  readonly type: string;
  readonly idx?: number | string;
  readonly dims: Dims;
  readonly idxs: number[];
}

export interface Dims {
  readonly width: number;
  readonly height: number;
}
