import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { User } from '@/types/user';

interface DisableUserDialogProps {
  open: boolean;
  user: User | null;
  disabled: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const DisableUserDialog: React.FC<DisableUserDialogProps> = ({
  open,
  user,
  disabled,
  onClose,
  onConfirm,
}) => {
  if (!user) return null;

  const action = user.disabled ? 'enable' : 'disable';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: { backdropFilter: 'blur(4px)' },
        },
      }}
    >
      <DialogTitle>
        {action.charAt(0).toUpperCase() + action.slice(1)} access for
      </DialogTitle>
      <DialogContent dividers>
        <Typography>
          Are you sure you want to {action} access for{' '}
          <strong>{user.email}</strong>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={disabled}
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
