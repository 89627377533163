import { Button, Box,Stack } from '@mui/material';
import { Copy } from 'lucide-react';
import { useState } from 'react';

interface ApiKeyDisplayProps {
  apiKey: string;
  onCopy?: () => void;
}

export const ApiKeyDisplay = ({ apiKey, onCopy }: ApiKeyDisplayProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(apiKey);
    setCopied(true);
    onCopy?.();
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Stack direction="row" sx={{ p: 2, alignItems: 'center', justifyContent: 'center', width: '100%' }}>
      <Box sx={{fontSize: '0.875rem', color: 'text.secondary', border: '1px solid', borderColor: 'divider', borderRadius: 1, p: 1}}>
        <code>{apiKey}</code>
      </Box>
      <Button
        size="small"
        onClick={handleCopy}
        sx={{ color: copied ? 'success.main' : 'text.primary' }}
      >
        <Copy className="w-4 h-4 mr-2" />
        {copied ? 'Copied!' : 'Copy'}
      </Button>
    </Stack>
  );
};
