import { useMutation, useQueryClient } from "@tanstack/react-query";
import { del } from "@truefit/http-utils";
interface ApiError {
    message: string;
    status: number;
}

interface ApiKey {
    key: string;
    id: string;
    success: boolean;
}

export const useRevokeApiKeyMutation = (orgId?: number) => {
    const queryClient = useQueryClient();

    return useMutation<ApiError>({
        mutationFn: async () => await del<ApiKey>(`/user/apikey`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['apikey', orgId] });
        },
        onError: (error) => {
            // Handle error appropriately
            console.error('Failed to create group:', error);
        }
    });
};

export default useRevokeApiKeyMutation;