import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Stack,
  Divider,
  Box,
  TextField,
  InputAdornment,
  Alert,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface Role {
  id: number;
  name: string;
  description?: string;
}

interface GroupRole {
  role: Role;
}

interface Group {
  id: number;
  name: string;
  description?: string;
  grouproles: GroupRole[];
}

interface AssignRolesDialogProps {
  open: boolean;
  group: Group | null;
  roles: Role[];
  onClose: () => void;
  onSubmit: (roleIds: number[]) => void;
  initialSelectedRoles?: number[];
}

export const AssignRolesDialog: React.FC<AssignRolesDialogProps> = ({
  open,
  group,
  roles,
  onClose,
  onSubmit,
  initialSelectedRoles,
}) => {
  const [selectedRoles, setSelectedRoles] = React.useState<number[]>([]);
  const [searchQuery, setSearchQuery] = React.useState('');

  // Reset selected roles when dialog opens with a group
  React.useEffect(() => {
    if (group && open) {
      const currentRoleIds = group.grouproles.map((gr) => gr.role.id);
      setSelectedRoles(currentRoleIds);
    } else if (!open) {
      setSelectedRoles([]);
      setSearchQuery('');
    }
  }, [group, open]);

  // Use initialSelectedRoles if provided
  React.useEffect(() => {
    if (initialSelectedRoles && open) {
      setSelectedRoles(initialSelectedRoles);
    }
  }, [initialSelectedRoles, open]);

  const handleToggleRole = (roleId: number) => {
    setSelectedRoles((prev) =>
      prev.includes(roleId) ? prev.filter((id) => id !== roleId) : [...prev, roleId],
    );
  };

  const handleSelectAll = () => {
    setSelectedRoles(roles.map((role) => role.id));
  };

  const handleDeselectAll = () => {
    setSelectedRoles([]);
  };

  const handleSubmit = () => {
    onSubmit(selectedRoles);
    handleClose();
  };

  const handleClose = () => {
    onClose();
    setSearchQuery('');
    // Don't reset selectedRoles here as it might cause UI flicker
    // It will be reset when the dialog fully closes due to the useEffect
  };

  const filteredRoles = roles.filter(
    (role) =>
      role.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (role.description?.toLowerCase() || '').includes(searchQuery.toLowerCase()),
  );

  // Calculate changes for showing summary
  const currentRoleIds = group?.grouproles.map((gr) => gr.role.id) || [];
  const rolesToAdd = selectedRoles.filter((id) => !currentRoleIds.includes(id));
  const rolesToRemove = currentRoleIds.filter((id) => !selectedRoles.includes(id));
  const hasChanges = rolesToAdd.length > 0 || rolesToRemove.length > 0;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: { minHeight: '60vh' },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(4px)', // Default blur effect
          },
        },
      }}
    >
      <DialogTitle>Manage Roles - {group?.name}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            fullWidth
            size="small"
            placeholder="Search roles..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Box display="flex" justifyContent="space-between">
            <Button size="small" variant="outlined" onClick={handleSelectAll}>
              Select All
            </Button>
            <Button size="small" variant="outlined" onClick={handleDeselectAll}>
              Deselect All
            </Button>
          </Box>

          <Divider />

          {hasChanges && (
            <Alert severity="info" sx={{ mt: 1 }}>
              {rolesToAdd.length > 0 && <div>Adding: {rolesToAdd.length} role(s)</div>}
              {rolesToRemove.length > 0 && <div>Removing: {rolesToRemove.length} role(s)</div>}
            </Alert>
          )}

          <Box sx={{ maxHeight: '50vh', overflow: 'auto' }}>
            <FormGroup>
              {filteredRoles.map((role) => (
                <Box key={role.id} sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedRoles.includes(role.id)}
                        onChange={() => handleToggleRole(role.id)}
                      />
                    }
                    label={
                      <Box>
                        <Typography variant="body1">{role.name}</Typography>
                        {role.description && (
                          <Typography variant="body2" color="text.secondary">
                            {role.description}
                          </Typography>
                        )}
                      </Box>
                    }
                  />
                </Box>
              ))}
              {filteredRoles.length === 0 && (
                <Typography color="text.secondary" align="center">
                  No roles found matching your search
                </Typography>
              )}
            </FormGroup>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" disabled={!hasChanges}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
