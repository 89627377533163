import Typography from '@mui/material/Typography';
import AuthenticationPage from './AuthenticationPage';
import SignInForm from './SignInForm';

const SignInPage = () => (
    <AuthenticationPage>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Log In
      </Typography>

      <SignInForm />
    </AuthenticationPage>
);

export default SignInPage;
