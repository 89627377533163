import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import { nameofFactory } from '../../../shared/services';
import { FormSubmitBar, FormValidationSummary, HookFormTextField } from '../../forms/components';
import useCognito from '../hooks/useCognito';

export type ForgotPasswordFormValues = {
  email: string;
};

const nameOf = nameofFactory<ForgotPasswordFormValues>();

const validationSchema = Yup.object().shape({
  [nameOf('email')]: Yup.string().required().email().max(256).label('Email'),
});

const useForgotPassword = () => {
  const cognito = useCognito();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: { [nameOf('email')]: '' },
    resolver: yupResolver(validationSchema),
  });
  const [generalValidationErrors, setGeneralValidationErrors] = React.useState<string[]>([]);

  const handleSubmit = form.handleSubmit(
    (formValues) =>
      new Promise((resolve) => {
        cognito.forgotPassword({
          username: formValues.email,

          onSuccess() {
            enqueueSnackbar('If you have an account, we emailed you a code.', {
              variant: 'success',
            });
            navigate('/change-password');
            resolve();
          },

          onFailure(message) {
            setGeneralValidationErrors([message]);
            resolve();
          },
        });
      }),
  );

  return { form, generalValidationErrors, handleSubmit };
};

const ForgotPasswordForm = () => {
  const { form, generalValidationErrors, handleSubmit } = useForgotPassword();

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} noValidate style={{ width: '100%' }}>
        <FormValidationSummary validationErrors={generalValidationErrors} />

        <HookFormTextField
          name={nameOf('email')}
          type="email"
          autoFocus
          required
          label="Email"
          variant="outlined"
          margin="dense"
        />

        <FormSubmitBar
          submitting={form.formState.isSubmitting}
          cancelTo="/sign-in"
          onSubmit={handleSubmit}
        />
      </form>
    </FormProvider>
  );
};

export default ForgotPasswordForm;
