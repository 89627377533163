// 1. Imports
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { startCase } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import { Tracking } from '@/shared/services/tracking';

// Hooks imports
import useAddClipMutation from '../../features/clips/hooks/useAddClipMutation';
import useDeleteClipMutation from '../../features/clips/hooks/useDeleteClipMutation';
import useUpdateClipMutation from '../../features/clips/hooks/useUpdateClipMutation';
import useConfirmationDialogContext from './useConfirmationDialogContext';
import useCurrentGameId from './useCurrentGameId';
import { usePlaybackSpeedRef } from '@/shared/hooks/websocket/usePlaybackSpeed';
import useServerStateContext from './useServerStateContext';
import { useTimestampRef } from '@/shared/hooks/websocket/useTimestamp';
import { usePTZStore } from '@/features/camera/store/usePTZStore';
import { usePTZSocket } from './websocket/usePTZSocket';

// Types and services imports
import { toClipWithSyncedTimes } from '../../features/clips/services';
import { ClipType, Tag, ClipModel } from '../../features/clips/types';
import displayWallClock from '../services/displayWallClock';
import { ClipState } from '../types/ClipState';
import {
  MANUAL_TRACKING_OR_NO_TRACKING,
  ServerStateAndFunctions,
} from './useServerState';

import { isInvalid } from '../services/isInvalid';

const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

interface PTZValues {
  pan: number;
  tilt: number;
  zoom: number;
}

const DEFAULT_PTZ: PTZValues = {
  pan: 0,
  tilt: 0,
  zoom: 0,
};

const DIRTIABLE_BOOKMARK_FIELDS: (keyof ClipModel)[] = ['id', 'note', 'description', 'type'];

const DIRTIABLE_CLIP_FIELDS: (keyof ClipModel)[] = [
  'id',
  'note',
  'description',
  'type',
  'startTimestamp',
  'endTimestamp',
  'cameraId',
  'objectTrackingId',
  'pan',
  'tilt',
  'zoom',
];

const getClipDetailsFromServerState = (
  type: ClipType,
  serverState: ServerStateAndFunctions,
  playbackPositionTimestamp: Date,
  pan: number,
  tilt: number,
  zoom: number,
): Omit<ClipModel, 'gameId' | 'endTimestamp'> => {
  const { selectedCameraId, singleCurrentlyTrackedObjectId } = serverState;

  return {
    type,
    description: '',
    startTimestamp: playbackPositionTimestamp,
    endTimestamp: playbackPositionTimestamp,
    cameraId: selectedCameraId,
    objectTrackingId: singleCurrentlyTrackedObjectId,
    pan,
    tilt,
    zoom,
    tags: [],
  };
};

const DEFAULT_TAGS_FILTER: Tag[] = [];
const DEFAULT_FILTER = 'all';

const useClipState = () => {
  const gameId = useCurrentGameId();

  const [filter, setFilter] = React.useState<ClipType | 'all'>(DEFAULT_FILTER);
  const [tagsFilter, setTagsFilter] = React.useState<Tag[]>([]);
  const [clipState, setClipState] = React.useState<ClipState>({});

  const playbackPositionTimestamp = React.useRef<Date>(new Date());
  const serverStateContext = useServerStateContext();
  const { getServerStateAndFunctions, trackEntity, selectCamera, gameStateManager } =
    serverStateContext || {};

  const addClipMutation = useAddClipMutation();

  if(!getServerStateAndFunctions) {
    throw new Error('getServerStateAndFunctions is not defined');
  }


  const totalClipState = React.useMemo(
    () => ({
      ...clipState,
      isEditing: !!clipState.editingModel,
      isEditingClip: clipState.editingModel?.type === ClipType.Clip,
      isEditingBookmark: clipState.editingModel?.type === ClipType.Bookmark,
      filter,
      tagsFilter,
    }),
    [clipState, filter, tagsFilter, clipState],
  );

  const clipRecordRefsLookup = React.useRef<Record<number, React.MutableRefObject<HTMLElement>>>(
    {},
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const setConfirmationDialog = useConfirmationDialogContext();

  React.useEffect(() => {
    if (!gameStateManager) return;

    const handleHeadChange = (head: number) => {
      playbackPositionTimestamp.current = new Date(head / 1_000_000);
    };

    gameStateManager.on('headChange', handleHeadChange);

    return () => {
      if (gameStateManager.off) gameStateManager.off('headChange', handleHeadChange);
    };
  }, [gameStateManager]);

  const updateClipMutation = useUpdateClipMutation();
  const deleteClipMutation = useDeleteClipMutation();

  const [, setPlaybackPositionTimestamp] = useTimestampRef();

  const [, setPlaybackSpeed] = usePlaybackSpeedRef();

  const { getValue: getPTZ } = usePTZStore();
  const { setValue: setPTZ } = usePTZSocket();

  const clipStateRef = React.useRef(totalClipState);

  React.useEffect(() => {
    clipStateRef.current = totalClipState;
  }, [totalClipState]);

  const getCurrentClipState = React.useCallback(() => {
    return clipStateRef.current;
  }, []);

  const handleScrollToClipRecord = React.useCallback((clipId: number) => {
    setTimeout(() => {
      clipRecordRefsLookup.current[clipId]?.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }, 100);
  }, []);

  const handleApplyCameraSettingsToServer = React.useCallback(
    (model: ClipModel) => {
      if (model.type === ClipType.Clip && selectCamera) {
        selectCamera(model.cameraId, 'ClipState');
        if (model.objectTrackingId && trackEntity) {
          trackEntity({ id: model.objectTrackingId }, 'ClipState');
        } else if (model.objectTrackingId === MANUAL_TRACKING_OR_NO_TRACKING.id) {
          console.warn('Tracking is manual for this clip ... ');
          // setting the PTZ values to the camera values
          setPTZ({
            pan: model.pan as number,
            tilt: model.tilt as number,
            zoom: model.zoom as number,
          });
        }
      }
    },
    [trackEntity, selectCamera, setPTZ],
  );

  const handleView = React.useCallback(
    async (modelToView: ClipModel) => {
      if (modelToView.type === ClipType.Clip) {
        // this will set the camera and the tracked object
        handleApplyCameraSettingsToServer(modelToView);
      }

      if (setPlaybackSpeed) setPlaybackSpeed(0);
      await delay(200);
      if (setPlaybackPositionTimestamp) setPlaybackPositionTimestamp(modelToView.startTimestamp);
      await delay(200);

      Tracking.getInstance().track('View in Timeline', {
        category: 'Bookmarks/Clips',
        type: modelToView.type,
      });
    },
    [handleApplyCameraSettingsToServer, setPlaybackPositionTimestamp, setPlaybackSpeed],
  );

  const handleStartEditing = React.useCallback(
    async (modelToEdit: ClipModel) => {
      if (modelToEdit.type === ClipType.Clip) {
        handleApplyCameraSettingsToServer(modelToEdit);
      }

      if (setPlaybackSpeed) setPlaybackSpeed(0);
      await delay(200);
      if (setPlaybackPositionTimestamp) setPlaybackPositionTimestamp(modelToEdit.startTimestamp);
      await delay(200);

      setClipState((state: ClipState) => ({
        ...state,
        editingModel: modelToEdit,
        originalModel: modelToEdit,
      }));

      setFilter(DEFAULT_FILTER);
      setTagsFilter(DEFAULT_TAGS_FILTER);

      handleScrollToClipRecord(modelToEdit.id);

      console.debug('[Start Editing] Done');
    },
    [
      handleApplyCameraSettingsToServer,
      handleScrollToClipRecord,
      setPlaybackPositionTimestamp,
      setPlaybackSpeed,
    ],
  );

  const handleReset = React.useCallback(
    () =>
      setClipState((state: ClipState) => ({
        ...state,
        editingModel: undefined,
        originalModel: undefined,
      })),
    [],
  );

  const getSyncedModel = React.useCallback(
    (newModel: ClipModel): ClipModel => {

      const withSyncedTimes = toClipWithSyncedTimes(newModel, playbackPositionTimestamp.current);

      const ptzValues: PTZValues = (() => {
        try {
          if (!getPTZ) return DEFAULT_PTZ;
          const values = getPTZ(getServerStateAndFunctions().selectedCameraId);
          if (!values) return DEFAULT_PTZ;

          return {
            pan: Number(values.pan) || 0,
            tilt: Number(values.tilt) || 0,
            zoom: Number(values.zoom) || 0,
          };
        } catch (error) {
          console.warn('Error getting PTZ values:', error);
          return DEFAULT_PTZ;
        }
      })();

      const { pan, tilt, zoom } = ptzValues;

      const withUpdatedCameraAndTracking = {
        ...withSyncedTimes,
        pan,
        tilt,
        zoom,
        cameraId: getServerStateAndFunctions().selectedCameraId,
        objectTrackingId: getServerStateAndFunctions().singleCurrentlyTrackedObjectId,
      };

      return withUpdatedCameraAndTracking;
    },

    [getServerStateAndFunctions, getPTZ, playbackPositionTimestamp],
  );

  const handleSubmit = React.useCallback(
    async (newModel: ClipModel) => {
      const syncedModel = getSyncedModel(newModel);
      await updateClipMutation.mutateAsync(syncedModel);
      handleReset();
      const { type } = syncedModel;
      enqueueSnackbar(
        `Your edits to ${syncedModel.note} at ${displayWallClock(
          syncedModel.startTimestamp,
        )} have been saved.`,
        {
          action: (id) => (
            <IconButton size="small" onClick={() => closeSnackbar(id)}>
              <CloseIcon sx={{ color: 'black' }} />
            </IconButton>
          ),
        },
      );

      Tracking.getInstance().track('Edit Clip/Bookmark', {
        category: 'Bookmarks/Clips',
        type,
      });
    },
    [closeSnackbar, enqueueSnackbar, getSyncedModel, handleReset, updateClipMutation],
  );

  const handleSetStartTimeOnSlider = React.useCallback(
    (startTimestamp: Date) => {
      const { editingModel } = getCurrentClipState();
      if (!editingModel) {
        console.warn('No editing model found');
      }
      if (editingModel?.type !== ClipType.Clip) return;
      setClipState((state) => ({
        ...state,
        editingModel: {
          ...state.editingModel,
          startTimestamp,
        } as ClipModel,
      }));
    },
    [getCurrentClipState],
  );

  const handleSetEndTimeOnSlider = React.useCallback(
    (endTimestamp: Date) => {
      const { editingModel } = getCurrentClipState();
      if (!editingModel) {
        console.warn('No editing model found');
      }

      if (editingModel?.type !== ClipType.Clip) return;
      setClipState((state) => ({
        ...state,
        editingModel: {
          ...state.editingModel,
          endTimestamp,
        } as ClipModel,
      }));
    },
    [getCurrentClipState],
  );

  const handleSetStartTime = React.useCallback(() => {
    const { editingModel } = getCurrentClipState();
    if (!editingModel) {
      console.warn('No editing model found');
    }

    if (editingModel?.type !== ClipType.Clip) return;

    setClipState((state) => {
      if (!state.editingModel) return state;
      return {
        ...state,
        editingModel: {
          ...state.editingModel,
          startTimestamp:
            playbackPositionTimestamp.current > state.editingModel.endTimestamp
              ? state.editingModel.endTimestamp
              : playbackPositionTimestamp.current,
          endTimestamp:
            playbackPositionTimestamp.current > state.editingModel.endTimestamp
              ? playbackPositionTimestamp.current
              : state.editingModel.endTimestamp,
        } as ClipModel,
      };
    });
  }, [getCurrentClipState, playbackPositionTimestamp]);

  const handleSetEndTime = React.useCallback(() => {
    const { editingModel } = getCurrentClipState();
    if (!editingModel) {
      console.warn('No editing model found');
    }

    if (editingModel?.type !== ClipType.Clip) return;

    setClipState((state) => {
      if (!state.editingModel) return state;
      return {
        ...state,
        editingModel: {
          ...state.editingModel,
          startTimestamp:
            playbackPositionTimestamp.current < state.editingModel.startTimestamp
              ? playbackPositionTimestamp.current
              : state.editingModel.startTimestamp,
          endTimestamp:
            playbackPositionTimestamp.current < state.editingModel.startTimestamp
              ? state.editingModel.startTimestamp
              : playbackPositionTimestamp.current,
        } as ClipModel,
      };
    });
  }, [getCurrentClipState, playbackPositionTimestamp]);

  const handleSetBookmarkTime = React.useCallback(() => {
    const { editingModel } = getCurrentClipState();
    if (!editingModel) {
      console.warn('No editing model found');
    }

    if (editingModel?.type !== ClipType.Bookmark) return;
    setClipState((state) => ({
      ...state,
      editingModel: {
        ...state.editingModel,
        startTimestamp: playbackPositionTimestamp.current,
      } as ClipModel,
    }));
  }, [getCurrentClipState, playbackPositionTimestamp]);

  const handleDeleteClip = React.useCallback(
    async (clipModel: ClipModel) => {
      await deleteClipMutation.mutateAsync(clipModel.id);
      setClipState((state: ClipState) => ({
        ...state,
        editingModel: undefined,
        originalModel: undefined,
      }));
    },
    [deleteClipMutation],
  );

  const handleDelete = React.useCallback(
    (clipModel: ClipModel) => {
      setConfirmationDialog({
        confirmButtonStyle: 'error',
        title: `Delete ${clipModel.type === ClipType.Clip ? 'Clip' : 'Bookmark'}`,
        message: (
          <Typography>
            This action cannot be undone.
            {clipModel.type === ClipType.Clip ? ' Clip ' : ' Bookmark '}
            <Typography sx={{ fontWeight: '700' }} component="span">
              {clipModel.note}
            </Typography>{' '}
            will be deleted.
          </Typography>
        ),
        confirmText: `Delete ${clipModel.type === ClipType.Clip ? 'Clip' : 'Bookmark'}`,
        cancelText: 'Cancel',
        onConfirm: () => handleDeleteClip(clipModel),
      });
    },
    [deleteClipMutation, setConfirmationDialog],
  );

  const handleConvertToClip = React.useCallback(
    (clipModel: ClipModel) => {
      if (clipModel.type !== ClipType.Bookmark) return;

      setConfirmationDialog({
        title: 'Convert Bookmark to Clip',
        message: (
          <Typography>
            This action cannot be undone. Bookmark{' '}
            <Typography sx={{ fontWeight: '700' }} component="span">
              {clipModel.note}
            </Typography>{' '}
            will be replaced with a new clip.
          </Typography>
        ),
        cancelText: 'Cancel',
        confirmText: 'Convert Bookmark',
        onConfirm: async () => {
          await updateClipMutation.mutateAsync({
            ...clipModel,
            type: ClipType.Clip,
          });

          enqueueSnackbar(
            `${clipModel.note} at ${displayWallClock(
              clipModel.startTimestamp,
            )} has been converted to a clip.`,
            {
              action: (id) => (
                <IconButton size="small" onClick={() => closeSnackbar(id)}>
                  <CloseIcon sx={{ color: 'black' }} />
                </IconButton>
              ),
            },
          );
        },
      });
    },
    [closeSnackbar, enqueueSnackbar, setConfirmationDialog, updateClipMutation],
  );

  const handleCancelEditing = React.useCallback(
    (newModel: ClipModel) => {
      const { editingModel, originalModel } = getCurrentClipState();
      if (!editingModel || !originalModel) return;

      const syncedModel = getSyncedModel(newModel);

      const keys =
        editingModel.type === ClipType.Clip ? DIRTIABLE_CLIP_FIELDS : DIRTIABLE_BOOKMARK_FIELDS;

      if (
        keys.some(
          (key) =>
            syncedModel[key] !== originalModel[key] &&
            (![undefined, null].includes(syncedModel[key]) ||
              ![undefined, null].includes(originalModel[key])),
        )
      ) {
        setConfirmationDialog({
          title: 'Cancel Changes',
          message: (
            <Typography>
              This action cannot be undone. Your changes to
              {syncedModel.type === ClipType.Clip ? ' clip ' : ' bookmark '}
              <Typography sx={{ fontWeight: '700' }} component="span">
                {syncedModel.note}
              </Typography>{' '}
              will be lost.
            </Typography>
          ),
          onConfirm: handleReset,
          confirmText: 'Cancel Changes',
          cancelText: 'Cancel',
        });
      } else {
        handleReset();
      }
    },
    [getCurrentClipState, getSyncedModel, handleReset, setConfirmationDialog],
  );

  const handleAddTag = React.useCallback(
    async (model: ClipModel, tag: Tag) => {
      const { editingModel } = getCurrentClipState();

      if (editingModel && editingModel.id === model.id) {
        setClipState((state) => ({
          ...state,
          editingModel: {
            ...editingModel,
            ClipTag: [...editingModel.tags, tag],
          },
        }));
      }

      await updateClipMutation.mutateAsync({ ...model, tags: [...model.tags, tag] });

      enqueueSnackbar(`${tag} tag applied to ${model.note}.`, {
        action: (id) => (
          <IconButton size="small" onClick={() => closeSnackbar(id)}>
            <CloseIcon sx={{ color: 'black' }} />
          </IconButton>
        ),
      });
    },
    [closeSnackbar, enqueueSnackbar, getCurrentClipState, updateClipMutation],
  );

  const handleRemoveTag = React.useCallback(
    async (model: ClipModel, tag: string) => {
      const { editingModel } = getCurrentClipState();

      if (editingModel && editingModel.id === model.id) {
        setClipState((state) => ({
          ...state,
          editingModel: {
            ...editingModel,
            tags: editingModel.tags.filter((t) => t?.tag.name !== tag),
          },
        }));
      }

      await updateClipMutation.mutateAsync({
        ...model,
        tags: model.tags.filter((t) => t?.tag.name !== tag),
      });

      enqueueSnackbar(`${tag} tag removed from ${model.note}.`, {
        action: (id) => (
          <IconButton size="small" onClick={() => closeSnackbar(id)}>
            <CloseIcon sx={{ color: 'black' }} />
          </IconButton>
        ),
      });
    },
    [closeSnackbar, enqueueSnackbar, getCurrentClipState, updateClipMutation],
  );

  const handleSetClipRecordRef = React.useCallback(
    (clipId: number, ref: React.MutableRefObject<HTMLElement>) => {
      clipRecordRefsLookup.current = { ...clipRecordRefsLookup.current, [clipId]: ref };
    },
    [],
  );

  const handleAdd = React.useCallback(
    async (type: ClipType): Promise<void> => {
      try {
        
        // Guard clauses grouped together at the start
        if (getCurrentClipState().isEditing) {
          console.debug('Ignoring add request while editing');
          return;
        }

        if (isInvalid(getServerStateAndFunctions().selectedCameraId, 'number')) {
          throw new Error('No camera selected');
        }

        // Get PTZ values with error handling
        const ptzValues: PTZValues = (() => {
          try {
            if (!getPTZ) return DEFAULT_PTZ;
            const values = getPTZ(getServerStateAndFunctions().selectedCameraId);
            if (!values) return DEFAULT_PTZ;

            return {
              pan: Number(values.pan) || 0,
              tilt: Number(values.tilt) || 0,
              zoom: Number(values.zoom) || 0,
            };
          } catch (error) {
            console.warn('Error getting PTZ values:', error);
            return DEFAULT_PTZ;
          }
        })();

        // Ensure timestamp exists
        const timestamp = playbackPositionTimestamp.current;
        if (!timestamp) throw new Error('No playback timestamp available');

        if (!getClipDetailsFromServerState)
          throw new Error('getClipDetailsFromServerState is not defined');



        // Create clip with validated data
        const newClip = toClipWithSyncedTimes(
          getClipDetailsFromServerState(
            type,
            getServerStateAndFunctions(),
            timestamp,
            ptzValues.pan,
            ptzValues.tilt,
            ptzValues.zoom,
          ),
          timestamp,
        );


        // Save clip with retry logic
        const savedClip = await addClipMutation.mutateAsync({ ...newClip, gameId });

        // Update clip note
        const clipNote = `${startCase(savedClip.type.toString())} ${savedClip.id}`;
        savedClip.note = clipNote;

        // Start editing the saved clip
        await handleStartEditing(savedClip);

        // Track the successful action
        Tracking.getInstance().track('Add Clip/Bookmark', {
          category: 'Bookmarks/Clips',
          type,
          success: true,
        });
      } catch (error) {
        // Error handling
        console.error('Error adding clip:', error);

        // Track the failed action
        Tracking.getInstance().track('Add Clip/Bookmark', {
          category: 'Bookmarks/Clips',
          type,
          success: false,
          error: error instanceof Error ? error.message : 'Unknown error',
        });

        // Show error to user
        enqueueSnackbar(error instanceof Error ? error.message : 'Failed to add clip', {
          variant: 'error',
        });

        // Rethrow if needed
        throw error;
      }
    },
    [
      addClipMutation,
      clipState.editingModel?.id,
      closeSnackbar,
      enqueueSnackbar,
      gameId,
      handleStartEditing,
      getPTZ,
      playbackPositionTimestamp,
      getServerStateAndFunctions(),
    ],
  );

  const handleAddBookmark = React.useCallback(() => {
    handleAdd(ClipType.Bookmark);
  }, [handleAdd]);

  const handleAddClip = React.useCallback(() => {
    handleAdd(ClipType.Clip);
  }, [handleAdd]);

  return {
    state: totalClipState,
    getCurrentClipState,
    handleAddBookmark,
    handleAddClip,
    handleAddTag,
    handleCancelEditing,
    handleConvertToClip,
    handleDelete,
    handleRemoveTag,
    handleSetBookmarkTime,
    handleSetClipRecordRef,
    handleSetEndTime,
    handleSetEndTimeOnSlider,
    handleSetStartTimeOnSlider,
    handleSetStartTime,
    handleStartEditing,
    handleSubmit,
    handleView,
    setFilter,
    setTagsFilter,
  };
};

export type ClipStateAndFunctions = ReturnType<typeof useClipState>;

export default useClipState;
