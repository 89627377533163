import { Alert, Button, Card, CardContent, Stack, Typography, Box } from '@mui/material';
import { ApiKeyDisplay } from './ApiKeyDisplay';
import { useApiKey } from '../hooks/useApiKey';
import { LoadingSpinner } from '@/shared/components/LoadingSpinner';

export const ApiKeyManagement = () => {
  const { apiKey, displayKey, loading, error, createApiKey, deleteApiKey } = useApiKey();

  if (loading) {
    return <LoadingSpinner text="Loading..." />;
  }

  return (
    <Card>
      <CardContent sx={{ pt: 4, pb: 4 }}>
        {error && (
          <Alert severity="error" variant="outlined">
            {error}
          </Alert>
        )}

        {!apiKey?.id && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
              }}
            >
              <Typography>You haven't created an API key yet</Typography>
              <Button variant="contained" onClick={createApiKey} disabled={loading}>
                Create API Key
              </Button>
            </Box>
          </>
        )}

        {apiKey?.id && (
          <>
            {displayKey && (
              <Stack direction="column" spacing={2} sx={{ mb: 2, alignItems: 'center', alignSelf: 'center' }}>
                <Alert severity="warning" sx={{ border: '1px solid', borderColor: 'info.warning' }}>
                  Make sure to copy your API key now. You won't be able to see it again!
                </Alert>
                <ApiKeyDisplay apiKey={displayKey} />
              </Stack>
            )}

            <Stack
              direction="row"
              spacing={2}
              sx={{ alignItems: 'center', justifyContent: 'center' }}
            >
              {!displayKey && (<code>{apiKey.shortKey}...</code>)}{' '}
              <Button variant="contained" color="error" onClick={deleteApiKey} disabled={loading}>
                Revoke
              </Button>
            </Stack>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ApiKeyManagement;
