import React from 'react';
import useGameSwitchQuery from '../hooks/useGameSwitchQuery';
import ServerStatus from '@/features/timeMachine/components/ServerStatus';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import { useNavigate } from 'react-router';

const LoadingGame = () => {
  const gameId = useCurrentGameId();
  const [loadingFlag, setLoadingFlag] = React.useState(true);
  const [errorFlag, setErrorFlag] = React.useState(true);
  const switchGameQuery = useGameSwitchQuery(gameId);
  const navigate = useNavigate();


  

  React.useEffect(() => {
    setLoadingFlag(['loading', 'pending'].includes(switchGameQuery.status));
    setErrorFlag(switchGameQuery.status === 'error');
  }, [switchGameQuery.status]);

  const handleCancel = () => {
    navigate('/games');
  };

  return (
    <ServerStatus
      open={loadingFlag || errorFlag}
      title="Loading Game"
      message={errorFlag ? 'Error loading game.' : 'Please wait while we set things up for you!'}
      onYes={handleCancel}
      retryable={errorFlag}
      onRetry={() => {
        switchGameQuery.refetch();
      }}
      onClose={() => {}}
      cancelButtonColor="secondary"
      cancelButtonText={errorFlag ? 'Back to Game Selection' : 'Cancel'}
      requiresConfirmation={!errorFlag}
      cancellable
    />
  );
};

export default LoadingGame;
