import { ClipStatus } from './ClipStatus';
import { ClipType } from './ClipType';
import { Tag } from './Tag';

// Base interface with common properties
export interface BaseClipModel {
  id: number;
  cameraId: number;
  description: string;
  clouduser: any;
  createdAt: Date;
  note: string;
  objectTrackingId: number;
  startTimestamp: Date;
  endTimestamp?: Date;
  status: ClipStatus;
  tags: Tag[];
  type: ClipType;
  cliptags: ClipTag[];
  pan?: number;
  tilt?: number;
  zoom?: number;
}

// Additional properties for clips
export interface ClipTag {
  clipId: number;
  tagId: number;
  tag: Tag;
  clip?: ClipModel;
}



// BookMark extends base model without adding new properties
export type BookMarkFromServer = BaseClipModel;

// ClipModel extends base model with additional properties
export interface ClipModelFromServer extends BaseClipModel {
  downloadUrl: string;
  endTimestamp: Date;
}

// Union type for cases where either type is acceptable
export type ClipModel = ClipModelFromServer | BookMarkFromServer;

// Helper type guard to distinguish between the two types
export function isClip(clip: ClipModel): clip is ClipModelFromServer {
  return clip.type === ClipType.Clip;
}