import React, { ReactNode } from 'react';

import useClipState, { ClipStateAndFunctions } from '../hooks/useClipState';

type Props = {
  children: ReactNode;
};

export const ClipStateContext = React.createContext<ClipStateAndFunctions>({} as ClipStateAndFunctions);

const ClipStateProvider = ({ children }: Props) => {
  const clipState = useClipState();
  return <ClipStateContext.Provider value={clipState}>{children}</ClipStateContext.Provider>;
};

export default ClipStateProvider;
