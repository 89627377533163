import { getTeamLogoPath } from '@/shared/hooks/getTeamLogoPath';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';

const GameMatchup = ({ game }) => {
  const { homeTeam, awayTeam } = game;
  if (!homeTeam || !awayTeam) return <></>;

  const awayLogoPath = getTeamLogoPath(awayTeam);
  const homeLogoPath = getTeamLogoPath(homeTeam);

  const homePrimaryTeamColor = useMemo(
    () =>
      game.homeTeamColor ||
      homeTeam.teamColors?.find((c) => c.type === 'primary')?.hex_color ||
      '#000000',
    [game.homeTeamColor, homeTeam.teamColors],
  );

  const awayPrimaryTeamColor = useMemo(
    () =>
      game.awayTeamColor ||
      awayTeam.teamColors?.find((c) => c.type === 'primary')?.hex_color ||
      '#000000',
    [game.awayTeamColor, awayTeam.teamColors],
  );

  return (
    <div
      style={{
        background: `linear-gradient(90deg, ${awayPrimaryTeamColor},black 50%, ${homePrimaryTeamColor})`,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{width: '100px', display: 'flex', justifyContent: 'flex-end'}}>
        <img loading="lazy" width="40" src={awayLogoPath} alt={awayTeam.abbreviation} />
      </Box>
      <Typography variant="h6">{awayTeam.abbreviation}</Typography>
      VS
      <Typography variant="h6">{homeTeam.abbreviation}</Typography>
      <Box sx={{width: '100px', display: 'flex', justifyContent: 'flex-start'}}>
        <img loading="lazy" width="40" src={homeLogoPath} alt={homeTeam.abbreviation} />
      </Box>
    </div>
  );
};

export default GameMatchup;
