import React, { useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import Slider from '@mui/material/Slider';
import { ZoomIn } from 'lucide-react';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import { buildIntervals, findMatchingIntervals } from '@/shared/services/findCountainingIntervals';
import { differenceInMilliseconds } from 'date-fns';

const PRIMARY_COLOR = '#ffffff';

type AudioControlsProps = {
  onVolumeChange?: (zoom: number) => void;
  onMuteToggle?: (isMuted: boolean) => void;
  initialVolume?: number;
  initialMuted?: boolean;
};

const AudioControls: React.FC<AudioControlsProps> = ({ gameStart, gameDuration, onZoomChange }) => {
  const [zoom, setZoom] = useState<number>(1);

  const handleZoomChange = (_event: Event, newValue: number | number[]) => {
    const newVolume = newValue as number;
    setZoom(newVolume);
    onZoomChange?.(newVolume);
  };

  const {
    state: { editingModel },
  } = useClipStateContext();

  const clipValues = useMemo(
    () => ({
      start: differenceInMilliseconds(editingModel?.startTimestamp as Date, gameStart) / 1000,
      end: differenceInMilliseconds(editingModel?.endTimestamp as Date, gameStart) / 1000,
    }),
    [editingModel],
  );

  const intervals = useMemo(() => buildIntervals(gameDuration), [gameDuration]);

  const matchingIntervals = useMemo(
    () => findMatchingIntervals(clipValues.start, clipValues.end, intervals),
    [clipValues, intervals],
  );

  const max = useMemo(() => Object.keys(matchingIntervals).length+1, [matchingIntervals]);

  return (
    <>
      {max > 1 && (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ width: 200 }}>
          <ZoomIn />

          <Slider
            size="small"
            value={zoom}
            onChange={handleZoomChange}
            aria-label="Volume"
            min={1}
            max={max}
            step={1}
            marks
            sx={{
              width: 100,
              color: PRIMARY_COLOR,
              '& .MuiSlider-track': {
                border: 'none',
              },
              '& .MuiSlider-thumb': {
                width: 12,
                height: 12,
                backgroundColor: '#fff',
                '&:hover, &.Mui-focusVisible': {
                  boxShadow: '0 0 0 8px rgba(255, 255, 255, 0.16)',
                },
              },
            }}
          />
        </Stack>
      )}
    </>
  );
};

export default AudioControls;
