import * as React from 'react';
import { Divider, ListItemText, Stack, Typography } from '@mui/material';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';
import { getTeamLogoPath } from '@/shared/hooks/getTeamLogoPath';
import { PeriodNamingMapping } from '@/features/autoIso/types/GameTypeMetadataModel';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import { useMemo } from 'react';
import { getTeamColor } from '@/features/teams/functions/getTeamColor';
import { GameModel } from '../types/GameModel';

const getOrdinalSuffix = (i: number) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) return 'st';
  if (j === 2 && k !== 12) return 'nd';
  if (j === 3 && k !== 13) return 'rd';
  return 'th';
};

function splitNumber(n: number): [number, number] {
  const base = Math.floor(n / 100) * 100;
  const remainder = n % 100;
  return [base, remainder];
}

type GameScoring = {
  period: number;
  score: {
    home: number;
    away: number;
  };
  clock: string;
};

type Props = {
  data: GameModel;
};

const ScoreboardData: React.FC<Props> = ({ data }) => {
  const gameTypeMetadataQuery = useGameTypeMetadataQuery();
  const serverState = useServerStateContext();
  const gameStateManager = serverState?.gameStateManager;

  const [gameScoring, setGameScoring] = React.useState({
    period: -1,
    score: {
      home: 0,
      away: 0,
    },
    clock: '00:00',
  });

  React.useEffect(() => {
    if (!gameStateManager) return;

    const updateGameScoring = <T extends keyof GameScoring>(key: T, value: GameScoring[T]) => {
      setGameScoring((prev) => ({
        ...prev,
        [key]: value,
      }));
    };

    const handleScoreChange = (homeScore: number, awayScore: number) => {
      updateGameScoring('score', { home: homeScore, away: awayScore });
    };

    const handleClockChange = (clock: string) => {
      updateGameScoring('clock', clock);
    };

    const handlePeriodChange = (period: number) => {
      updateGameScoring('period', period);
    };

    gameStateManager.on('scoreChange', handleScoreChange);
    gameStateManager.on('clockChange', handleClockChange);
    gameStateManager.on('periodChange', handlePeriodChange);

    return () => {
      if (gameStateManager.off) {
        gameStateManager.off('scoreChange', handleScoreChange);
        gameStateManager.off('clockChange', handleClockChange);
        gameStateManager.off('periodChange', handlePeriodChange);
      }
    };
  }, [gameStateManager]);

  const homeTeamPrimaryColor = useMemo(() => {
    const fallBackColor = '#000000';
    if (data?.homeTeam) return getTeamColor(data?.homeTeam, data, fallBackColor);
    return fallBackColor;
  }, [data?.homeTeam]);

  const awayTeamPrimaryColor = useMemo(() => {
    const fallBackColor = '#000000';
    if (data?.awayTeam) return getTeamColor(data?.awayTeam, data, fallBackColor);
    return fallBackColor;
  }, [data?.awayTeam]);

  // Memoize the period name calculation
  const periodName = React.useMemo(() => {
    if (gameScoring.period === -1) return '';

    const periodsMappings = gameTypeMetadataQuery.data
      ?.periodNamingMapping[0] as PeriodNamingMapping;

    if (!periodsMappings) return `Period ${gameScoring.period}`;

    const { mappings } = periodsMappings;
    const [base, remainder] = splitNumber(gameScoring.period);

    if (base === 0 && remainder === 0) return '';

    const periodName = mappings[base] || periodsMappings.periodName;
    return `${remainder}${getOrdinalSuffix(remainder)} ${periodName}`;
  }, [gameTypeMetadataQuery.data, gameScoring.period]);

  // Early return if gameState is not available
  if (!gameScoring) {
    return (
      <Stack
        direction="row"
        spacing={3}
        sx={{ flexGrow: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
      />
    );
  }

  const awayLogoPath = useMemo(() => getTeamLogoPath(data.awayTeam), [data.awayTeam]);
  const homeLogoPath = useMemo(() => getTeamLogoPath(data.homeTeam), [data.homeTeam]);

  if (!data.awayTeam || !data.homeTeam) {
    return (
      <Stack
        direction="row"
        spacing={3}
        sx={{ flexGrow: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
      />
    );
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      divider={<Divider orientation="vertical" flexItem />}
      sx={{ flexGrow: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
    >
      {/* Away Team */}
      <Stack direction="row" spacing={3} alignItems="center" sx={{ marginLeft: 4, marginRight: 4 }}>
        <Stack
          direction="row"
          spacing={0}
          alignItems="center"
          sx={{
            backgroundImage: `radial-gradient(circle, ${awayTeamPrimaryColor.concat('AA')} 0%, ${awayTeamPrimaryColor.concat('00')} 100%)`,
          }}
        >
          <img
            loading="lazy"
            width="40"
            src={awayLogoPath}
            alt={data.awayTeam?.abbreviation || ''}
          />
          <ListItemText
            primary={data.awayTeam?.abbreviation || ''}
            secondary={data.awayTeam.name}
          />
          <Typography
            variant="h4"
            fontWeight="bold"
            minWidth={50}
            textAlign={'center'}
            sx={(theme) => ({
              color:
                gameScoring.score.home <= gameScoring.score.away
                  ? theme.palette.grey[50]
                  : theme.palette.grey[500],
            })}
          >
            {gameScoring.score.away}
          </Typography>
        </Stack>

        <Typography variant="subtitle1" sx={(theme) => ({ color: theme.palette.grey[600] })}>
          VS
        </Typography>

        {/* Home Team */}
        <Stack
          direction="row"
          spacing={0}
          alignItems="center"
          sx={{
            backgroundImage: `radial-gradient(circle, ${homeTeamPrimaryColor.concat('AA')} 0%, ${homeTeamPrimaryColor.concat('00')} 100%)`,
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            minWidth={50}
            textAlign={'center'}
            sx={(theme) => ({
              color:
                gameScoring.score.home >= gameScoring.score.away
                  ? theme.palette.grey[50]
                  : theme.palette.grey[500],
            })}
          >
            {gameScoring.score.home}
          </Typography>
          <ListItemText
            primary={data.homeTeam.abbreviation}
            secondary={data.homeTeam.name}
            sx={{ '&.MuiListItemText-root': { textAlign: 'right' } }}
          />
          <img loading="lazy" width="40" src={homeLogoPath} alt={data.homeTeam.abbreviation} />
        </Stack>
      </Stack>

      {/* Game Clock and Period Name */}
      <Stack direction="row" spacing={2} alignItems="center" sx={{ marginLeft: 4 }}>
        <Typography variant="h4" fontWeight="bold" sx={{ margin: '0 10px', minWidth: 90 }}>
          {gameScoring.clock}
        </Typography>
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          textTransform="uppercase"
          sx={(theme) => ({ color: theme.palette.grey[400] })}
        >
          {periodName}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ScoreboardData;
