import React, { useState, KeyboardEvent, ChangeEvent } from 'react';
import { Chip, Box, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';

interface MultiEmailInputProps {
  value: string[];
  onChange: (emails: string[]) => void;
  error?: boolean;
  helperText?: string;
  label?: string;
}

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const MultiEmailInput: React.FC<MultiEmailInputProps> = ({
  value,
  onChange,
  error = false,
  helperText = '',
  label = 'Email Addresses',
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const validateEmail = (email: string): boolean => {
    return EMAIL_REGEX.test(email.trim());
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      const email = inputValue.trim();

      if (email && validateEmail(email) && !value.includes(email)) {
        onChange([...value, email]);
        setInputValue('');
      }
    } else if (event.key === 'Backspace' && !inputValue && value.length > 0) {
      onChange(value.slice(0, -1));
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleDelete = (emailToDelete: string) => {
    onChange(value.filter((email) => email !== emailToDelete));
  };

  const handleBlur = () => {
    setIsFocused(false);
    const email = inputValue.trim();
    if (email && validateEmail(email) && !value.includes(email)) {
      onChange([...value, email]);
      setInputValue('');
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <FormControl error={error} fullWidth variant="outlined">
      <InputLabel
        htmlFor="multi-email-input"
        shrink={isFocused || value.length > 0 || !!inputValue}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        id="multi-email-input"
        label={label}
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onFocus={handleFocus}
        multiline
        fullWidth
        sx={{
          '& .MuiOutlinedInput-input': {
            paddingLeft: value.length > 0 ? 1 : 3.5,
          },
          '& .MuiInputAdornment-root': {
            marginRight: 0,
            maxWidth: '100%',
          },
        }}
        startAdornment={
          value.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
                maxWidth: '100%',
                padding: '8px',
                paddingRight: '4px',
                margin: '-14px 0',
                '& .MuiChip-root': {
                  margin: '2px',
                },
              }}
            >
              {value.map((email) => (
                <Chip
                  key={email}
                  label={email}
                  onDelete={() => handleDelete(email)}
                  size="small"
                  sx={{
                    maxWidth: '100%',
                    '& .MuiChip-label': {
                      whiteSpace: 'normal',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                />
              ))}
            </Box>
          )
        }
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};