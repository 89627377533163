import { CognitoUser } from 'amazon-cognito-identity-js';
import { SignOutParams, UpdateCognitoContext } from './types';

const signOut =
  (user: CognitoUser, updateCognitoContext: UpdateCognitoContext) =>
    ({ onSuccess, onFailure }: SignOutParams) => {
      const resetState = () => {
        updateCognitoContext({
          userAttributes: undefined,
          user: undefined,
          userSession: undefined,
          authenticated: false,
          newPasswordRequired: false,
        });
      };

      if (!user) {
        console.warn('No user to sign out');
        resetState();
        onSuccess();
        return;
      }

      try {
        user.globalSignOut({
          onSuccess() {
            resetState();
            onSuccess();
          },

          onFailure(err) {
            console.warn('Failed to globalsoundout',err)
            resetState();
            onFailure(err.message);
          },
        });
      } catch (error) {
        resetState();
        onFailure(error instanceof Error ? error.message : 'Unknown error during sign out');
      }
    };

export default signOut;