import { GameModel } from "@/features/games/types/GameModel";
import { TeamModel } from "../types/TeamModel";

export function getTeamColor(
    team:TeamModel,
    game:GameModel,
     fallbackColor : string = '#000000'
    ): string{
   
    const {homeTeamColor,awayTeamColor} = game;

    const {homeTeam,awayTeam} = game;

    if(team.abbreviation === homeTeam.abbreviation){
        if(homeTeamColor){
            return homeTeamColor;
        }

    }

    if(team.abbreviation === awayTeam.abbreviation){
        if(awayTeamColor){
            return awayTeamColor;
        }
    }

    if(!team.teamColors){
        return fallbackColor;
    }

    return  team.teamColors.find((c) => c.type === 'primary')?.hex_color || fallbackColor;

}