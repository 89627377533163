import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Group } from '@/types/user';
import { User } from '@/types/user';

interface EditUserDialogProps {
  open: boolean;
  user: User | null;
  groupId: number;
  groups: Group[];
  loading: boolean;
  onClose: () => void;
  onSave: () => void;
  onGroupChange: (groupId: number) => void;
}

export const EditUserDialog: React.FC<EditUserDialogProps> = ({
  open,
  user,
  groupId,
  groups,
  loading,
  onClose,
  onSave,
  onGroupChange,
}) => {
  if (!user) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: { backdropFilter: 'blur(4px)' },
        },
      }}
    >
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent dividers sx={{ minWidth: 450 }}>
        <Stack spacing={2} direction="column" sx={{ p: 2 }}>
          <FormControl fullWidth>
            <TextField
              id="email"
              value={user.email}
              disabled
              fullWidth
              label="Email"
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="select-label-edit-user">Group</InputLabel>
            <Select
              id="group"
              value={groupId}
              label="Group"
              onChange={(e) => onGroupChange(e.target.value as number)}
              fullWidth
            >
              {groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          onClick={onSave}
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};