import { Group } from "@/types/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { del } from "@truefit/http-utils";
interface ApiError {
    message: string;
    status: number;
}

export const useDeleteGroupMutation = (orgId: number) => {
    const queryClient = useQueryClient();

    return useMutation<Group, ApiError, Group>({
        mutationFn: (data: Group) =>
            del<Group>(`/org/${orgId}/groups/${data.id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['groups', orgId] });
        },
        onError: (error) => {
            // Handle error appropriately
            console.error('Failed to create group:', error);
        }
    });
};

export default useDeleteGroupMutation;