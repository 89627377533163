type FontFace = {
  fontFamily: string;
  src: string;
  fontStyle?: string;
  fontDisplay?: string;
};

type CSSVariables = {
  fonts: Record<string, string>;
  colors: Record<string, string>;
};

// Utility function to extract @font-face from CSS
function extractFontFaces(css: string): FontFace[] {
  const fontFaceRegex = /@font-face\s*{([^}]+)}/g;
  const fontPropsRegex = /([\w-]+):\s*([^;]+);/g;
  const fontFaces: FontFace[] = [];

  let match;
  while ((match = fontFaceRegex.exec(css)) !== null) {
    const fontFace: FontFace = { fontFamily: '', src: '' };
    let propMatch;
    while ((propMatch = fontPropsRegex.exec(match[1])) !== null) {
      const [_, prop, value] = propMatch;
      switch (prop.trim()) {
        case 'font-family':
          fontFace.fontFamily = value.replace(/['"]/g, '');
          break;
        case 'src':
          fontFace.src = value;
          break;
        case 'font-style':
          fontFace.fontStyle = value;
          break;
        case 'font-display':
          fontFace.fontDisplay = value;
          break;
      }
    }
    fontFaces.push(fontFace);
  }
  return fontFaces;
}

// Utility function to extract CSS variables from :root
function extractCSSVariables(css: string): Record<string, string> {
  const rootVarsRegex = /:root\s*{([^}]+)}/g;
  const varPropsRegex = /--([\w-]+):\s*([^;]+);/g;
  const variables: Record<string, string> = {};

  const match = rootVarsRegex.exec(css);
  if (match) {
    let varMatch;
    while ((varMatch = varPropsRegex.exec(match[1])) !== null) {
      const [_, key, value] = varMatch;
      variables[key.trim()] = value.trim();
    }
  }
  return variables;
}

// Function to convert extracted data to JS object
export function generateCSSVarsObject(css: string): CSSVariables {
  const fontFaces = extractFontFaces(css);
  const cssVariables = extractCSSVariables(css);

  const fonts: Record<string, string> = {};
  const colors: Record<string, string> = {};

  // Convert font-face declarations to the fonts object
  fontFaces.forEach((font) => {
    const key = font.fontFamily.toLowerCase().replace(/\s+/g, '-').replace(/['"]/g, ''); // convert to kebab-case
    fonts[key] = `"${font.fontFamily}", sans-serif`;
  });

  // Convert CSS root variables to the colors object
  for (const [key, value] of Object.entries(cssVariables)) {
    colors[key.replace(/-/g, '')] = value;
  }

  return {
    fonts,
    colors,
  };
}

export default generateCSSVarsObject;
