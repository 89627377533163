import DownloadIcon from '@mui/icons-material/Download';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Tracking } from '../services/tracking';
import useClipDownloadMutation from '@/features/clips/hooks/useClipDownloadMutation';
import { ClipModel } from '@/features/clips/types';

export interface CamerasDialogProps {
  open: boolean;
  clip: ClipModel;
  onClose?: () => void;
}

function DownloadClipDialog(props: CamerasDialogProps) {
  const { onClose, open, clip } = props;

  if (!clip) return null;

  const downloadClipMutation = useClipDownloadMutation();

  const handleClose = () => {
    onClose?.();
  };

  const handleDownload = () => {
    downloadClipMutation.mutate({
      clipId: clip.id.toString(),
      video: true,
    });
  };

  const handleMetadataDownload = () => {
    downloadClipMutation.mutate({
      clipId: clip.id.toString(),
      video: false,
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        backdropFilter: 'blur(5px)',
      }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(4px)', // Default blur effect
          },
        },
      }}
    >
      <DialogTitle>Download Clip</DialogTitle>

      <List sx={{ pt: 0 }}>
        <ListItem key={`clip-${clip.id}`}>
          <ListItemText primary="Video file" sx={{ mr: -1, minWidth: 300 }} />
          <ListItemButton
            onClick={() => {
              handleDownload();
              Tracking.getInstance().track('Download', {
                category: 'Bookmarks/Clips',
                type: 'clip',
              });
            }}
          >
            <DownloadIcon />
          </ListItemButton>
        </ListItem>

        <ListItem key="-1">
          <ListItemText
            primary="Metadata file"
            secondary="metadata.json"
            sx={{ mr: -1, minWidth: 300 }}
          />
          <ListItemButton
            onClick={() => {
              handleMetadataDownload();
              Tracking.getInstance().track('Download Metadata', {
                category: 'Bookmarks/Clips',
                type: 'clip',
              });
            }}
            key="-1"
          >
            <DownloadIcon />
          </ListItemButton>
        </ListItem>
      </List>

      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default DownloadClipDialog;
