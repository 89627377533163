import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { Group } from '@/types/user';
import { MultiEmailInput } from '@/features/settings/components/MultiEmailInput';


interface InviteUserDialogProps {
  open: boolean;
  groups: Group[];
  groupId: number;
  loading: boolean;
  emails: string[];
  onClose: () => void;
  onInvite: () => void;
  onEmailsChange: (emails: string[]) => void;
  onGroupChange: (groupId: number) => void;
}

export const InviteUserDialog: React.FC<InviteUserDialogProps> = ({
  open,
  groups,
  groupId,
  loading,
  emails,
  onClose,
  onInvite,
  onEmailsChange,
  onGroupChange,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      slotProps={{
        backdrop: {
          sx: { backdropFilter: 'blur(4px)' },
        },
      }}
    >
      <DialogTitle>Invite User</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2} direction="column" sx={{ p: 2, width: 650 }}>
          <MultiEmailInput
            onChange={onEmailsChange}
            value={emails}
          />
          <FormControl fullWidth>
            <InputLabel id="select-label">Group</InputLabel>
            <Select
              id="group"
              label="Group"
              value={groupId}
              onChange={(e) => onGroupChange(e.target.value as number)}
              fullWidth
            >
              {groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={<SendIcon />}
          variant="contained"
          onClick={onInvite}
        >
          Invite
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};