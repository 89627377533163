import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { configureGlobalSSE, configureITMSSE, configureWebSocket, configureHttp } from '@/util';
declare global {
  interface Window {
    savedHandler: string;
  }
}

const setHttpAuth = (userSession: CognitoUserSession, handler?: string) => {
  // Save handler to window object if provided
  if (handler) {
    window.savedHandler = handler;
  }

  // Use saved handler if none provided
  const currentHandler = handler || window.savedHandler;
  const path = `/${currentHandler}`;

  const jwt = userSession.getAccessToken().getJwtToken();
  const identityToken = userSession.getIdToken().getJwtToken();

  configureHttp({
    jwt,
    path,
    identityToken,
  });
  configureWebSocket(jwt, currentHandler);
  configureITMSSE({
    jwt,
    path,
    identityToken,
  });
  configureGlobalSSE({
    jwt,
    path,
    identityToken,
  });
};

export default setHttpAuth;