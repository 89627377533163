
export type WebsocketConfig = {
  jwt?: string;
  handler?: string;
};

const WebsocketbaseConfig: WebsocketConfig = {};

export function GetWebsocketUrl(endpoint: string): string {
  if (!WebsocketbaseConfig.jwt) {
    throw new Error('Websocket jwt not configured');
  }
  const { jwt, handler } = WebsocketbaseConfig;

  if (!handler) {
    throw new Error('Websocket handler not configured');
  }

  const gatewayUrl = new URL(`https://${handler}.aws.c360live.com`);
  gatewayUrl.pathname = endpoint
  gatewayUrl.protocol = gatewayUrl.protocol.replace('http', 'ws');
  gatewayUrl.searchParams.append('token', jwt);
  return gatewayUrl.toString();
}

export function configureWebSocket(jwt: string, handler: string) {
  WebsocketbaseConfig.jwt = jwt;
  WebsocketbaseConfig.handler = handler;
}
