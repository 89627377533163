import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl, InputLabel, MenuItem, Select, Grid } from '@mui/material';
import {
  FormSubmitBar,
  FormValidationSummary,
  HookFormPasswordField,
  HookFormTextField,
} from '../../forms/components';
import useCognito from '../hooks/useCognito';
import useCurrentCloudUser from '@/shared/hooks/user/getCurrentUser';
import useUpdateSession from '@/shared/hooks/user/useUpdateSession';
import { Tracking } from '@/shared/services/tracking';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { Org } from '@/types/user';

import { useSnackbar } from 'notistack';

type SignInFormValues = {
  username: string;
  password: string;
};

const validationSchema = Yup.object().shape({
  username: Yup.string().required().max(256).label('Username'),
  password: Yup.string().required().max(50).label('Password'),
});

const SignInForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const returnUrl = new URLSearchParams(location.search).get('returnUrl') ?? '/';

  const [generalErrors, setGeneralErrors] = useState<string[]>([]);
  const [organizations, setOrganizations] = useState<Org[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
  
  const [loggingIn, setLoggingIn] = useState(false);

  const cognito = useCognito();

  const snackBar = useSnackbar();

  const { data: { data } = {}, status, refetch: refetchUser, isLoading } = useCurrentCloudUser();
  const updateSession = useUpdateSession();

  const form = useForm<SignInFormValues>({
    defaultValues: { username: '', password: '' },
    resolver: yupResolver(validationSchema),
  });

  useDocumentTitle('Log In');

  const currentUser = useMemo(() => data?.user, [data?.user]);

  useEffect(() => {
    if (status === 'error') {
      snackBar.enqueueSnackbar('Error fetching user data', { variant: 'error' });
    }
  }, [status]);

  // First effect: Fetch and set organizations when user data is available
  useEffect(() => {
    if (!cognito.authenticated || !currentUser) return;

    const availableOrgs = currentUser.orgs ?? [];
    setOrganizations(availableOrgs);
  }, [cognito.authenticated, currentUser]);

  // Second effect: Handle organization selection logic when organizations change
  useEffect(() => {
    if (!organizations.length) return;

    if (organizations.length === 0) {
      setGeneralErrors(['No organizations available for this user']);
      return;
    }

    if (organizations.length === 1) {
      // Auto-select the only available organization
      handleOrganizationSelect(organizations[0].id);
    } else {
      // For multiple orgs, wait for user selection
      setSelectedOrgId(null);
    }
  }, [organizations]);

  const handleOrganizationSelect = async (orgId: number) => {
    setLoggingIn(true);

    try {
      const org = organizations.find((o) => o.id === orgId);
      
      if (!org) {
        setGeneralErrors(['An error occurred during login. Please try again.']);
        setLoggingIn(false);
        return;
      }

      if (!org.leagues?.[0]?.id) {
        setGeneralErrors(['Selected organization has no available leagues']);
        setLoggingIn(false);
        return;
      }

      await updateSession.mutateAsync({
        org: org.id,
        league: org.leagues[0].id,
      });

      Tracking.getInstance().signIn();
      navigate(returnUrl);
    } catch (error) {
      setGeneralErrors(['Failed to select organization']);
      setLoggingIn(false);
    }
  };

  const handleSignIn = form.handleSubmit(async (values) => {
    setGeneralErrors([]); // Clear previous errors

    try {
      await new Promise<void>((resolve, reject) => {
        cognito.authenticateUser({
          username: values.username,
          password: values.password,
          onSuccess: () => {
            refetchUser();
            resolve();
          },
          onFailure: (message) => {
            setGeneralErrors([message]);
            reject(message);
          },
          newPasswordRequired: () => {
            Tracking.getInstance().track('Reset Password', { category: 'Auth' });
            navigate('/reset-password');
            resolve();
          },
        });
      });
    } catch (error) {
      // Error already handled in onFailure
    }
  });

  const handleContinueClick = () => {
    if (selectedOrgId === null) {
      setGeneralErrors(['Please select an organization']);
      return;
    }
    
    handleOrganizationSelect(selectedOrgId);
  };

  // Show org selection if authenticated and has multiple orgs
  if (cognito.authenticated && organizations.length > 1) {
    return (
      <>
        <FormValidationSummary validationErrors={generalErrors} />

        <FormControl fullWidth>
          <InputLabel id="org-select-label">Organization</InputLabel>
          <Select
            disabled={loggingIn}
            labelId="org-select-label"
            value={selectedOrgId ?? ''}
            label="Organization"
            onChange={(e) => setSelectedOrgId(e.target.value as number)}
          >
            {organizations.map((org) => (
              <MenuItem key={org.id} value={org.id}>
                {org.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{pt:4}}>
          <Button
            variant="contained"
            disabled={loggingIn || selectedOrgId === null}
            onClick={handleContinueClick}
          >
            {loggingIn ? 'Processing...' : 'Continue'}
          </Button>
        </FormControl>
      </>
    );
  }

  // Show login form
  return (
    <FormProvider {...form}>
      <form noValidate style={{ width: '100%' }}>
        <FormValidationSummary validationErrors={generalErrors} />

        <HookFormTextField
          name="username"
          autoFocus
          required
          label="Email"
          disabled={form.formState.isSubmitting || loggingIn || isLoading}
          variant="outlined"
          margin="dense"
        />

        <HookFormPasswordField
          name="password"
          required
          label="Password"
          disabled={form.formState.isSubmitting || loggingIn || isLoading}
          variant="outlined"
          margin="dense"
          onEnter={handleSignIn}
        />

        <FormSubmitBar
          submitting={form.formState.isSubmitting || loggingIn || isLoading}
          buttonText="Sign In"
          onSubmit={handleSignIn}
          mainButtonStyle={{ width: '50%' }}
        />

        <Grid container justifyContent="center" alignItems="center">
          <Button component={RouterLink} to="/forgot-password" sx={{ mt: 1, ml: -1 }}>
            Forgot password?
          </Button>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default SignInForm;