
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';

export default () => (
  <Grid container justifyContent="center" alignItems="center">
    <Grid item>
      <Box marginTop={2}>
        <Fade in style={{ transitionDelay: '800ms' }} unmountOnExit>
          <CircularProgress />
        </Fade>
      </Box>
    </Grid>
  </Grid>
);
