
// hooks/useGroupManagement.ts
import { useState, useCallback, useContext, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Group } from '@/types/user';
import { useOrgGroupsQuery, getQueryKey as orgGroupsQueryKey } from '@/features/settings/hooks/useOrgGroupsQuery';
import { useRolesQuery } from './useRolesQuery';
import useUpdateGroupMutation from './useUpdateGroupMutation';
import { UserStateContext } from '@/shared/components/UserStateProvider';
import useCreateGroupMutation from './useCreateGroupMutation';
import useDeleteGroupMutation from './useDeleteGroupMutation';

interface GroupManagementState {
    selectedGroup: Group | null;
    addDialogOpen: boolean;
    editDialogOpen: boolean;
    deleteDialogOpen: boolean;
    assignRolesDialogOpen: boolean;
}

export const useGroupManagement = () => {

    const { currentOrg } = useContext(UserStateContext);

    const [state, setState] = useState<GroupManagementState>({
        selectedGroup: null,
        addDialogOpen: false,
        editDialogOpen: false,
        deleteDialogOpen: false,
        assignRolesDialogOpen: false
    });

    const groupsKey = useMemo(() => orgGroupsQueryKey(currentOrg?.id), [currentOrg?.id]);

    const queryClient = useQueryClient();
    const groupsQuery = useOrgGroupsQuery(currentOrg?.id);
    const rolesQuery = useRolesQuery();


    const createGroupMutation = useCreateGroupMutation(currentOrg?.id);
    const updateGroupMutation = useUpdateGroupMutation(currentOrg?.id);
    const deleteGroupMutation = useDeleteGroupMutation(currentOrg?.id);

    const handleCreateGroup = useCallback(async (data: Omit<Group, 'id' | 'roles'>) => {
        try {
            await createGroupMutation.mutateAsync(data);
            queryClient.invalidateQueries({ queryKey: groupsKey });
            setState(prev => ({ ...prev, addDialogOpen: false }));
        } catch (error) {
            console.error('Error creating group:', error);
        }
    }, [createGroupMutation, queryClient]);

    const handleUpdateGroup = useCallback(async (data: Partial<Group>) => {
        if (!state.selectedGroup?.id) return;

        try {
            await updateGroupMutation.mutateAsync({ id: state.selectedGroup.id, ...data });
            queryClient.invalidateQueries({ queryKey: groupsKey });
            setState(prev => ({ ...prev, editDialogOpen: false, selectedGroup: null }));
        } catch (error) {
            console.error('Error updating group:', error);
        }
    }, [state.selectedGroup, updateGroupMutation, queryClient]);

    const handleDeleteGroup = useCallback(async () => {
        if (!state.selectedGroup?.id) return;

        try {
            await deleteGroupMutation.mutateAsync(state.selectedGroup);
            queryClient.invalidateQueries({ queryKey: groupsKey });
            setState(prev => ({ ...prev, deleteDialogOpen: false, selectedGroup: null }));
        } catch (error) {
            console.error('Error deleting group:', error);
        }
    }, [state.selectedGroup, deleteGroupMutation, queryClient]);

    const handleAssignRoles = useCallback(async (roleIds: number[]) => {
        if (!state.selectedGroup?.id) return;

        try {
            await updateGroupMutation.mutateAsync({
                id: state.selectedGroup.id,
                roles: roleIds
            });
            queryClient.invalidateQueries({ queryKey: groupsKey });
            setState(prev => ({ ...prev, assignRolesDialogOpen: false, selectedGroup: null }));
        } catch (error) {
            console.error('Error assigning roles:', error);
        }
    }, [state.selectedGroup, updateGroupMutation, queryClient]);

    return {
        state,
        groups: groupsQuery.data || [],
        roles: rolesQuery.data || [],
        isLoading: groupsQuery.isLoading || rolesQuery.isLoading,
        isError: groupsQuery.isError || rolesQuery.isError,
        handlers: {
            setSelectedGroup: (group: Group | null) =>
                setState(prev => ({ ...prev, selectedGroup: group })),
            setAddDialogOpen: (open: boolean) =>
                setState(prev => ({ ...prev, addDialogOpen: open })),
            setEditDialogOpen: (open: boolean) =>
                setState(prev => ({ ...prev, editDialogOpen: open })),
            setDeleteDialogOpen: (open: boolean) =>
                setState(prev => ({ ...prev, deleteDialogOpen: open })),
            setAssignRolesDialogOpen: (open: boolean) =>
                setState(prev => ({ ...prev, assignRolesDialogOpen: open })),
            handleCreateGroup,
            handleUpdateGroup,
            handleDeleteGroup,
            handleAssignRoles
        }
    };
};