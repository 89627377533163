import React, { ReactNode } from 'react';
import useServerState, { ServerStateAndFunctions } from '../hooks/useServerState';

type Props = {
  children: ReactNode;
};

// Create a default/initial state that matches ServerStateAndFunctions type
export const ServerStateContext = React.createContext<ServerStateAndFunctions | null>(null);

const ServerStateProvider = ({ children }: Props) => {
  const serverState = useServerState();

  return <ServerStateContext.Provider value={serverState}>{children}</ServerStateContext.Provider>;
};

export default ServerStateProvider;