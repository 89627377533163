import React, { ReactNode, useMemo } from 'react';

import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { Tooltip, useTheme } from '@mui/material';

import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { UserStateContext } from '@/shared/components/UserStateProvider';
import { ColorModeContext } from '@/features/scaffolding/components/App';

import AnimatedCosmLogo from '../AnimatedCosmLogo';

type Props = {
  hasPageMenu: boolean;
  navigationOpen: boolean;
  pageMenuOpen: boolean;
  pageMenuWidth: number;
  setNavigationOpen: (open: boolean) => unknown;
  setPageMenuOpen: (open: boolean) => unknown;
  title: string;
  renderSummaryData?: () => ReactNode;
};

// see https://mui.com/material-ui/react-app-bar/#fixed-placement
export const AppBarSpacer = () => (
  <Box
    sx={(theme) => ({
      ...theme.mixins.toolbar,
    })}
  />
);

const AppBar = ({
  hasPageMenu,
  navigationOpen,
  pageMenuOpen,
  pageMenuWidth,
  setNavigationOpen,
  setPageMenuOpen,
  title,
  renderSummaryData = undefined,
}: Props) => {
  // feature flags
  const { darkLightThemeSwitching } = useFlags();

  // context
  const colorMode = React.useContext(ColorModeContext);
  const { currentLeague, currentLeagueLogo } = React.useContext(UserStateContext);

  // custom hooks
  const theme = useTheme();

  const backgroundColor = useMemo(
    () =>
      {
        if(currentLeague?.firstColor) {
          return `linear-gradient(to left, rgba(${currentLeague?.firstColor},1), rgba(${currentLeague?.firstColor},0.1))`;
        }
        return '#333';
      },
    [currentLeague?.firstColor],
  );

  // event handlers
  const handleNavigationOpen = () => {
    setNavigationOpen(true);
  };

  const handleViewingToolsOpen = () => {
    setPageMenuOpen(true);
  };

  return (
    <MuiAppBar
      position="fixed"
      sx={(sxTheme) => ({
        overflow: 'hidden',
        transition: sxTheme.transitions.create(['margin', 'width'], {
          easing: sxTheme.transitions.easing.sharp,
          duration: sxTheme.transitions.duration.leavingScreen,
        }),
        ...(pageMenuOpen && {
          width: `calc(100% - ${pageMenuWidth}px)`,
          marginRight: `${pageMenuWidth}px`,
          transition: sxTheme.transitions.create(['margin', 'width'], {
            easing: sxTheme.transitions.easing.easeOut,
            duration: sxTheme.transitions.duration.enteringScreen,
          }),
        }),
      })}
    >
      <Toolbar
        sx={{
          transition: `background 0.5s ease`, // Transition over 0.5 seconds with ease timing function
          background: backgroundColor,
        }}
      >
        {!navigationOpen && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleNavigationOpen}
          >
            <MenuIcon />
          </IconButton>
        )}

        {!renderSummaryData && (
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {title}
          </Typography>
        )}

        {!!renderSummaryData && renderSummaryData()}

        {darkLightThemeSwitching && (
          <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        )}
        {currentLeagueLogo && <AnimatedCosmLogo leagueLogo={currentLeagueLogo} height={30} />}

        {hasPageMenu && !pageMenuOpen && (
          <Tooltip title="Viewing Tools" arrow>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              sx={{ ml: 3 }}
              onClick={handleViewingToolsOpen}
            >
              <MenuOpenIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
