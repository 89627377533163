import { CheckCircle, Cog } from 'lucide-react';
import { Stack, Typography, Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { ProgressMessage } from './AuthenticateRoute';

type ProgressMessagesProps = {
  messages: ProgressMessage[];
};

// Animation for rotating the Cog icon
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingCog = styled(Cog)(({ theme }) => ({
  animation: `${spin} 1s linear infinite`,
}));

const ProgressMessages = ({ messages }: ProgressMessagesProps) => {
  return (
    <Stack spacing={1} alignItems="center" minHeight={100}>
      {messages.map((msg, index) => (
        <Stack
          key={msg.id}
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
          sx={{
            color:
              msg.status === 'completed'
                ? 'success.main'
                : msg.status === 'error'
                  ? 'error.main'
                  : 'text.primary',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {msg.status === 'completed' ? (
              <CheckCircle />
            ) : msg.status === 'error' ? (
              <></>
            ) : (
              <RotatingCog />
            )}
          </Box>
          <Typography
            variant="body2"
            fontWeight={index === messages.length - 1 ? 'bold' : 'normal'}
          >
            {msg.message}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default ProgressMessages;
