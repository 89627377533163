import { useState, useCallback, useEffect, useContext } from 'react';
import { UserStateContext } from '@/shared/components/UserStateProvider';
import useCreateApiKeyMutation from './useCreateApiKeyMutation';
import useGetApiKeyQuery from './useGetApiKeyQuery';
import useRevokeApiKeyMutation from './useRevokeApiKeyMutation';

interface ApiKey {
  id: string;
  key: string;
  shortKey?: string;
} 

interface ApiKeyState {
  apiKey: ApiKey | null;
  displayKey: string | null;
  loading: boolean;
  error: string | null;
}

export const useApiKey = () => {
  const [state, setState] = useState<ApiKeyState>({
    apiKey: null,
    displayKey: null,
    loading: true,
    error: null,
  });

  const { currentOrg } = useContext(UserStateContext);
  const orgId = currentOrg?.id;

  const createApiKeyMutation = useCreateApiKeyMutation(orgId);
  const deleteApiKeyMutation = useRevokeApiKeyMutation(orgId);
  const fetchApiKeyQuery = useGetApiKeyQuery(orgId);

  // Handle fetch API key state
  useEffect(() => {
    if (fetchApiKeyQuery.status === 'success' && fetchApiKeyQuery.data) {
      setState(prev => ({ ...prev, apiKey: fetchApiKeyQuery.data.apiKey }));
    } else if (fetchApiKeyQuery.status === 'error') {
      setState(prev => ({
        ...prev,
        error: fetchApiKeyQuery.error?.message ?? 'An error occurred'
      }));
    }
  }, [fetchApiKeyQuery.status, fetchApiKeyQuery.data, fetchApiKeyQuery.error]);

  // Handle create API key state
  useEffect(() => {
    if (createApiKeyMutation.status === 'success' && createApiKeyMutation.data) {
      setState(prev => ({
        ...prev,
        apiKey: createApiKeyMutation.data,
        displayKey: createApiKeyMutation.data.key
      }));
    } else if (createApiKeyMutation.status === 'error') {
      setState(prev => ({
        ...prev,
        error: createApiKeyMutation.error?.message ?? 'Failed to create API key'
      }));
    }
  }, [createApiKeyMutation.status, createApiKeyMutation.data, createApiKeyMutation.error]);

  // Handle delete API key state
  useEffect(() => {
    if (deleteApiKeyMutation.status === 'success') {
      setState(prev => ({ ...prev, apiKey: null, displayKey: null }));
    } else if (deleteApiKeyMutation.status === 'error') {
      setState(prev => ({
        ...prev,
        error: deleteApiKeyMutation.error?.message ?? 'Failed to delete API key'
      }));
    }
  }, [deleteApiKeyMutation.status, deleteApiKeyMutation.error]);

  // Handle loading state
  useEffect(() => {

    setState(prev => ({
      ...prev,
      loading:
        fetchApiKeyQuery.isLoading ||
        createApiKeyMutation.isPending ||
        deleteApiKeyMutation.isPending
    }));
  }, [
    fetchApiKeyQuery.isLoading,
    createApiKeyMutation.isPending,
    deleteApiKeyMutation.isPending
  ]);



  const createApiKey = useCallback(async () => {
    try {
      await createApiKeyMutation.mutateAsync();
    } catch {
      // Error handling is managed by the mutation
    }
  }, [createApiKeyMutation]);

  const deleteApiKey = useCallback(async () => {
    try {
      await deleteApiKeyMutation.mutateAsync();
    } catch {
      // Error handling is managed by the mutation
    }
  }, [deleteApiKeyMutation]);

  return {
    ...state,
    createApiKey,
    deleteApiKey,
  };
};