import { formatInTimeZone, format } from 'date-fns-tz';
import { isTomorrow, isYesterday, isToday } from 'date-fns';

const TIME_FORMATS = {
  SHORT_DATE: 'MMM dd',
  BROWSER_TIME: 'MMM dd, yyyy • h:mmaaa',
  TIMEZONE_TIME: 'MMM dd, yyyy • h:mmaaa zzz',
  TIME_ONLY: 'h:mm a',
} as const;

const getBrowserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
const browserTimezone = getBrowserTimezone();

const isValidDate = (date: any): date is Date => {
  return date instanceof Date && !isNaN(date.getTime());
};

const getRelativeDayFormat = (targetDate: Date, tz: string, mode = 'long') => {

  if (tz === 'UNSET')
    tz = browserTimezone;

  try {
    if (isToday(targetDate)) {
      return mode === 'short' ? 'Today' : `Today • ${format(targetDate, TIME_FORMATS.TIME_ONLY, { timeZone: tz })}`;
    }
    if (isTomorrow(targetDate)) {
      return mode === 'short' ? 'Tomorrow' : `Tomorrow • ${format(targetDate, TIME_FORMATS.TIME_ONLY, { timeZone: tz })}`;
    }
    if (isYesterday(targetDate)) {
      return mode === 'short' ? 'Yesterday' : `Yesterday • ${format(targetDate, TIME_FORMATS.TIME_ONLY, { timeZone: tz })}`;
    }
    return null;
  } catch (error) {
    console.error('Error in getRelativeDayFormat:', error);
    return null;
  }
};

export const formatDate = (date: Date, tz: string, formatType?: 'short') => {

  if (tz === 'UNSET' || !tz) 
    tz = browserTimezone;

  try {
    if (!isValidDate(date)) {
      throw new Error('Invalid date provided');
    }

    const strFormat = formatType === 'short'
      ? TIME_FORMATS.SHORT_DATE
      : (browserTimezone === tz ? TIME_FORMATS.BROWSER_TIME : TIME_FORMATS.TIMEZONE_TIME);

    return formatInTimeZone(date, tz, strFormat);
  } catch (error) {
    console.error('Error in formatDate:', error);
    return '';
  }
};

export const formatStartTime = (targetDate: Date, tz: string, mode = 'long') => {
  if (tz === 'UNSET')
    tz = browserTimezone;

  try {
    if (!isValidDate(targetDate)) {
      throw new Error('Invalid date provided');
    }

    const relativeFormat = getRelativeDayFormat(targetDate, browserTimezone, mode);
    if (relativeFormat) return relativeFormat;

    const strFormat = browserTimezone === tz ? 'h:mmaaa' : ' h:mmaaa zzz';
    return formatInTimeZone(targetDate, tz, strFormat);
  } catch (error) {
    console.error('Error in formatStartTime:', error);
    return '';
  }
};

export const formatStart = (targetDate: Date, tz: string, mode = 'long') => {

  if (tz === 'UNSET')
    tz = browserTimezone;

  try {
    if (!isValidDate(targetDate)) {
      throw new Error('Invalid date provided');
    }

    const relativeFormat = getRelativeDayFormat(targetDate, browserTimezone, mode);
    if (relativeFormat) return relativeFormat;

    return formatDate(targetDate, tz, mode === 'short' ? 'short' : undefined);
  } catch (error) {
    console.error('Error in formatStart:', error);
    return '';
  }
};