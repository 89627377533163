import { getBusinessApiEndPoint, HttpConfiguration } from './configureHttp';


let SSEBaseConfig: HttpConfiguration = {};

export function GetGlobalSSEUrl(): string {
  const { identityToken } = SSEBaseConfig;

  const commonIoUrl = getBusinessApiEndPoint('/common-io');
  const gatewayUrl = new URL(commonIoUrl);
  gatewayUrl.pathname = gatewayUrl.pathname+'/common-io';
  gatewayUrl.searchParams.append('idToken', identityToken);
  return gatewayUrl.toString();
}

export function configureGlobalSSE(config: HttpConfiguration) {
  SSEBaseConfig = config;
}
