import { useQuery } from '@tanstack/react-query';

import { get } from '@truefit/http-utils';
import { startCase } from 'lodash';
import { Tag } from '@/features/clips/types';

export const queryKey = ['ClipTags'];
const useAllClipTagsQuery = () =>
  useQuery({
    queryKey,
    queryFn: async () => {
      let { data } = await get<Tag[]>('tags');
      data = data.map((tag) => ({
        ...tag,
        name: startCase(tag.name),
      }));
      return data;
    },
    staleTime: 60 * 60 * 1000, // Data considered fresh for 30 seconds
    gcTime: 60 * 60 * 1000, // Data will be garbage collected
  });

export default useAllClipTagsQuery;
