import { useMutation, useQueryClient } from "@tanstack/react-query";
import { post } from "@truefit/http-utils";

interface ApiError {
    message: string;
    status: number;
}

interface ApiKey {
    key: string;
    id: string;
    success: boolean;
}

export const useCreateApiKeyMutation = (orgId?: number) => {
    const queryClient = useQueryClient();

    return useMutation<ApiKey, ApiError, void>({
        mutationFn: async () => {
            const {data} =  await post<ApiKey>(`/user/apikey`, { orgId });
            return data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["apikey", orgId] });
        },
        onError: (error) => {
            console.error("Failed to create API key:", error);
        }
    });
};

export default useCreateApiKeyMutation;