import React, { useEffect, useRef, useState } from 'react';

import { Add } from '@mui/icons-material';

import { Box, Chip, CircularProgress, Popover, Stack, useTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { startCase } from 'lodash';
import TagsAddInline from './TagsAddInline';
import { colorTag } from '@/shared/services/colorTag';
import { ClipModel, ClipTag, Tag } from '@/features/clips/types';
import useUpdateClipTagsMutation from '../hooks/useUpdateClipTagsMutation';

type Props = {
  clip: ClipModel;
  readonly: boolean;
  sx?: any;
};

function DisplayTags({ clip, readonly, sx = {} }: Props) {
  const updateClipTagsMutation = useUpdateClipTagsMutation();
  const clipTags: ClipTag[] = clip?.cliptags ?? [];

  const tags: Tag[] = clipTags.map((cliptags) => cliptags?.tag);
  
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [checkMark, setCheckMark] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const gridRef = useRef(null);
  const theme = useTheme();
  const [updateTags, setUpdateTags] = useState<Tag[]>(tags);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(gridRef.current);
    return event;
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (JSON.stringify(tags) !== JSON.stringify(updateTags)) {
      updateClipTagsMutation.mutate({ ...clip, tags: updateTags });
    }
  };

  useEffect(() => {
    if (updateClipTagsMutation.status === 'success') {
      setCheckMark(true);
      setTimeout(() => {
        setCheckMark(false);
      }, 2000);
    }
  }, [updateClipTagsMutation.status]);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        alignItems={'center'}
        ref={gridRef}
        justifyContent={'left'}
        flexWrap={'wrap'}
      >
        {!!tags &&
          tags.map((item) => (
            <Chip
              key={item.id}
              style={{
                padding: 0,
                margin: 4,
                fontSize: 10,
                color: colorTag(item.name).c,
                background: colorTag(item.name).b,
              }}
              label={startCase(item.name)}
              size="small"
            />
          ))}{' '}
        {!readonly && (
          <Stack direction="row" alignItems={'center'}>
            <Chip
              icon={<Add />}
              style={{ margin: 4 }}
              label="Tag"
              variant="outlined"
              size="small"
              onClick={handleClick}
            />

            {updateClipTagsMutation.status === 'pending' && <CircularProgress size={15} />}
            <CheckCircleOutlineIcon
              sx={{
                color: theme.palette.success.main,
                opacity: checkMark ? '1' : '0',
                transition: 'opacity 0.5s ease-out', // Fade-out transition
              }}
            />
          </Stack>
        )}
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <TagsAddInline clip={clip} setUpdateTags={setUpdateTags} />
        </Box>
      </Popover>
    </>
  );
}

export default DisplayTags;
