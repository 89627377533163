import { Box, Container, Divider, Typography } from '@mui/material';

import { AppPage } from '@/shared/components';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import Arena from './Arena';
import { UserStateContext } from '@/shared/components/UserStateProvider';
import { useContext } from 'react';

const ApiKeyManagementPage = () => {
  const title = 'Camera Position Editor';

  useDocumentTitle(title);


  const { currentLeague } = useContext(UserStateContext);
  

  return (
    <AppPage title={title} requiredPermissions={['menu:arena']}>
      <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
           Place your camera in the arena and edit the position and rotation.
           You can also add new cameras and delete existing ones.
           Camera Indexes are generated automatically but you can change them if you want
           Rotation is in degrees and can be changed with the arrow keys.
        </Typography>
        <Divider sx={{ marginTop: 3 }} />
        <Box sx={{ marginTop: 4 }}>

        <Arena backgroundSvg={currentLeague?.surfaceSVG}  width={currentLeague?.surfaceWidth} height={currentLeague?.surfaceHeight}/>
        </Box>
      </Container>
    </AppPage>
  );
};

export default ApiKeyManagementPage;
