

export * from './panelPadding';

export const PLAYER_CONTROLS_HEIGHT = 100;
export const TOOLBAR_HEIGHT = 72;
export const CLIP_MODE_ALERT_HEIGHT = 52;
export const VIDEO_PLAYER_ID = 'video-player';
export const ACCEPTABLE_GRID_SIZES = [1, 2, 4, 8];
export const NUMBER_OF_VALUES = 60; // 30 seconds
export const DEFAULT_ZOOM = 35;
export const ZOOM_FACTOR = 0.5;
export const DRAWER_WIDTH = 400;
