import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';

type Props = {
  submitting: boolean;
  buttonText?: string;
  cancelTo?: string;
  cancelHref?: string;
  onCancel?: () => void;
  hasDivider?: boolean;
  onSubmit: (e?) => void;
  cancelButtonStyle?: React.CSSProperties;
  mainButtonStyle?: React.CSSProperties;
  disabled?: boolean; // Add disabled prop
};

type CancelButtonProps = {
  cancelTo?: string;
  cancelHref?: string;
  onCancel?: () => void;
  classes: {
    button: string;
    cancelButton: string;
  };
  style: React.CSSProperties;
  disabled?: boolean; // Add disabled prop for cancel button
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginLeft: theme.spacing(1),
    color: '#000',
    backgroundColor: '#fff',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#fff',
      opacity: 0.5,
    },
    '&.Mui-disabled': {
      backgroundColor: '#f5f5f5',
      color: 'rgba(0, 0, 0, 0.38)',
    },
  },
  cancelButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 0.5,
    },
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.38)',
    },
  },
}));

const CancelButton = ({ cancelTo, cancelHref, onCancel, classes, style, disabled }: CancelButtonProps) => {
  if (onCancel) {
    return (
      <Grid item>
        <Button
          className={classes.cancelButton}
          variant="outlined"
          onClick={onCancel}
          color="secondary"
          style={style}
          disabled={disabled}
        >
          Cancel
        </Button>
      </Grid>
    );
  }
  if (cancelTo) {
    return (
      <Grid item>
        <Button
          className={classes.cancelButton}
          component={RouterLink}
          to={cancelTo}
          color="secondary"
          style={style}
          disabled={disabled}
        >
          Cancel
        </Button>
      </Grid>
    );
  }

  if (cancelHref) {
    return (
      <Grid item>
        <Button
          className={classes.cancelButton}
          href={cancelHref}
          color="secondary"
          style={style}
          disabled={disabled}
        >
          Cancel
        </Button>
      </Grid>
    );
  }

  return null;
};

const FormSubmitBar = ({
  submitting,
  cancelTo,
  cancelHref,
  onCancel,
  buttonText,
  hasDivider,
  onSubmit,
  mainButtonStyle,
  cancelButtonStyle,
  disabled = false, // Add default value
}: Props) => {
  const classes = useStyles();

  // Combine submitting and disabled states
  const isDisabled = disabled || submitting;

  return (
    <>
      {hasDivider && <Divider sx={{ mt: 2 }} />}
      <Grid container justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
        <CancelButton
          cancelTo={cancelTo}
          cancelHref={cancelHref}
          onCancel={onCancel}
          classes={classes}
          style={cancelButtonStyle||{}}
          disabled={isDisabled}
        />

        <LoadingButton
          loading={submitting}
          color="primary"
          variant="contained"
          disabled={submitting}
          onClick={onSubmit}
          style={mainButtonStyle}
        >
          {buttonText || 'Submit'}
        </LoadingButton>
      </Grid>
    </>
  );
};

export default FormSubmitBar;