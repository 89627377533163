
import { Role } from "@/types/user";
import { useQuery } from "@tanstack/react-query";
import { get } from "@truefit/http-utils";


export const useListSessionsQuery = () => {
    return useQuery<Role[]>({
        queryKey: ['sessions'],
        queryFn: async (): Promise<Role[]> => {
            const { data } = await get('/internals/mgmt/sessions/list');
            return data;
        },
        enabled: true
    });
}