import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slider,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

// Tab Panel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`camera-tabpanel-${index}`}
      aria-labelledby={`camera-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const CameraSystemConfig = ({ initialConfig, setOutputJson }) => {
  // State for active tab
  const [activeTab, setActiveTab] = useState(0);

  // State for configuration
  const [config, setConfig] = useState(() => {
    // Create a deep copy of the initial config
    const initialConfigCopy = JSON.parse(JSON.stringify(initialConfig));
    if(!initialConfig) return {}
    // Ensure all cameras have the enabled property set to true if missing
    initialConfigCopy.cams = initialConfigCopy.cams.map((cam) => {
      if (cam.enabled === undefined) {
        cam.enabled = true;
      }
      return cam;
    });

    return initialConfigCopy;
  });

  useEffect(() => {
    setOutputJson(config);
  }, [config]);

  // Handle units change
  const handleUnitsChange = (event) => {
    setConfig({
      ...config,
      units: event.target.value,
    });
  };

  // Handle camera field changes
  const handleCameraChange = (index, field, value) => {
    const updatedCams = [...config.cams];
    updatedCams[index] = {
      ...updatedCams[index],
      [field]: value,
    };

    setConfig({
      ...config,
      cams: updatedCams,
    });
  };

  // Handle camera nested field changes (position, forward)
  const handleCameraNestedChange = (camIndex, parent, field, value) => {
    const updatedCams = [...config.cams];
    updatedCams[camIndex] = {
      ...updatedCams[camIndex],
      [parent]: {
        ...updatedCams[camIndex][parent],
        [field]: parseFloat(value),
      },
    };

    setConfig({
      ...config,
      cams: updatedCams,
    });
  };

  // Handle tracking spec changes
  const handleTrackingChange = (field, value) => {
    setConfig({
      ...config,
      trackingSpec: {
        ...config.trackingSpec,
        [field]: parseFloat(value),
      },
    });
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if(!initialConfig?.cams){
    return <>
    
    </>
  }
  
  return (
    <Box component="form" noValidate sx={{ maxWidth: 800, mx: 'auto', mt: 4 }}>
      <Card>
        <CardHeader
          title="Camera System Configuration"
          subheader="Edit camera and tracking settings"
        />
        <Divider />

        <CardContent>
          {/* Global Settings */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Global Settings
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Units</InputLabel>
              <Select value={config.units} label="Units" onChange={handleUnitsChange}>
                <MenuItem value="feet">Feet</MenuItem>
                <MenuItem value="meters">Meters</MenuItem>
                <MenuItem value="inches">Inches</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Camera Tabs */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Cameras
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ flexGrow: 1 }}
              >
                {config.cams.map((cam, index) => (
                  <Tab key={index} label={cam.displayName || `Camera ${index}`} />
                ))}
              </Tabs>
            </Box>

            {config.cams.map((cam, index) => (
              <TabPanel key={index} value={activeTab} index={index}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <Grid container spacing={3}>
                    {/* Basic Camera Settings */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Camera Index"
                        type="number"
                        value={cam.camIndex}
                        onChange={(e) =>
                          handleCameraChange(index, 'camIndex', parseInt(e.target.value))
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Display Name"
                        value={cam.displayName}
                        onChange={(e) => handleCameraChange(index, 'displayName', e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cam.enabled || false}
                            onChange={(e) => handleCameraChange(index, 'enabled', e.target.checked)}
                          />
                        }
                        label="Enabled"
                      />
                    </Grid>

                    {/* Position Settings */}
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Position (x, y, z)
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Position X"
                        type="number"
                        step="0.01"
                        value={cam.position.x}
                        onChange={(e) =>
                          handleCameraNestedChange(index, 'position', 'x', e.target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Position Y"
                        type="number"
                        step="0.01"
                        value={cam.position.y}
                        onChange={(e) =>
                          handleCameraNestedChange(index, 'position', 'y', e.target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Position Z"
                        type="number"
                        step="0.01"
                        value={cam.position.z}
                        onChange={(e) =>
                          handleCameraNestedChange(index, 'position', 'z', e.target.value)
                        }
                      />
                    </Grid>

                    {/* Forward Direction Settings */}
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Forward Direction (x, y, z)
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Forward X"
                        type="number"
                        step="0.1"
                        value={cam.forward.x}
                        onChange={(e) =>
                          handleCameraNestedChange(index, 'forward', 'x', e.target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Forward Y"
                        type="number"
                        step="0.1"
                        value={cam.forward.y}
                        onChange={(e) =>
                          handleCameraNestedChange(index, 'forward', 'y', e.target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Forward Z"
                        type="number"
                        step="0.1"
                        value={cam.forward.z}
                        onChange={(e) =>
                          handleCameraNestedChange(index, 'forward', 'z', e.target.value)
                        }
                      />
                    </Grid>

                    {/* Tilt Setting */}
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Tilt (degrees)
                      </Typography>
                      <Slider
                        value={cam.tilt}
                        onChange={(e, value) => handleCameraChange(index, 'tilt', value)}
                        valueLabelDisplay="auto"
                        min={-90}
                        max={90}
                        marks={[
                          { value: -90, label: '-90°' },
                          { value: -45, label: '-45°' },
                          { value: 0, label: '0°' },
                          { value: 45, label: '45°' },
                          { value: 90, label: '90°' },
                        ]}
                      />
                    </Grid>

                    {/* Offset */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Offset"
                        type="number"
                        step="0.1"
                        value={cam.offset}
                        onChange={(e) =>
                          handleCameraChange(index, 'offset', parseFloat(e.target.value))
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </TabPanel>
            ))}
          </Box>

          {/* Tracking Specifications */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Tracking Specifications
            </Typography>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="SLERP Rate"
                    type="number"
                    step="0.01"
                    value={config.trackingSpec.slerpRate}
                    onChange={(e) => handleTrackingChange('slerpRate', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="LERP Rate"
                    type="number"
                    step="0.01"
                    value={config.trackingSpec.lerpRate}
                    onChange={(e) => handleTrackingChange('lerpRate', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="vDamp"
                    type="number"
                    step="0.1"
                    value={config.trackingSpec.vDamp}
                    onChange={(e) => handleTrackingChange('vDamp', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Maximum Distance"
                    type="number"
                    step="1"
                    value={config.trackingSpec.distMax}
                    onChange={(e) => handleTrackingChange('distMax', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Minimum Distance"
                    type="number"
                    step="1"
                    value={config.trackingSpec.distMin}
                    onChange={(e) => handleTrackingChange('distMin', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Maximum FOV"
                    type="number"
                    step="1"
                    value={config.trackingSpec.fovMax}
                    onChange={(e) => handleTrackingChange('fovMax', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Minimum FOV"
                    type="number"
                    step="1"
                    value={config.trackingSpec.fovMin}
                    onChange={(e) => handleTrackingChange('fovMin', e.target.value)}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CameraSystemConfig;
