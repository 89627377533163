import { Alert, Box, Container, Divider, Typography } from '@mui/material';
import { useState } from 'react';

import { AppPage, Loading } from '@/shared/components';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';

import SessionGrid from './SessionGrid';
import useKillSessionMutation from '../hooks/useKillSessionMutation';
import { useListSessionsQuery } from '../hooks/useListSessionsQuery';
import KillSessionDialog from './KillSessionDialog';

const ManageSessionsPage = () => {
  const [sessionToKill, setSessionToKill] = useState<string | null>(null);
  const { mutate, error: killSessionError } = useKillSessionMutation();

  const title = 'Manage CXVH Sessions';
  useDocumentTitle(title);

  const {
    data: sessions,
    isLoading,
    error,
    refetch,
  } = useListSessionsQuery({
    refetchInterval: 5000,
  });

  const handleKillRequest = (sessionId: string) => {
    setSessionToKill(sessionId);
  };

  const handleKillConfirm = async (sessionId: string) => {
    await mutate(sessionId);
    setSessionToKill(null);
    refetch();
  };

  const handleKillCancel = () => {
    setSessionToKill(null);
  };

  return (
    <AppPage title={title} requiredPermissions={['menu:manage:sessions']}>
      <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
          Place where you can manage your sessions. You can see the status of each session and kill
          them if needed.
        </Typography>
        <Divider sx={{ marginTop: 3 }} />
        <Box sx={{ marginTop: 4 }}>
          {isLoading && <Loading />}
          {error && (
            <Alert variant="outlined" color="error">
              {error.message}
            </Alert>
          )}
          {killSessionError && (
            <Alert variant="outlined" color="error">
              {killSessionError.message}
            </Alert>
          )}
          {sessions && (
            <SessionGrid sessions={sessions} onKillSession={handleKillRequest}></SessionGrid>
          )}
        </Box>

        <KillSessionDialog
          open={sessionToKill !== null}
          sessionId={sessionToKill}
          onConfirm={handleKillConfirm}
          onCancel={handleKillCancel}
        />
      </Container>
    </AppPage>
  );
};

export default ManageSessionsPage;