import { useCallback, useRef } from 'react';
import { addSeconds } from 'date-fns';

const useDebouncedScrub = (
  endPositionSeconds: number,
  setTimestamp: (date: Date) => void,
  gamePlaybackPositions: { start: number },
  delay = 200
) => {
  const timeoutRef = useRef<NodeJS.Timeout>(null);

  const handleScrub = useCallback(
    (_: Event, newPosition: number | number[]) => {
      if (Array.isArray(newPosition)) return;
      
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        if (newPosition < endPositionSeconds && setTimestamp) {
          setTimestamp(addSeconds(gamePlaybackPositions.start, newPosition));
        }
      }, delay);
    },
    [endPositionSeconds, setTimestamp, gamePlaybackPositions.start, delay]
  );

  return handleScrub;
};

export default useDebouncedScrub;