import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import { useQuery } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';
import { isUUID } from 'validator';



export const queryKey = (gameId: string) => ['SwitchGame', gameId] as const;

const useGameSwitchQuery = () => {

  const gameId = useCurrentGameId();

  return useQuery({
    queryKey: queryKey(gameId),
    queryFn: async () => {
      if (!gameId || !isUUID(gameId)) {
        throw new Error('Invalid game ID');
      }
      
      const response = await get<any>(`/games/${gameId}/switch`);
      return response;
    },
    // Don't cache the result since it's a one-time switch operation
    gcTime: 0,
    // Query won't run automatically - needs to be triggered manually
    enabled: false,
    // No need to refetch since this is a one-time operation
    refetchOnWindowFocus: false,
    // Don't retry on failure since this is a deliberate action
    retry: false,
    // Prevent stale data
    staleTime: 0
  });
};

export default useGameSwitchQuery;