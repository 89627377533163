import { parseISO } from 'date-fns';
import { GameModel } from '../types';
import { Camera } from '../types/Camera';

const MIN_FOV = 0;
const INITIAL_FOV = 100;

const toGameModel = (game: GameModel): GameModel => ({
  ...game,
  cameras: game.camspecs
    ? game.camspecs.cams.map((cam): Camera => {
      const curveAngles: number[] | false = cam?.curveAngle
        ? JSON.parse(cam.curveAngle).map(parseFloat)
        : false;
      const computedFov: number = Array.isArray(curveAngles)
        ? (curveAngles[curveAngles.length - 1] * 2) / (16 / 9)
        : INITIAL_FOV;
      const initialFov: number = cam?.initialFov ? cam.initialFov : computedFov;
      const fovMin: number = cam.fovMin ? cam.fovMin : MIN_FOV;
      const fovMax: number = cam.fovMax ? cam.fovMax : initialFov;

      return {
        id: cam.camIndex + 1,
        name: cam.displayName,
        cameraId: `camera-${cam.camIndex + 1}`,
        curveAngles,
        initialFov,
        fovMin,
        fovMax,
        tilt: cam.tilt,
      };
    })
    : [],
  startTimestamp: parseISO(game.startTimestamp),
  endTimestamp: game.endTimestamp
    ? parseISO(game.endTimestamp)
    : null,
});

export default toGameModel;
