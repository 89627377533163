import { Group } from "@/types/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { post } from "@truefit/http-utils";
interface ApiError {
    message: string;
    status: number;
}

interface CreateGroupInput {
    name: string;
    description?: string;  // If description is part of your group creation
}

export const useCreateGroupMutation = (orgId: number) => {
    const queryClient = useQueryClient();

    return useMutation<Group, ApiError, CreateGroupInput>({
        mutationFn: (data: CreateGroupInput) =>
            post<Group>(`/org/${orgId}/groups`, data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['groups', orgId] });
        },
        onError: (error) => {
            // Handle error appropriately
            console.error('Failed to create group:', error);
        }
    });
};

export default useCreateGroupMutation;