import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Instance } from '@popperjs/core';
import { addSeconds, differenceInMilliseconds, differenceInSeconds } from 'date-fns';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import useClipStateContext from '@/shared/hooks/useClipStateContext';
import displayWallClock from '@/shared/services/displayWallClock';
import useCurrentGameClipsQuery from '../../clips/hooks/useCurrentGameClipsQuery';
import getClipsFilter from '../../clips/services/getClipsFilter';
import { ClipType } from '../../clips/types';
import { BootstrapTooltip } from './BootstrapTooltip';

import { useTimestampState } from '@/shared/hooks/websocket/useTimestamp';
import { getSliderStyle, MainSlider } from '../utils/PlaybackPositionStyledComponents';
import { GamePlayBackPositions } from './PlayerControls';
import ClipSlider from '../../clips/components/ClipSlider';
import { BookmarkMarker } from './BookmarkMarker';

type Props = { showingBookmarks: boolean; gamePlaybackPositions: GamePlayBackPositions, show: boolean, zoom: number };

const MARK_HEIGHT = 20;

const PlaybackPositionSlider = ({ showingBookmarks, gamePlaybackPositions, show, zoom }: Props) => {
  const [, setTimestamp] = useTimestampState();

  const sliderRef = useRef<HTMLInputElement>(null);
  const popperRef = useRef<Instance>(null);
  const theme = useTheme();

  const playbackPositionSeconds = Math.round(
    differenceInMilliseconds(gamePlaybackPositions.head, gamePlaybackPositions.start) / 1000,
  );
  const endPositionSeconds = Math.round(
    differenceInMilliseconds(gamePlaybackPositions.live, gamePlaybackPositions.start) / 1000,
  );

  const {
    state: { isEditingClip, editingModel, tagsFilter, filter },
    handleView,
  } = useClipStateContext();

  const timeLabel = (seconds: number) => {
    if (Number.isNaN(seconds)) return '00:00:00';
    return displayWallClock(addSeconds(gamePlaybackPositions.start, seconds));
  };

  const currentGameClipsQuery = useCurrentGameClipsQuery();
  const marks = useMemo(
    () =>
      ((!isEditingClip && currentGameClipsQuery.data) || [])
        .filter(getClipsFilter(filter, tagsFilter))
        .map((c) => ({
          id: c.id,
          label: c.note,
          value:
            editingModel?.id === c.id
              ? playbackPositionSeconds
              : differenceInSeconds(c.startTimestamp, gamePlaybackPositions.start),
          editing: editingModel?.id === c.id,
          type: c.type,
          model: c,
        })),
    [
      currentGameClipsQuery.data,
      editingModel?.id,
      filter,
      isEditingClip,
      playbackPositionSeconds,
      gamePlaybackPositions.start,
      tagsFilter,
    ],
  );

  const handleScrub = useCallback(
    (_e: Event, newPlaybackPosSeconds: number | number[]) => {
      if (Array.isArray(newPlaybackPosSeconds)) throw new Error('Not Implemented');
      if (newPlaybackPosSeconds < endPositionSeconds) {
        if (setTimestamp)
          setTimestamp(addSeconds(gamePlaybackPositions.start, newPlaybackPosSeconds)); // debounce
      }
    },
    [endPositionSeconds, setTimestamp, gamePlaybackPositions.start],
  );

  const getToolTipTitle = useCallback((mark: any) => {
    if (mark.type === ClipType.Bookmark) {
      return `Bookmark: ${mark.label}`;
    }
    if (mark.type === ClipType.Clip) {
      return `Clip: ${mark.label}`;
    }
    return '';
  }, []);


  const [progressBarSize, setProgressBarSize] = React.useState<'small' | 'medium'>('small');

  const [tooltipTimePosition, setTooltipTimePosition] = useState<string>('');

  const positionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const handleMouseMoveOnProgressBar = (e: { clientX: any }) => {
    const { clientX } = e;
    const brect = sliderRef.current?.getBoundingClientRect();

    if (brect) {
      const mousePosition = clientX - brect?.left;
      const percentage = (mousePosition < 0 ? 0 : mousePosition) / brect.width;
      const timePosition = endPositionSeconds * percentage;

      setTooltipTimePosition(timeLabel(timePosition));

      positionRef.current = { x: e.clientX, y: brect.y };

      if (popperRef.current != null) {
        popperRef.current.update();
      }
    }
  };

  return (
    <Box
      mt={`${MARK_HEIGHT}px`}
      mx={1}
      alignSelf="stretch"
      position="relative"
      display="flex"
      onMouseEnter={() => setProgressBarSize('medium')}
      onMouseLeave={() => setProgressBarSize('small')}
    >
      {!isEditingClip && (
        <BootstrapTooltip
          title={tooltipTimePosition}
          key="timestamp"
          placement="top"
          PopperProps={{
            popperRef,
            anchorEl: {
              getBoundingClientRect: () =>
                new DOMRect(
                  positionRef.current.x,
                  sliderRef.current?.getBoundingClientRect().y,
                  0,
                  0,
                ),
            },
          }}
        >
          <MainSlider
            aria-label="mainSlider"
            getAriaValueText={(val) => `${val}s`}
            max={endPositionSeconds}
            onChange={handleScrub}
            ref={sliderRef}
            size={progressBarSize}
            step={1}
            sx={() => getSliderStyle(theme)}
            value={playbackPositionSeconds}
            valueLabelDisplay="off"
            valueLabelFormat={timeLabel}
            onChangeCommitted={() => {
              // @ts-ignore
              sliderRef.current.querySelector('input').blur();
            }}
            onMouseMove={handleMouseMoveOnProgressBar}
          />
        </BootstrapTooltip>
      )}

      {isEditingClip && (
        <ClipSlider
          gamePlaybackPositions={gamePlaybackPositions}
          show={show}
          onMouseMove={handleMouseMoveOnProgressBar}
          zoom={zoom}
        ></ClipSlider>
      )}

      {showingBookmarks && (
        <BookmarkMarker marks={marks} endPositionSeconds={endPositionSeconds} handleView={handleView} getToolTipTitle={getToolTipTitle}  ></BookmarkMarker>
      )}
    </Box>
  );
};

export default PlaybackPositionSlider;
