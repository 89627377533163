import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from '@mui/material';
  
  interface KillSessionDialogProps {
    open: boolean;
    sessionId: string | null;
    onConfirm: (sessionId: string) => void;
    onCancel: () => void;
  }
  
  const KillSessionDialog = ({ open, sessionId, onConfirm, onCancel }: KillSessionDialogProps) => {
    const handleConfirm = () => {
      if (sessionId) {
        onConfirm(sessionId);
      }
    };
  
    return (
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="kill-session-dialog-title"
        aria-describedby="kill-session-dialog-description"
      >
        <DialogTitle id="kill-session-dialog-title">Confirm Session Termination</DialogTitle>
        <DialogContent>
          <DialogContentText id="kill-session-dialog-description">
            Are you sure you want to terminate this session? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary" variant='outlined'>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="error" variant='contained' autoFocus>
            Terminate Session
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default KillSessionDialog;