import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { Group } from '@/types/user';

interface DeleteGroupDialogProps {
  open: boolean;
  group: Group | null;
  onClose: () => void;
  onConfirm: () => void;
}

export const DeleteGroupDialog: React.FC<DeleteGroupDialogProps> = ({
  open,
  group,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(4px)', // Default blur effect
          },
        },
      }}
    >
      <DialogTitle>Delete Group</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the group "{group?.name}"? This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">Cancel</Button>
        <Button onClick={onConfirm} variant="contained" color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
