import {UAParser} from 'ua-parser-js';
import { Tracker } from './Tracker';
import AnalyticsSDK from '../libs/AnalyticsSDK';

function getDomainFromReferrer(referrer: string) {
  // @ts-ignore
  try {
    const url = new URL(referrer);
    const domain: string = url.hostname;
    return domain;
  } catch (error) {
    return referrer;
  }
}

export class CosmTracker implements Tracker {
  private static instance: CosmTracker;

  private ready = false;

  private name = 'COSM';

  private sessionId;

  private tracker;

  private browserData: any = {};

  private userTraits = {};

  getMixpanelBrowserProperties() {
    const currentUrl: string = window.location.href;

    if (Object.keys(this.browserData).length > 0) {
      this.browserData.$current_url = currentUrl;
      return this.browserData;
    }

    const parser = new UAParser(); // you need to pass the user-agent for nodejs
    const parserResults = parser.getResult();

    const screenHeight: number = window.innerHeight;
    const screenWidth: number = window.innerWidth;

    const { referrer } = document;
    const computedReferrer = referrer?.length > 0 ? referrer : '$direct';
    const referrerDomain = referrer?.length > 0 ? getDomainFromReferrer(referrer) : '$direct';

    this.browserData = {
      $browser: parserResults.browser.name,
      $browser_version: parserResults.browser.major,
      $os: parserResults.os.name,
      $os_version: parserResults.os.version,
      $screen_height: screenHeight,
      $screen_width: screenWidth,
      $current_url: currentUrl,
      $initial_referrer: computedReferrer,
      $initial_referring_domain: referrerDomain,
    };
    return this.browserData;
  }

  private constructor() {
    this.tracker = new AnalyticsSDK();
    this.tracker.initialize();
    this.ready = true;
    if (this.ready) console.debug('Loaded Cosm Activity tracker');
  }

  navigation() {
    throw new Error(`Navigation not implemented in ${this.name}`);
  }

  signIn(): void {
    throw new Error(`Sign in not implemented in ${this.name}`);
  }

  signOut(): void {
    throw new Error(`Sign out not implemented in ${this.name}`);
  }

  public toString() {
    return this.name;
  }

  public static getInstance(): CosmTracker {
    if (!CosmTracker.instance) {
      CosmTracker.instance = new CosmTracker();
    }

    return CosmTracker.instance;
  }

  public identify(userId: string, userProps: any) {
    const userprops = userProps;
    this.sessionId = userId;
    const userTraits = JSON.parse(JSON.stringify(userProps));
    userTraits['cognito-group'] = userTraits?.group;
    this.userTraits = userTraits;

    try {
      if (!this.ready) return;
      this.tracker.identifyUser(userId, userprops);
    } catch (error) {
      console.error(error);
    }
  }

  public track(event: string, payload?: any) {
    if (!this.ready) return;
    const browserDetails = this.getMixpanelBrowserProperties();
    this.trackActivity(event, { ...payload, ...browserDetails, ...this.userTraits });
  }

  private async trackActivity(event, props = {}) {
    if (this.sessionId) {
      this.tracker.trackEvent(event, props);
    }
  }
}
