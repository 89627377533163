import { MutableRefObject, useEffect, useRef, useState } from 'react';

export type WebSocketStateHookReturn<TValue> = [TValue, (value: TValue) => unknown] | [TValue];

export type WebSocketRefHookReturn<TValue> =
  | [MutableRefObject<TValue>, (value: TValue) => unknown]
  | [MutableRefObject<TValue>];

export type WebSocketRefHookOptions<TValue> = {
  onChange?: (value: TValue) => unknown;
};

export type WebSocketRefHook<TValue> = (
  options?: WebSocketRefHookOptions<TValue>,
) => WebSocketRefHookReturn<TValue>;

export const withStateFromRef =
  <TValue>(useRefHook: WebSocketRefHook<TValue>) =>
  (): WebSocketStateHookReturn<TValue> => {
    const isRunningRef = useRef(true);
    const lastUpdateRef = useRef<TValue | null>(null);
    const [refValue, setRefValue] = useRefHook();
    const [state, setState] = useState<TValue>(refValue.current);

    const setValue = (value: TValue) => {
      setRefValue(value);
    };

    useEffect(() => {
      const tick = () => {
        if (!isRunningRef.current) return;
        
        // Only update if the value has changed and we haven't processed this exact value yet
        if (refValue.current !== lastUpdateRef.current) {
          lastUpdateRef.current = refValue.current;
          setState(refValue.current);
        }
        
        window.requestAnimationFrame(tick);
      };

      isRunningRef.current = true;
      window.requestAnimationFrame(tick);

      return () => {
        isRunningRef.current = false;
      };
    }, []); // Remove state dependency to prevent feedback loops

    return [state, setValue];
  };