import useCurrentGameQuery from '@/features/games/hooks/useCurrentGameQuery';
import { TimePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import useServerStateContext from '../hooks/useServerStateContext';

type Props = {
  label: string;
  value: Date | null;
  onChange: (value: Date | null) => void;
  sx?: object;
};

export const GameTimePicker = ({ label, value, onChange, initialValue, sx = {} }: Props) => {
  const { gameStateManager } = useServerStateContext();

  const gameQuery = useCurrentGameQuery();
  const [playbackLive, setPlaybackEnd] = useState<Date | null>(null);
  const [playbackStart, setPlaybackStart] = useState<Date | null>(null);

  useEffect(() => {
    if (!gameStateManager) return;

    if (gameStateManager.lastGameState) {
      setPlaybackEnd(new Date(gameStateManager.lastGameState.live / 1_000_000));
    }

    const handleLiveChange = (head: number) => {
      setPlaybackEnd(new Date(head / 1_000_000));
    };

    gameStateManager.on('liveChange', handleLiveChange);

    return () => {
      if (gameStateManager.off) {
        gameStateManager.off('liveChange', handleLiveChange);
      }
    };
  }, [gameStateManager]);

  useEffect(() => {
    if (!gameQuery?.data || !gameQuery.data?.startTimestamp) return;
    setPlaybackStart(gameQuery.data.startTimestamp);
  }, [gameQuery?.data]);

  return (
    <TimePicker
      disabled={playbackLive === null || playbackStart === null}
      label={label}
      value={value}
      timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
      views={['hours', 'minutes', 'seconds']}
      onChange={onChange}
      sx={{ ...sx }}
      minTime={playbackStart as Date}
      maxTime={playbackLive as Date}
      slotProps={{ 
        textField: { 
          variant: "standard" 
        } 
      }}
    />
  );
};

export default GameTimePicker;
