import { forwardRef, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import Color from 'color';

import { Alert, AlertTitle, Button, IconButton, Typography } from '@mui/material';
import { Download } from 'lucide-react';

const useStyles = makeStyles(() => ({
  root: {
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
  },
  card: {
    width: '100%',
    backgroundColor: '#12b76a',
  },
  typography: {
    color: '#000',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    marginLeft: 'auto',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    color: '#000',
    transition: 'all .2s',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  paper: {
    backgroundColor: '#fff',
    padding: 16,
  },
  checkIcon: {
    fontSize: 20,
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
}));

interface ReportCompleteProps extends CustomContentProps {
  allowDownload?: boolean;
}

export const ClipReadySnackBar = forwardRef<HTMLDivElement, ReportCompleteProps>(
  ({ id, ...props }, ref) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    const green = Color('#12b76a');

    return (
      <SnackbarContent ref={ref} className={classes.root}>
        <Alert
          variant="filled"
          onClose={handleDismiss}
          sx={{
            color: green.lighten(0.95).toString(),
            backgroundColor: green.toString(),
          }}
        >
          <AlertTitle sx={{ color: 'white' }}>
            <Typography variant="body1" className={classes.message}>
              {props.message || 'Your clip is ready!'}
            </Typography>
          </AlertTitle>

          <Button variant="filled" startIcon={<Download />} onClick={props.downloadHandler}>
            Download
          </Button>
        </Alert>
      </SnackbarContent>
    );
  },
);

ClipReadySnackBar.displayName = 'ClipReadySnackBar';

export default ClipReadySnackBar;
