import { useMutation } from '@tanstack/react-query';
import { AxiosResponse, get } from '@truefit/http-utils';
import { AxiosError } from 'axios';

interface DownloadParams {
  clipId: string;
  video: boolean;
}

interface DownloadResponse {
  downloadKey: string;
}

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const fetchDownloadKey = async ({ clipId, video }: DownloadParams): Promise<string> => {
  const endpoint = `/clips/${clipId}/download?${video ? 'video=true' : 'metadata=true'}`;
  
  let attempt = 0;
  while (attempt < MAX_RETRIES) {
    try {
      const { data }: AxiosResponse<DownloadResponse> = await get(endpoint, () => ({
        maxRedirects: 0,
      }));
      
      if (!data.downloadKey) {
        throw new Error('Download key not found in response');
      }
      
      return data.downloadKey;
    } catch (error) {
      attempt++;
      
      // If it's the last attempt, throw the error
      if (attempt === MAX_RETRIES) {
        throw error;
      }
      
      // Only retry on specific errors
      const axiosError = error as AxiosError;
      if (
        axiosError.response?.status &&
        ![408, 429, 500, 502, 503, 504].includes(axiosError.response.status)
      ) {
        throw error;
      }
      
      // Exponential backoff
      await sleep(RETRY_DELAY * Math.pow(2, attempt - 1));
    }
  }
  
  throw new Error('Maximum retry attempts reached');
};

const useClipDownloadMutation = () => {
  return useMutation({
    mutationFn: async ({ clipId, video }: DownloadParams) => {
      try {
        const downloadKey = await fetchDownloadKey({ clipId, video });
        
        // Safely open in new window
        const newWindow = window.open(downloadKey, '_blank');
        if (!newWindow) {
          throw new Error('Popup blocked. Please allow popups and try again.');
        }
        
        return downloadKey;
      } catch (error) {
        // Transform error messages for better UX
        if (error instanceof AxiosError) {
          switch (error.response?.status) {
            case 404:
              throw new Error('Clip not found');
            case 403:
              throw new Error('You do not have permission to download this clip');
            case 429:
              throw new Error('Too many requests. Please try again later');
            default:
              throw new Error('Failed to download clip. Please try again');
          }
        }
        throw error;
      }
    },
    gcTime: 0,
    retry: false, // We handle retries manually for more control
  });
};

export default useClipDownloadMutation;