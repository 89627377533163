import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormSubmitBar, FormValidationSummary, HookFormPasswordField } from '@/features/forms/components';
import { Alert, Box, Typography } from '@mui/material';
import useCognito from '../hooks/useCognito';

const PASSWORD_REQUIREMENTS = {
  minLength: 8,
  maxLength: 50,
  requireSpecialChar: true,
  requireNumber: true,
  requireUppercase: true,
};

type ChangePasswordFormValues = {
  oldPassword: string;
  newPassword: string;
  passwordConfirmation: string;
};

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Current password is required')
    .label('Current Password'),
  newPassword: Yup.string()
    .required('New password is required')
    .max(PASSWORD_REQUIREMENTS.maxLength, 'Password is too long')
    .min(PASSWORD_REQUIREMENTS.minLength, 'Password is too short')
    .matches(/[!@#$%^&*]/, 'Password must contain a special character')
    .matches(/[0-9]/, 'Password must contain a number')
    .matches(/[A-Z]/, 'Password must contain an uppercase letter')
    .label('New Password'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Password confirmation is required')
    .label('Confirm Password'),
});

const MAX_ATTEMPTS = 3;

const useChangePassword = () => {
  const cognito = useCognito();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [attempts, setAttempts] = React.useState(0);
  const [generalValidationErrors, setGeneralValidationErrors] = React.useState<string[]>([]);

  const form = useForm<ChangePasswordFormValues>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      passwordConfirmation: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const isLocked = attempts >= MAX_ATTEMPTS;

  const handleSubmit = useCallback(
    form.handleSubmit(async (formValues: ChangePasswordFormValues) => {
      if (isLocked) return;

      try {
        await cognito.changePassword({
          oldPassword: formValues.oldPassword,
          newPassword: formValues.newPassword,
          onSuccess: () => {
            enqueueSnackbar('Password successfully changed', { variant: 'success' });
            navigate('/');
          },
          onFailure: (message: string) => {
            setAttempts((prev) => prev + 1);
            
            if (message === 'Invalid verification code provided, please try again.') {
              form.setError(
                'oldPassword',
                { type: 'custom', message },
                { shouldFocus: true },
              );
            } else {
              setGeneralValidationErrors([message]);
            }
          },
        });
      } catch (error) {
        setGeneralValidationErrors(['An unexpected error occurred. Please try again.']);
      }
    }),
    [cognito, enqueueSnackbar, navigate, form, isLocked]
  );

  const passwordRequirements = useMemo(() => [
    `At least ${PASSWORD_REQUIREMENTS.minLength} characters long`,
    'Contains at least one special character (!@#$%^&*)',
    'Contains at least one number',
    'Contains at least one uppercase letter',
  ], []);

  return { 
    form, 
    generalValidationErrors, 
    handleSubmit,
    isLocked,
    passwordRequirements,
  };
};

const ChangePasswordForm = () => {
  const { 
    form, 
    handleSubmit, 
    generalValidationErrors,
    isLocked,
    passwordRequirements 
  } = useChangePassword();

  return (
    <Box 
      sx={{ padding: 2 }}
      role="form"
      aria-label="Change Password Form"
    >
      <FormProvider {...form}>
        <form onSubmit={handleSubmit} noValidate>
          <FormValidationSummary 
            validationErrors={generalValidationErrors}
            role="alert"
            aria-live="polite"
          />

          <div role="list" aria-label="Password Requirements">
            {passwordRequirements.map((req, index) => (
              <Typography key={index} variant="body2" role="listitem">{req}</Typography>
            ))}
          </div>

          <HookFormPasswordField
            name="oldPassword"
            required
            label="Current Password"
            variant="outlined"
            margin="dense"
            autoComplete="current-password"
            aria-label="Current Password"
            disabled={isLocked}
          />

          <HookFormPasswordField
            name="newPassword"
            required
            label="New Password"
            variant="outlined"
            margin="dense"
            autoComplete="new-password"
            aria-label="New Password"
            disabled={isLocked}
          />

          <HookFormPasswordField
            name="passwordConfirmation"
            required
            label="Confirm Password"
            variant="outlined"
            margin="dense"
            autoComplete="new-password"
            aria-label="Confirm New Password"
            disabled={isLocked}
          />

          <FormSubmitBar 
            onSubmit={handleSubmit} 
            submitting={form.formState.isSubmitting}
            disabled={isLocked}
          />

          {isLocked && (
            <Alert 
              severity="error"
              role="alert"
            >
              Too many failed attempts. Please try again later.
            </Alert>
          )}
        </form>
      </FormProvider>
    </Box>
  );
};

export default ChangePasswordForm;