import React, { ReactNode, useEffect, useRef } from 'react';

import useServerStateContext from '@/shared/hooks/useServerStateContext';
import { usePTZStore } from '@/features/camera/store/usePTZStore';

import Box from '@mui/material/Box';
import { useDrag, usePinch, useWheel } from '@use-gesture/react';

import { usePTZSocket } from '@/shared/hooks/websocket/usePTZSocket';
import { ViewType } from '@/features/camera/types/VideoSource';

type Props = {
  children: ReactNode;
};

function mapValueToRange(a: number, x1: number, y1: number, x2: number, y2: number): number {
  // Ensure that 'a' is within the range x1 to y1
  a = Math.max(x1, Math.min(y1, a));

  // Perform linear interpolation
  const result = ((a - x1) * (y2 - x2)) / (y1 - x1) + x2;

  return result;
}

type MaxPTBoundaries = {
  pan: number;
  tilt: number;
};

const MAX_VELOCITY = 1; // degrees/frame
const SIZE = 135;
const MAX_DRAG_DISTANCE = SIZE * 2;

const toVelocity = (dragDistance: number, velocity: number = MAX_VELOCITY) =>
  (dragDistance / MAX_DRAG_DISTANCE) * velocity;

function throttle(func: Function, limit: number) {
  let lastCall = 0;
  return function (...args: any[]) {
    const now = Date.now();
    if (now - lastCall >= limit) {
      lastCall = now;
      func.apply(this, args);
    }
  };
}

// zoom is 180 (fully zoomed out) to 1 (max zoom in)
const WheelZoom = ({ children }: Props) => {
  const ref = useRef<HTMLElement>(null);
  const serverState = useServerStateContext();

  const viewType = serverState?.viewType;
  
  const { setValue: setPtzVelocity } = usePTZSocket();

  const [dragBoundaries, setDragBoundaries] = React.useState<MaxPTBoundaries>(); // [x,y,width,height]

  useEffect(() => {
    if(!ref.current) return;
    const rect = ref.current?.getBoundingClientRect();
    setDragBoundaries({ pan: rect.width / 2, tilt: (rect.width * 9) / 16 / 2 });
  }, [ref.current]);

  // Define the debounced function (300ms delay)
  const throttledHandleDrag = throttle((pan: number, tilt: number) => {
    const mappedPan = mapValueToRange(pan, -dragBoundaries.pan, dragBoundaries.pan, -1, 1);
    const mappedTilt = mapValueToRange(tilt, -dragBoundaries.tilt, dragBoundaries.tilt, -1, 1);

    const vPan = toVelocity(mappedPan * -1, 3);
    const vTilt = toVelocity(mappedTilt * -1, 3);

    setPtzVelocity({
      pan: vPan,
      tilt: vTilt,
      zoom: 0,
    });
  }, 100);

  usePinch((state) => {

    state.event.stopPropagation();
    state.event.preventDefault();

    if (viewType === ViewType.MOSAIC) {
      return;
    }

    if (!state.active) {
      // ignores the extra event that use-gesture includes to indicate when the gesture ends
      // see
      return;
    }


    //  const delta = state.delta[1] * getVelocity(state.velocity[1], 20); // 1 for up, -1 for down
    //  const newZoom = zoom + delta;

    //  setPtzVelocity({ pan, tilt, zoom: newZoom });
  });

  useWheel(
    (state) => {
      const zoomFactor = 1.5;
      const trackpadScaleFactor = 2.5;

      state.event.stopPropagation();
      state.event.preventDefault();

      if (viewType === ViewType.MOSAIC) {
        return;
      }

      if (!state.active) {
        setPtzVelocity({ pan: 0, tilt: 0, zoom: 0 });
        return;
      }

      // Heuristic to detect trackpad vs. mouse wheel
      const isTrackpad = Math.abs(state.event.deltaY) < 100 && state.velocity[1] < 1.5;

      // Calculate the zoom delta
      let zoomDelta = state.direction[1] * state.velocity[1] * zoomFactor;

      if (isTrackpad) {
        zoomDelta *= trackpadScaleFactor;
      }

      // Pass the zoom delta (positive or negative) to the velocity
      setPtzVelocity({ pan: 0, tilt: 0, zoom: zoomDelta });
    },
    { target: ref, eventOptions: { passive: false } },
  );

  // Drag handler
  useDrag(
    (state) => {

      if (!state.active) {
        return;
      }

      if (viewType === ViewType.MOSAIC) {
        return;
      }

      const x = state.movement[0];
      const y = state.movement[1];

      const tilt = -1 * y;
      const pan = x;

      // Call the debounced function instead of the direct handler
      throttledHandleDrag(pan, tilt);
    },
    { target: ref },
  );

  const stopDrag = (e) => {
    if (viewType === ViewType.MOSAIC) {
      return;
    }

    setPtzVelocity({
      pan: 0,
      tilt: 0,
      zoom: 0,
    });
  };

  return (
    <Box ref={ref} onMouseUp={stopDrag}>
      {children}
    </Box>
  );
};

export default WheelZoom;
