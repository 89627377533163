import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface LoadingSpinnerProps {
  text?: string;
  size?: 'small' | 'medium' | 'large';
  fullScreen?: boolean;
  overlay?: boolean;
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  text,
  size = 'medium',
  fullScreen = false,
  overlay = false,
  color = 'primary'
}) => {
  const getSpinnerSize = () => {
    switch (size) {
      case 'small':
        return 24;
      case 'large':
        return 48;
      default:
        return 40;
    }
  };

  const baseStyles = {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2
  };

  const containerStyles = {
    ...baseStyles,
    ...(fullScreen && {
      height: '100vh',
      width: '100vw',
      position: 'fixed' as const,
      top: 0,
      left: 0,
      backgroundColor: 'background.paper'
    }),
    ...(overlay && {
      position: 'absolute' as const,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      zIndex: 1000
    }),
    ...(!fullScreen && !overlay && {
      minHeight: 200
    })
  };

  return (
    <Box sx={containerStyles}>
      <CircularProgress
        size={getSpinnerSize()}
        color={color}
      />
      {text && (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mt: 2 }}
        >
          {text}
        </Typography>
      )}
    </Box>
  );
};

// Example usage:
// <LoadingSpinner /> - Basic usage
// <LoadingSpinner text="Loading data..." /> - With text
// <LoadingSpinner size="large" fullScreen /> - Full screen loader
// <LoadingSpinner overlay text="Processing..." /> - Overlay with text
// <LoadingSpinner color="secondary" size="small" /> - Custom color and size