import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { nameofFactory } from '@/shared/services';

import {
  FormSubmitBar,
  FormValidationSummary,
  HookFormPasswordField,
} from '../../forms/components';
import useCognito from '../hooks/useCognito';

export type ResetPasswordFormValues = {
  newPassword: string;
  passwordConfirmation: string;
};

const nameOf = nameofFactory<ResetPasswordFormValues>();

const validationSchema = Yup.object().shape({
  [nameOf('newPassword')]: Yup.string().required().max(50).min(8).label('New Password'),
  [nameOf('passwordConfirmation')]: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Passwords must match',
  ),
});

const usePasswordReset = () => {
  const cognito = useCognito();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<ResetPasswordFormValues>({
    defaultValues: {
      [nameOf('newPassword')]: '',
      [nameOf('passwordConfirmation')]: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const [generalValidationErrors, setGeneralValidationErrors] = React.useState<string[]>([]);

  const handleSubmit = form.handleSubmit(
    async (formValues) =>
      new Promise((resolve) => {
        if (formValues.newPassword !== formValues.passwordConfirmation) {
          // don't submit if passwords don't match
          resolve();
          return;
        }

        cognito.completeNewPasswordChallenge({
          password: formValues.newPassword,

          onSuccess() {
            enqueueSnackbar('Password successfully reset', { variant: 'success' });
            navigate('/sign-in');
            resolve();
          },

          onFailure(message) {
            setGeneralValidationErrors([message]);
            resolve();
          },
        });
      }),
  );

  return { form, generalValidationErrors, handleSubmit };
};

const ResetPasswordForm = () => {
  const { form, generalValidationErrors, handleSubmit } = usePasswordReset();

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} noValidate style={{ width: '100%' }}>
        <FormValidationSummary validationErrors={generalValidationErrors} />

        <HookFormPasswordField
          name={nameOf('newPassword')}
          required
          label="Password"
          variant="outlined"
          margin="dense"
        />

        <HookFormPasswordField
          name={nameOf('passwordConfirmation')}
          required
          label="Confirm Password"
          variant="outlined"
          margin="dense"
        />

        <FormSubmitBar
          onSubmit={handleSubmit}
          submitting={form.formState.isSubmitting}
          cancelTo="/sign-in"
        />
      </form>
    </FormProvider>
  );
};

export default ResetPasswordForm;
