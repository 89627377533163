import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Stack, FormGroup, TextField, FormControlLabel, Checkbox, Tooltip, Box } from '@mui/material';
import { debounce, isEqual } from 'lodash';

import TeamAccordion from './TeamAccordion';
import useRegistrationEntitiesQuery from '../../autoIso/hooks/useAutoIsoEntitiesQuery';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import { setIsActive, sortPlayers } from '@/features/teams/functions';
import { AutoIsoModel, AutoIsoObjectType } from '@/features/autoIso/types/AutoIsoModel';

interface CategoryPlayers {
  [category: string]: AutoIsoModel[];
}

interface ProcessedTeam extends Team {
  players: CategoryPlayers;
}

export const AutoISOTeamSelector: React.FC = () => {
  // Server State
  const { gameStateManager } = useServerStateContext();
  const autoIsoQuery = useRegistrationEntitiesQuery();
  const { homeTeam, awayTeam, list = [] } = autoIsoQuery?.data || {};

  // Local State
  const [gameTrackingEntities, setGameTrackingEntities] = useState<AutoIsoModel[]>([]);
  const [includeInactivePlayers, setIncludeInactivePlayers] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [activeEntities, setActiveEntities] = useState<number[]>([]);

  // Event Handlers
  useEffect(() => {
    if (!gameStateManager) return;

    const handleTrackingEntitiesChange = (trackingEntities: AutoIsoModel[]) => {
      setGameTrackingEntities(trackingEntities);
    };

    gameStateManager.on('trackingEntitiesChange', handleTrackingEntitiesChange);
    return () => {
      gameStateManager.off?.('trackingEntitiesChange', handleTrackingEntitiesChange);
    };
  }, [gameStateManager]);

  useEffect(() => {
    if (gameTrackingEntities?.length) {
      const newIds = gameTrackingEntities.map((e) => e.id);
      if (!isEqual(newIds, activeEntities)) {
        setActiveEntities(newIds);
      }
    }
  }, [gameTrackingEntities, activeEntities]);

  // Memoized Filtering Logic
  const filteredRegistrationEntities = useMemo(() => {
    return list
      .filter((entity) => {
        if (!includeInactivePlayers && !activeEntities.includes(entity.id)) {
          return false;
        }

        if (!searchValue) return true;

        const searchLower = searchValue.toLowerCase();
        return (
          entity.displayName.toLowerCase().includes(searchLower) ||
          (entity.type === AutoIsoObjectType.Player && entity.playerNumber?.includes(searchValue))
        );
      })
      .map(setIsActive(activeEntities));
  }, [list, activeEntities, includeInactivePlayers, searchValue]);

  // Memoized Team Processing
  const processedTeams = useMemo((): ProcessedTeam[] => {
    return [homeTeam, awayTeam]
      .map((team) => {
        if (!team) return null;

        const teamPlayers = filteredRegistrationEntities.filter(
          (entity) => entity.type === AutoIsoObjectType.Player && entity?.playerTeamId === team.id,
        );

        const categorizedPlayers = teamPlayers.reduce((acc, player) => {
          const category = player.playerCategory || 'Uncategorized';
          return {
            ...acc,
            [category]: [...(acc[category] || []), player],
          };
        }, {} as CategoryPlayers);

        // Sort players within each category
        Object.keys(categorizedPlayers).forEach((category) => {
          categorizedPlayers[category].sort(sortPlayers);
        });

        return {
          ...team,
          players: categorizedPlayers,
        };
      })
      .filter(Boolean) as ProcessedTeam[];
  }, [filteredRegistrationEntities, homeTeam, awayTeam]);

  // Debounced Search Handler
  const debouncedSearch = useCallback(
    debounce((value: string) => setSearchValue(value), 300),
    [],
  );

  return (
    <Stack spacing={3}>
      <Box sx={{ m: 3,p:3 }}>
        <FormGroup>
          <TextField
            label="Search Player"
            variant="outlined"
            fullWidth
            onChange={(e) => {
              debouncedSearch(e.target.value);
              e.stopPropagation();
            }}
            placeholder="Search by name or jersey number"
            sx={{ fontSize: '0.8rem' }}
          />
          <Tooltip title="Show inactive players outside the rink">
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeInactivePlayers}
                  onChange={() => setIncludeInactivePlayers((prev) => !prev)}
                />
              }
              label="Include inactive players"
            />
          </Tooltip>
        </FormGroup>
      </Box>
      <TeamAccordion teams={processedTeams} />
    </Stack>
  );
};

export default AutoISOTeamSelector;
