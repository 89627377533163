import Sintony from '@/assets/fonts/Sintony/Sintony-Regular.ttf';
import StolzBook from '@/assets/fonts/Stolzl/Stolzl-Book.otf';
import Sohne from '@/assets/fonts/Sohne/soehne-kraftig.woff2';
import Monument from '@/assets/fonts/Monument/MonumentExtended-Regular.otf';
import cosmStyles from './style.css?inline';
import generateCSSVarsObject from './stylesParser';

const { colors } = generateCSSVarsObject(cosmStyles);


function lightenDarkenColor(color: string, amount: number, opacity: number = 1): string {
  const usePound = color[0] === '#';
  const hex = usePound ? color.slice(1) : color;

  let num = parseInt(hex, 16);
  let r = Math.min(255, Math.max(0, (num >> 16) + amount));
  let g = Math.min(255, Math.max(0, ((num >> 8) & 0x00ff) + amount));
  let b = Math.min(255, Math.max(0, (num & 0x0000ff) + amount));

  // Clamp opacity between 0 and 1
  const a = Math.min(1, Math.max(0, opacity));

  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

const primaryColor = colors.primary500;

export const themeOptions = {
  typography: {
    fontFamily: '"Stolzl Book", Arial',
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: 'white', // Label color when focused
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: lightenDarkenColor(primaryColor, 20, 0.3),
            color: 'white', // Selected tab text
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          fontFamily: 'Sohne, arial, sans-serif',
          fontWeight: 800,
          fontStyle: 'normal',
          textTransform: 'Capitalize',
          color: 'white',
          backgroundColor: lightenDarkenColor(primaryColor, 20, 0.3),
          borderColor: primaryColor,
          transition: 'all 0.1s ease', // This line adds the transition
          opacity: 0.8,
          '&:hover': {
            backgroundColor: lightenDarkenColor(primaryColor, 20, 0.5),
            opacity: 0.9,
          },
          '&.Mui-selected': {
            backgroundColor: primaryColor,
            color: 'white',
            opacity: 1,
            '&:hover': {
              backgroundColor: primaryColor,
              opacity: 0.8,
            },
          },
          '&.Mui-disabled': {
            borderColor: primaryColor,
            color: primaryColor,
            opacity: 0.4,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          fontFamily: 'Sohne, arial, sans-serif',
          fontStyle: 'normal',
          textTransform: 'capitalize',
          // Default styles for all buttons
          '&.MuiButton-primary': {
            backgroundColor: colors.primary500,
            color: 'white',
            '&:hover': {
              backgroundColor: '#0e2ebb',
            },
          },
          '&.MuiButton-error': {
            backgroundColor: colors.red500,
            color: 'white',
            '&:hover': {
              backgroundColor: '#d9342a',
            },
          },
          '&.MuiButton-warning': {
            backgroundColor: colors.yellow500,
            color: 'black',
            '&:hover': {
              backgroundColor: '#e6b800',
            },
          },
          '&.MuiButton-success': {
            backgroundColor: colors.green500,
            color: 'white',
            '&:hover': {
              backgroundColor: '#218838',
            },
          },
        },
        // Styles for Contained Buttons
        contained: {
          color: 'white'
        },
        // Styles for Outlined Buttons
        outlined: {
          color: 'white',
          '&.MuiButton-primary': {
            borderColor: primaryColor,
            backgroundColor: lightenDarkenColor(primaryColor, 20, 0.3),
            '&:hover': {
              backgroundColor: lightenDarkenColor(primaryColor, 20, 0.5),
            },
          },
          '&.MuiButton-error': {
            color: colors.red500,
            '&:hover': {
              color: lightenDarkenColor(colors.red500, 20),
            },
          },
          '&.MuiButton-warning': {
            color: colors.yellow500,
            '&:hover': {
              color: lightenDarkenColor(colors.yellow500, 20),
            },
          },
          '&.MuiButton-success': {
            color: colors.green500,
            '&:hover': {
              color: colors.green400,
            },
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'Sintony';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Sintony'), local('Sintony-Regular'), url(${Sintony}) format('woff2');
          }

          @font-face {
            font-family: 'Stolzl Book';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src:  local('Stolzl-Book'), url(${StolzBook}) format('otf');
          }

          @font-face {
            font-family: 'Sohne';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Sohne'), url(${Sohne}) format('woff2');
          }
        
          @font-face {
            font-family: 'Monument';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Monument'), url(${Monument}) format('otf');
          }

        `,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: primaryColor,
    },
    action: {
      active: 'rgba(255, 255, 255, 0.56)',
      hover: 'rgba(255, 255, 255, 0.08)',
      disabled: 'rgba(255, 255, 255, 0.3)',
      selected: 'rgba(255, 255, 255, 0.16)',
      focus: 'rgba(255, 255, 255, 0.12)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
    },
    error: {
      main: colors.red500,
    },
    warning: {
      main: colors.yellow500,
    },
    info: {
      main: colors.tertiary600,
    },
    success: {
      main: colors.green500,
    },
    background: {
      paper: colors.wash950,
    },
  },
};

export default themeOptions;
