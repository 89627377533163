import asyncAwaitMiddleware from 'redux-async-await';
import { thunk } from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit';
import createRootReducer from '@/rootReducer';
import { isProduction } from '@/shared/constants';

// scaffolding
const middleware = (getDefaultMiddleware: any) => getDefaultMiddleware().concat(thunk, asyncAwaitMiddleware);
const reducer = createRootReducer();

// Create the store configuration
const createStore = () => {
  return configureStore({
    reducer,
    middleware,
    devTools: isProduction || {
      name: 'Cosm Web Viewer',
    },
  });
};

// Create and export the store instance
export const store = createStore();

// Export types from the actual store instance
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Still export the create function if needed elsewhere
export default createStore;