import { getTeamLogoPath } from '@/shared/hooks/getTeamLogoPath';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMemo } from 'react';
import { GameModel } from '../types';

const GameSlate = ({ game, message }: { game: GameModel; message?: string }) => {
  const theme = useTheme();
  const { homeTeam, awayTeam } = game;

  if (!homeTeam || !awayTeam) return null;

  const awayLogoPath = getTeamLogoPath(awayTeam);
  const homeLogoPath = getTeamLogoPath(homeTeam);

  const homePrimaryTeamColor = useMemo(
    () =>
      game.homeTeamColor ||
      homeTeam.teamColors?.find((c) => c.type === 'primary')?.hex_color ||
      '#000000',
    [game.homeTeamColor, homeTeam.teamColors],
  );

  const awayPrimaryTeamColor = useMemo(
    () =>
      game.awayTeamColor ||
      awayTeam.teamColors?.find((c) => c.type === 'primary')?.hex_color ||
      '#000000',
    [game.awayTeamColor, awayTeam.teamColors],
  );

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 10,
        background: `linear-gradient(110deg, 
          ${awayPrimaryTeamColor}99 0%, 
          black 50%, 
          ${homePrimaryTeamColor}99 100%
        )`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(4),
      }}
    >
      {/* Main content container */}
      <Stack
        direction="column"
        spacing={4}
        sx={{
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Teams matchup section */}
        <Stack
          direction="row"
          sx={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}
        >
          {/* Away Team */}
          <Stack
            direction="row"
            spacing={3}
            sx={{
              flex: 1,
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '192px',
                height: '192px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src={awayLogoPath}
                alt={awayTeam.abbreviation}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                }}
                loading="lazy"
              />
            </Box>
            <Typography
              variant="h2"
              sx={{
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              {awayTeam.abbreviation}
            </Typography>
          </Stack>

          {/* VS Divider */}
          <Box sx={{ px: 4 }}>
            <Typography
              variant="h4"
              sx={{
                color: grey[500],
                fontWeight: 600,
              }}
            >
              VS
            </Typography>
          </Box>

          {/* Home Team */}
          <Stack
            direction="row"
            spacing={3}
            sx={{
              flex: 1,
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h2"
              sx={{
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              {homeTeam.abbreviation}
            </Typography>
            <Box
              sx={{
                width: '192px',
                height: '192px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src={homeLogoPath}
                alt={homeTeam.abbreviation}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                }}
                loading="lazy"
              />
            </Box>
          </Stack>
        </Stack>

        {/* Message section */}
        <Stack spacing={2} sx={{ textAlign: 'center' }}>
          <Typography
            variant="h4"
            sx={{
              color: grey[100],
              fontWeight: 600,
            }}
          >
            {message || ''}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: grey[400],
            }}
          >
            {game.venue?.name} - {game.venue?.city}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default GameSlate;
