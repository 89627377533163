import { Box, Typography } from '@mui/material';

import { CognitoContext } from '@/features/authentication/components/CognitoProvider';
import { KeyboardShortcutsDialog } from '@/shared/components';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import useServerStateContext from '@/shared/hooks/useServerStateContext';

import useCameraPtzKeyboardBindings from '../../camera/hooks/useCameraPtzKeyboardBindings';

import { ViewType } from '@/features/camera/types/VideoSource';
import RotatingIcon from '@/shared/components/RotatingIcon';
import { usePTZSocket } from '@/shared/hooks/websocket/usePTZSocket';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PlayerControls } from '../../video/components';
import useFullscreen from '../../video/hooks/useFullscreen';
import usePlayer from '../../video/hooks/usePlayer';
import {
  ACCEPTABLE_GRID_SIZES,
  CLIP_MODE_ALERT_HEIGHT,
  DEFAULT_ZOOM,
  TOOLBAR_HEIGHT,
  VIDEO_PLAYER_ID,
  ZOOM_FACTOR,
} from '../constants';
import WheelZoom from './WheelZoom';
import { usePTZStore } from '@/features/camera/store/usePTZStore';
import GameSlate from './GameSlate';
import { GameModel } from '../types';
import { usePersistedValue } from '../hooks/usePersistedValue';

type Tuple = [zoom: number, step: number];

// Example usage:
const tuples: Tuple[] = [
  [1, 7500],
  [10, 750],
  [80, 500],
];

function interpolateStep(zoom: number): number {
  // Ensure the tuples are sorted by zoom value
  tuples.sort((a, b) => a[0] - b[0]);

  const n = tuples.length;

  // Handle edge cases where the zoom is outside the range
  if (zoom <= tuples[0][0]) return tuples[0][1];
  if (zoom >= tuples[n - 1][0]) return tuples[n - 1][1];

  // Find the surrounding points for interpolation
  for (let i = 0; i < n - 1; i++) {
    const [z1, s1] = tuples[i];
    const [z2, s2] = tuples[i + 1];

    if (zoom >= z1 && zoom <= z2) {
      // Perform linear interpolation
      const t = (zoom - z1) / (z2 - z1); // interpolation factor
      return Math.round(s1 + t * (s2 - s1)); // round to the nearest integer
    }
  }

  throw new Error('Unexpected error in interpolation.');
}

const GamePageContents = ({ game }: { game: GameModel }) => {
  const { ref: fullscreenRef, requestFullscreen, isFullscreen, exitFullscreen } = useFullscreen();

  const [toolbarToggle, setToolbarToggle] = usePersistedValue('toolbarToggle', false);
  const [playerState, setPlayerState] = useState<string>('idle');
  const videoControls = useRef<HTMLDivElement>(null);

  const singleCameraRef = useRef<HTMLDivElement>(null);
  const { cognitoContext } = useContext(CognitoContext);

  const serverState = useServerStateContext();

  const {
    selectedCamera = null,
    selectCamera = () => {},
    viewType = 'single',
    availableCameras = [],
  } = serverState || {};

  useEffect(() => {
    // console.log('serverState', serverState);
  }, [serverState]);

  const clipState = useClipStateContext();

  const [toggleStatsVisibility, setToggleStatsVisibility] = useState(false);

  const { getValue, store } = usePTZStore();
  const { setValue } = usePTZSocket();

  const streamUrl = `wss://${cognitoContext?.sessionHandler}.aws.c360live.com/app/stream`;

  const { player } = usePlayer({
    elId: VIDEO_PLAYER_ID,
    streamUrl,
    handlers: {
      //@ts-ignore
      stateChanged: ({ prevstate, newstate }) => {
        setPlayerState(newstate);
      },
    },
  });

  useEffect(() => {
    if (clipState.state.isEditing) {
      setToolbarToggle(true);
    }
  }, [clipState.state.isEditing]);

  const gridSize = useMemo(
    () => ACCEPTABLE_GRID_SIZES.find((size) => size >= availableCameras.length) || 1,
    [availableCameras],
  );

  const gridEdgeSize = useMemo(() => Math.max(gridSize / 2, 1), [gridSize]);

  useCameraPtzKeyboardBindings((ptzDiff) => {
    if (viewType === ViewType.MOSAIC) {
      return;
    }

    if (typeof selectedCamera?.idx === 'undefined') return;

    const { zoom } = getValue?.(selectedCamera.idx) || { zoom: DEFAULT_ZOOM };

    const newValue = {
      pan: (ptzDiff.panDiff ?? 0) / interpolateStep(zoom),
      tilt: (ptzDiff.tiltDiff ?? 0) / interpolateStep(zoom),
      zoom: (ptzDiff.zoomDiff ?? 0) / ZOOM_FACTOR || 0,
    };

    setValue(newValue);
  });

  const playbackAvailable =
    playerState === 'playing' || playerState === 'paused' || playerState === 'loading';

  useEffect(() => {
    if (!availableCameras.length || !store) return;
    availableCameras.forEach((c) => {
      if (store[c.id] !== undefined) {
        c.pan = store[c.id].pan;
      }
    });
  }, [store]);

  const [showToolbar, setshowToolbar] = useState<boolean>(true);

  const hideToolbarTimeout = useRef<number | null>(null);

  const mouseIn = useRef(false);

  const show = useCallback(() => {
    setshowToolbar(true);

    if (hideToolbarTimeout?.current) {
      clearTimeout(hideToolbarTimeout.current);
    }

    hideToolbarTimeout.current = window.setTimeout(() => {
      if (!mouseIn.current) setshowToolbar(false);
    }, 2000);
  }, [setshowToolbar, hideToolbarTimeout, mouseIn]);

  const absolute: 'absolute' | 'relative' | 'fixed' = 'absolute';

  const gradientBackground = useMemo(
    () => ({
      position: absolute,
      width: '100%',
      left: 0,
      bottom: 0,
      height: '100%',
      backgroundImage:
        'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAC+CAYAAAD0iWUpAAAAAXNSR0IArs4c6QAAAa\
        JJREFUOE890PtrT3Ecx/H3mxnGMJvNLsZsZhvmtjsbYzP3lLSSpCQlSUlKkpKU1lJaStJKUvJH6vH5fp3zw7PX5f0+n885E\
        eXJCnTWgtwgLdhYR2aDvoHdFJHZaLTR+GZZwZYaMmKrYYgmWZPhbVTB9grNVLP37TC8ky3Y9R/ZEpnRwu6uI7NVVtBmrU2x\
        hy1od1q7rEPbIdsr62Q7FV1Ul3O7IyEyemQ92n01ZEavrJfa779AHKAg+nxlH3vQC/pl/YYH2AG7h2QFg+yg4rANiCHDQ9S\
        wbNhdRqgRc0dsHK0hIo5VGKVGFcepghPWIE5WOCUrOC0rGLM2Ro0rJtxggp2kJqkpasoNpl1oWjZjeIY9o4A86/MhZo3MKub\
        YOeqcjfMKyHlqXnuhwkUZ5IKNBXaRXfTmSxTEkgstUZdlV+rIuCqDvOZCENfriLhB3TRyy8ht9g67DHedcU9xn31APaQewWPD\
        T6inrvuMeg4vrL2kXtl4zb5h38I7eG/jA/WR+mRuhVqFz4ov1Br11UHfqO/wA9Z9zE8/+5e53/BH8fcfIHIrEq6e9rEAAAAASUVORK5CYII=")',
      display: 'flex',
      backgroundRepeat: 'repeat-x',
      backgroundPosition: 'bottom',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: toolbarToggle ? 1 : showToolbar ? 1 : 0,
      transition: `opacity ${showToolbar ? '0.1s' : '0.5s'} cubic-bezier(.4,0,1,1)`,
    }),
    [showToolbar, toolbarToggle],
  );

  const maxWidth = useMemo(() => {
    return `calc((100vh - ${
      (isFullscreen ? 0 : TOOLBAR_HEIGHT) +
      (clipState?.state.isEditing ? CLIP_MODE_ALERT_HEIGHT : 0)
    }px) * ${16 / 9})`;
  }, [isFullscreen, clipState?.state.isEditing]);

  return (
    <Box
      ref={fullscreenRef}
      sx={() => ({
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      })}
      onMouseEnter={() => show()}
      onMouseMove={show}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth, // keeps a 16/9 aspect ratio based on available height
          maxHeight: '100vh',
          position: 'relative',
        }}
        ref={singleCameraRef}
      >
        <WheelZoom>
          <Box id={VIDEO_PLAYER_ID} />
          {!playbackAvailable && <GameSlate game={game}></GameSlate>}
          {!isFullscreen && <div style={gradientBackground}></div>}
          
         
          {playbackAvailable && (
            <>
              {viewType === ViewType.MOSAIC && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    aspectRatio: '16/9',
                    display: 'grid',
                    gridTemplateColumns: `repeat(${gridEdgeSize}, 1fr)`,
                  }}
                >
                  {availableCameras.length > 0 &&
                    availableCameras.map((c) => (
                      <Box
                        key={c.id}
                        onClick={() => selectCamera(c, 'Video Player')}
                        sx={{
                          color: 'white',
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                          flexDirection: 'column',
                          fontWeight: 'bold',
                          fontSize: 26,
                          cursor: 'pointer',
                          position: 'relative',
                          transition: 'background-color 0.2s ease-in-out',
                          '&:hover': {
                            backgroundColor: 'rgba(0,0,0,0.1)',
                          },
                        }}
                      >
                        <Box
                          m={1}
                          sx={{
                            padding: '1px',
                            borderRadius: '4px',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            opacity: 0.2,
                            '&:hover': {
                              opacity: 1,
                            },
                            transition: 'opacity 0.2s ease-in-out',
                          }}
                        >
                          <RotatingIcon size={24} angle={c.pan - 45} />
                        </Box>
                        <Box
                          m={1}
                          sx={{
                            backgroundColor: 'rgba(0,0,0,0.4)',
                            padding: '5px',
                            borderRadius: '4px',
                            position: 'absolute',
                            bottom: 100,
                            right: 0,
                          }}
                        >
                          <Typography fontSize={10}>{c.name} </Typography>
                        </Box>
                      </Box>
                    ))}
                </Box>
              )}

              {viewType === ViewType.SINGLE_CAMERA && (
                <Box
                  m={1}
                  sx={{
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    padding: '5px',
                    borderRadius: '4px',
                    bottom: toolbarToggle || showToolbar ? 100 : 20,
                    position: 'absolute',
                    opacity: toolbarToggle || showToolbar ? 100 : 20,
                    right: 0,
                    transition: 'bottom 0.2s ease-in-out',
                  }}
                >
                  <Typography fontSize={12}>{selectedCamera?.name}</Typography>
                </Box>
              )}
            </>
          )}
        </WheelZoom>

        {playbackAvailable && (
          <Box
            position="absolute"
            sx={{
              bottom: 0,
              right: 0,
              width: '100%',
              opacity: toolbarToggle || showToolbar ? 1 : 0,
              transition: (theme) => `opacity ${showToolbar ? '0.1s' : '0.5s'} ease-in-out`,
            }}
            ref={videoControls}
          >
            <PlayerControls
              requestFullscreen={requestFullscreen}
              exitFullscreen={exitFullscreen}
              isFullscreen={isFullscreen}
              onStatsToggle={() => setToggleStatsVisibility(!toggleStatsVisibility)}
              onVolumeChange={(volume) => player?.setVolume(volume)}
              onMuteToggle={(isMuted) => player?.setMute(isMuted)}
              onToolBarToggle={(event) => {
                event.stopPropagation();
                setToolbarToggle(!toolbarToggle);
              }}
              toolbarToggle={toolbarToggle}
              show={toolbarToggle || showToolbar}
            />
          </Box>
        )}
      </Box>

      {/* this has to be inside of the fullscreen component so that it will show in fullscreen mode */}
      <KeyboardShortcutsDialog />
    </Box>
  );
};

export default GamePageContents;
