import { Cameras } from './CameraSettingsState';

/** Value used on the server to represent that no tracking ID is set. */

export enum ViewType {
  MOSAIC = 'mosaic',
  AUTO_TALLY = 'autoTally',
  SINGLE_CAMERA = 'single',
  NONE = 'none',
}

export type ServerState = {
  cameras: Cameras;
  selectedCamera: number;
  viewType: ViewType;
};

export type VideoSource = ViewType;
