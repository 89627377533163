



const isRoleAllowed = (userRole: string, requiredRole: string): boolean => {
    

    // Split roles into parts
    const userParts = userRole.split(':');
    const requiredParts = requiredRole.split(':');

    // If user role has a wildcard, check if it's a parent of the required role
    if (userRole.endsWith('*')) {
        const userPrefix = userParts.slice(0, -1).join(':');
        const requiredPrefix = requiredParts.slice(0, userParts.length - 1).join(':');
        return requiredPrefix === userPrefix;
    }

    // If required role has a wildcard, check if user role is a child of it
    if (requiredRole.endsWith('*')) {
        const requiredPrefix = requiredParts.slice(0, -1).join(':');
        return userRole.startsWith(requiredPrefix + ':') || userRole === requiredPrefix;
    }

    // Otherwise, roles must match exactly
    return userRole === requiredRole;
};

export const hasAccess = (userRoles, requiredRoles) => {
 
    // Check if any of the user's roles match any of the required roles

    const result =   requiredRoles.every((requiredRole) =>
        userRoles.some((userRole) => isRoleAllowed(userRole, requiredRole))
    );

    return result;
}
