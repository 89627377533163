import { useQuery } from '@tanstack/react-query';

import { get } from '@truefit/http-utils';

import { GameTypeMetadataModel } from '../../features/autoIso/types';
import useCurrentGameId from './useCurrentGameId';
import { isInvalid } from '../services/isInvalid';

export const getQueryKey = (gameId: number) => ['Games', gameId, 'TypeMetadata'];

const useGameTypeMetadataQuery = () => {
  const gameId = useCurrentGameId();

  return useQuery({
    queryKey: getQueryKey(gameId),
    queryFn: async () => {
      const response = await get<GameTypeMetadataModel>(`games/${gameId}/metadata`);
      return response.data;
    },
    staleTime: 60 * 60 * 1000, // Data considered fresh for 30 seconds
    gcTime: 60 * 60 * 1000, // Data will be garbage collected after 5 minutes
    refetchOnWindowFocus: !isInvalid(gameId, 'string'), // refetch ONLY if enabled and gameId is valid
    enabled: !isInvalid(gameId, 'string'),
  });
};

export default useGameTypeMetadataQuery;
