

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { del } from '@truefit/http-utils';


export type UserDeleteModel = {
    userId?: number;
}

export const useDeleteUserMutation = (orgId:number) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (model: UserDeleteModel):Promise<void> => {
            const response = await del(`/users/${model.userId}`);
            return response.data;
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['AllUsers', orgId] })
    });
};

export default useDeleteUserMutation;
