import { calculateBitrate } from "./calculateBitrate";

export function printWebRtcReport(report: RTCStatsReport) {

    try {
        const res: any = {};
        report.forEach((report: any) => {
            // Process 'inbound-rtp' type for video
            if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
                const bitrate = calculateBitrate(report);
                const bitrateMbps = Number.parseFloat((bitrate / 1000000).toFixed(2));
                res['bitrate'] = bitrateMbps;
            }

            // Process other stats like jitter, FPS, and frames dropped
            Object.entries(report).forEach(([key, value]) => {
                if (key === 'jitter') {
                    res['jitter'] = value;
                } else if (key === 'framesPerSecond') {
                    res['FPS'] = value;
                } else if (key === 'framesDropped') {
                    res['framesDropped'] = value;
                }
            });
        });

        if(window.webrtcLogs) console.debug(res)
    } catch (e) {
        return;
    }
}