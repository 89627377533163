import { Container, Divider, Typography } from '@mui/material';

import { AppPage } from '@/shared/components';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import UserManagement from '@/features/UserManagement';

const OrganizationSettingsPage = () => {
  const title = 'Organization Settings';

  useDocumentTitle(title);

  return (
    <AppPage title={title} requiredPermissions={['menu:manage:org']}>
      <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
          Manage your organization settings, including user management and organization members
        </Typography>
        <Divider sx={{ marginTop: 3 }} />
        <UserManagement />
      </Container>
    </AppPage>
  );
};

export default OrganizationSettingsPage;
