import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { UserStateContext } from '@/shared/components/UserStateProvider';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { formatStart } from '@/shared/services/formatStart';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Container, Divider, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppPage from '../../../shared/components/PageComponents/AppPage';
import { useGamesQuery } from '../hooks/useGameQuery';
import { GameModel } from '../types';
import GameCard from './GameCard';
import GamesForm, { DEFAULT_VALUES, FormValues as GamesFormValues } from './GamesForm';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { LoadingSpinner } from '@/shared/components/LoadingSpinner';


const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const GamesPage = () => {
  const [formValues, setFormValues] = useState<GamesFormValues>(DEFAULT_VALUES);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [boxY, setBoxY] = useState(0);

  const boxRef = useRef<HTMLDivElement>(null);
  const userContext = useContext(UserStateContext);

  const gamesQuery = useGamesQuery(formValues);

  const total = useMemo(() => gamesQuery.data?.pages[0].total, [gamesQuery.data]);

  const allGames = useMemo(
    () => gamesQuery.data?.pages.flatMap((p) => p.results),
    [gamesQuery.data],
  );
  const hasMore = useMemo(
    () => gamesQuery.hasNextPage && !gamesQuery.isError,
    [gamesQuery.hasNextPage, gamesQuery.isError],
  );
  const isLoading = useMemo(
    () => gamesQuery.isLoading || gamesQuery.isFetchingNextPage,
    [gamesQuery.isLoading, gamesQuery.isFetchingNextPage],
  );

  const { filteringByDate } = useFlags();

  const handleFilterAndSortChange = (values: GamesFormValues) => {
    setFormValues(values);
  };

  useEffect(() => {
    // Function to update the height on resize
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Add the resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const title = 'Games';
  useDocumentTitle(title);

  useEffect(() => {
    if (boxRef.current) {
      setBoxY(boxRef.current.getBoundingClientRect().top);
    }
  }, [boxRef.current]);

  const [gameBuckets, setGameBuckets] = useState<
    Record<
      string,
      {
        bucketFields: { year: number; month: number; day: number };
        games: GameModel[]; // Changed from Set to array
        date: Date;
      }
    >
  >({});

  const [gamesContainerHeight, setGamesContainerHeight] = useState(0);
  const tz = useMemo(() => userContext?.userProfile?.tz, [userContext?.userProfile?.tz]);

  useEffect(() => {
    if (boxY && windowHeight) {
      setGamesContainerHeight(windowHeight - boxY - 110);
    }
  }, [boxY, windowHeight]);

  useEffect(() => {
    if (!allGames) return;

    const updatedBuckets: Record<
      string,
      {
        bucketFields: { year: number; month: number; day: number, monthStr : string };
        games: GameModel[]; // Changed from Set to array
        date: Date;
      }
    > = {};

    // Process games and create buckets
    allGames.forEach((game) => {
      const gameStartTimeStamp = new Date(game.startTimestamp);
      const formattedStartDate = formatStart(gameStartTimeStamp, tz, 'short');

      if (!updatedBuckets[formattedStartDate]) {
        updatedBuckets[formattedStartDate] = {
          bucketFields: {
            year: gameStartTimeStamp.getFullYear(),
            month: gameStartTimeStamp.getMonth(),
            monthStr: months[gameStartTimeStamp.getMonth()],
            day: gameStartTimeStamp.getDate(),
          },
          games: [], // Initialize as empty array
          date: gameStartTimeStamp,
        };
      }

      // Check if game already exists in the bucket to prevent duplicates
      const gameExists = updatedBuckets[formattedStartDate].games.some(
        (existingGame) => existingGame.id === game.id,
      );

      if (!gameExists) {
        updatedBuckets[formattedStartDate].games.push(game);
      }
    });

    // Sort games within each bucket if needed
    Object.keys(updatedBuckets).forEach((date) => {
      updatedBuckets[date].games.sort(
        (a, b) => new Date(a.startTimestamp).getTime() - new Date(b.startTimestamp).getTime(),
      );
    });

    setGameBuckets(updatedBuckets);
  }, [allGames, tz]);

  return (
    <AppPage title={title} requiredPermissions={['menu:games']}>
      <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">Select a game</Typography>
        <Typography variant="body1">
          Choose between live and recorded games for a customized viewing experience.
        </Typography>
        <Divider sx={{ marginTop: 3 }} />

        <GamesForm onChange={handleFilterAndSortChange} available={true} />
        <Box
          sx={{ display: 'flex', justifyContent: 'left' }}
        >
          {total !== undefined ? (
            <Typography variant="h6">
              {total === 0 ? 'No games found.' : total > 1 ? `${total} Games` : `${total} Game`}
            </Typography>
          ) : null}

          {gamesQuery.isError ? (
            <Typography color="error" sx={{ textAlign: 'center' }}>
              An error ocurred while fetching games.
            </Typography>
          ) : null}
        </Box>

        <Box
          ref={boxRef}
          sx={{
            height: `${gamesContainerHeight}px`, // Set the height to make the container scrollable
          }}
        >
          <PerfectScrollbar>
            {isLoading && <LoadingSpinner text={'loading ...'} color={'warning'} />}
            {filteringByDate &&
              Object.keys(gameBuckets).map((date, index) => (
                <Box key={date} sx={{ width: '100%' }}>
                  <Box
                    key={`d-${date}`}
                    sx={{
                      position: 'absolute',
                      marginTop: 1,
                      width: '200px',
                    }}
                  >
                    <Typography variant="h6">{gameBuckets[date].bucketFields.monthStr}</Typography>
                    <Typography variant="h3" sx={{ fontWeight: 100 }}>
                      {gameBuckets[date].bucketFields.day}
                    </Typography>
                    <Typography variant="body1">{gameBuckets[date].bucketFields.year}</Typography>
                  </Box>
                  {index > 0 && <Divider sx={{ marginBottom: 2, marginTop: 2 }} />}
                  <Box
                    sx={{
                      display: 'flex',
                      marginLeft: 14,
                      marginTop: 1,
                      gap: 2,
                      flexWrap: 'wrap',
                      alignContent: 'flex-start',
                    }}
                  >
                    {gameBuckets[date].games.map((game: GameModel) => (
                      <GameCard
                        key={game.id}
                        game={game}
                        cardWidth={444}
                        cardHeight={220}
                        // Apply a fade-in animation only to new games
                      />
                    ))}
                  </Box>
                </Box>
              ))}
            <Box
              sx={{
                textAlign: 'center',
                height: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {hasMore && (
                <Tooltip title="Load more ...">
                  <IconButton
                    onClick={() => {
                      gamesQuery.fetchNextPage();
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </PerfectScrollbar>
        </Box>
      </Container>
    </AppPage>
  );
};

export default GamesPage;
