import { useInfiniteQuery } from '@tanstack/react-query';

import { get } from '@truefit/http-utils';
import { SessionData } from '../../../types/types';

export function getQueryKey(orgId:number) {
  return ['AllUsers', orgId];
}
const LIMIT = 5;
const fetchUserSessions = async ({ pageParam = null }) => {
  const urlSearchParams = new URLSearchParams();

  if (pageParam) {
    urlSearchParams.set('cursor', pageParam);
    urlSearchParams.set('limit', LIMIT.toString());
  }

  const { data } = await get<SessionData[]>(`user/sessions?${urlSearchParams.toString()}`);
  return data;
};

const getNextPageParam = (lastPage:any) => {
  return lastPage.hasNextPage ? lastPage.nextCursor : undefined;
};

export const useAccountSessionsQuery = () => {
  return useInfiniteQuery({
    queryKey: ['userSessions'],
    queryFn: fetchUserSessions,
    enabled: true,
    initialPageParam: 0,
    getNextPageParam,
  });
};

export default useAccountSessionsQuery;
