import { useQuery, useQueryClient } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import { isUUID } from 'validator';
import { useEffect } from 'react';

// Define proper types for camera data
export interface Camera {
  id: string;
  name: string;
  url: string;
  isActive: boolean;
  position?: string;
  type?: string;
}

export type CamerasResponse = {
  cameras: Camera[];
  totalCount: number;
};

const defaultResponse: CamerasResponse = { cameras: [], totalCount: 0 };

export const getQueryKey = (gameId: string) => ['Games', gameId, 'Cameras'];

const useCamerasQuery = () => {
  const gameId = useCurrentGameId();
  const queryClient = useQueryClient();
  const isValidGame = gameId ? isUUID(gameId) : false;
  
  // Effect to remove invalid queries
  useEffect(() => {
    if (!isValidGame) {
      queryClient.resetQueries({ queryKey: getQueryKey(gameId || '') });
    }
  }, [gameId, isValidGame, queryClient]);
  
  return useQuery({
    queryKey: getQueryKey(gameId || ''),
    queryFn: async (): Promise<CamerasResponse> => {
      if (!gameId || !isValidGame) {
        return defaultResponse;
      }
      
      try {
        return await get<CamerasResponse>(`games/${gameId}/cameras`);
      } catch (error) {
        return defaultResponse;
      }
    },
    enabled: isValidGame,
    gcTime: Infinity,
    placeholderData: defaultResponse,
    retry: 1,
    staleTime: 30000, // 30 seconds
  });
};

export default useCamerasQuery;