import { format, parseISO } from 'date-fns';

const displayWallClock = (date: Date, options?: { includeSubseconds?: boolean }) => {
  try {
    const formatStr = options?.includeSubseconds ? 'h:mm:ss.SSS aaa' : 'h:mm:ss aaa';
    const dateObj = typeof date === 'string' ? parseISO(date) : date;
    return format(dateObj, formatStr).toUpperCase();
  } catch (e) {
    console.error(`date is ${date} and typeof date is ${typeof date}`);
    return 'INVALID DATE';
  }
};

export default displayWallClock;