import React from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';
import { green, grey, purple, red, orange, amber } from '@mui/material/colors';
import { Squircle, LoaderCircle } from 'lucide-react';

interface ClipStatusProps {
  status: string;
  onClickHandler?: () => void;
}

const getChipStatusColor = (status: string): string => {
  switch (status.toUpperCase()) {
    case 'READY':
      return green['A700'];
    case 'ERROR':
      return red[700];
    case 'CANCELED':
      return amber[500];
    case 'PENDING':
      return orange[700];
    case 'UNPROCESSED':
      return grey[700];
    case 'PROCESSING':
      return purple[700];
    default:
      return purple[700];
  }
};

const getBorderColor = (status: string): string => {
  switch (status.toUpperCase()) {
    case 'READY':
      return green['A200'];
    case 'ERROR':
      return red[300];
    case 'CANCELED':
      return amber[100];
    case 'PENDING':
      return orange[300];
    case 'UNPROCESSED':
      return grey[300];
    case 'PROCESSING':
      return grey[200];
    default:
      return purple[300];
  }
};

const ClipStatus: React.FC<ClipStatusProps> = ({ status, onClickHandler }) => {
  const color = getChipStatusColor(status);
  const borderColor = getBorderColor(status);
  const title = status.charAt(0).toUpperCase() + status.slice(1);
  
  const isProcessing = status.toUpperCase() === 'PROCESSING';

  // Create a style object with the animation
  const iconStyle = isProcessing ? {
    animation: 'spin 2s linear infinite',
  } : {};

  return (
    <>
      {/* Add the keyframes to the document */}
      {isProcessing && (
        <style>
          {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
      )}
      
      <MuiTooltip color={borderColor} title={title} arrow onClick={onClickHandler}>
        <span style={{ display: 'inline-block' }}>
          {isProcessing ? (
            <LoaderCircle 
              color={borderColor}
              style={iconStyle}
            />
          ) : (
            <Squircle color={borderColor} fill={color} />
          )}
        </span>
      </MuiTooltip>
    </>
  );
};

export default ClipStatus;