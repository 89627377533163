interface Interval {
    start: number;
    end: number;
}

function findContainingIntervals(
    a1: number,
    a2: number,
    intervals: Interval[]
): {
    start: number | null;
    end: number | null;
} {
    if (a1 >= a2 || a1 < 0) {
        throw new Error('Invalid input: a1 must be less than a2 and both must be positive');
    }

    if (intervals.length < 2) {
        throw new Error('At least 2 intervals are required');
    }

    const result = {
        startInterval: null as number | null,
        endInterval: null as number | null
    };

    // Find interval containing a1
    for (let i = 0; i < intervals.length; i++) {
        if (a1 >= intervals[i].start && a1 <= intervals[i].end) {
            result.startInterval = i;
            break;
        }
    }

    // Find interval containing a2
    for (let i = 0; i < intervals.length; i++) {
        if (a2 >= intervals[i].start && a2 <= intervals[i].end) {
            result.endInterval = i;
            break;
        }
    }

    return {
        start: intervals[result.startInterval as number].start,
        end: intervals[result.endInterval as number].end
    };
}


interface Interval {
    start: number;
    end: number;
}

function buildIntervals(totalSeconds: number): {
    xsmall: Interval[]; // 60s intervals
    small: Interval[];   // 300s intervals
    medium: Interval[]; // 900s intervals
    large: Interval[];  // 1800s intervals
} {
    const createIntervalSet = (intervalSize: number): Interval[] => {
        const intervals: Interval[] = [];
        let current = 0;

        while (current < totalSeconds) {
            intervals.push({
                start: current,
                end: Math.min(current + intervalSize - 1, totalSeconds)
            });
            current += intervalSize;
        }

        return intervals;
    };

    return {
        xsmall: createIntervalSet(60),
        small: createIntervalSet(300),
        medium: createIntervalSet(900),
        large: createIntervalSet(1800)
    };
}

function findMatchingIntervals(
    v1: number,
    v2: number,
    intervals: {xsmall: Interval[], small: Interval[]; medium: Interval[]; large: Interval[]; }
): { small?: Interval; medium?: Interval; large?: Interval; } {
    const result: {xsmall?: Interval, small?: Interval; medium?: Interval; large?: Interval; } = {};
    const duration = v2 - v1;

    if (v1 < 0 || v2 <= v1) return result;

    const xSmallInterval = duration <= intervals.xsmall[0].end ? intervals.xsmall[0] : undefined
    if (xSmallInterval) result.xsmall = xSmallInterval;

    const smallInterval = duration <= intervals.small[0].end ? intervals.small[0] : undefined
    if (smallInterval) result.small = smallInterval;

    const mediumInterval = duration <= intervals.medium[0].end ? intervals.medium[0] : undefined;
    if (mediumInterval) result.medium = mediumInterval;

    const largeInterval = duration <= intervals.large[0].end ? intervals.large[0] : undefined;
    if (largeInterval) result.large = largeInterval;

    return result;
}

export { findContainingIntervals, buildIntervals, findMatchingIntervals };