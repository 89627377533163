import React, { useEffect, memo } from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Stack, 
  Chip,
  styled,
  useTheme 
} from '@mui/material';
import { Place } from '@mui/icons-material';
import useIPLookupQuery from '../hooks/useIPLookup';
import { SessionData } from '@/types/user';

// Constants
const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZoneName: 'short',
};

// Styled components
const StatusChip = styled(Chip)(({ theme }) => ({
  '&.expired': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  '&.current': {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  }
}));

// Types
interface LocationDisplayProps {
  ipLookupData?: {
    city: string;
    country_name: string;
  };
  showNA?: boolean;
}

interface StatusPillProps {
  isExpired: boolean;
}

// Subcomponents
const LocationDisplay = memo(({ ipLookupData, showNA }: LocationDisplayProps) => {
  const theme = useTheme();
  
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Place sx={{ color: theme.palette.text.secondary }} />
      <Typography variant="body2" color="text.primary">
        {ipLookupData 
          ? `${ipLookupData.city}, ${ipLookupData.country_name}`
          : showNA ? 'N/A' : ''}
      </Typography>
    </Stack>
  );
});

const StatusPill = memo(({ isExpired }: StatusPillProps) => (
  <StatusChip
    size="small"
    label={isExpired ? 'Expired' : 'Current'}
    className={isExpired ? 'expired' : 'current'}
  />
));

// Utility functions
const formatDate = (date: Date): string => {
  return new Intl.DateTimeFormat(
    navigator.language,
    DATE_FORMAT_OPTIONS
  ).format(date);
};

// Main component
export const SessionInfoCard: React.FC<{ session: SessionData }> = memo(({ session }) => {
  const { sessionDetails, expired } = session;
  const ipLookup = useIPLookupQuery();
  const theme = useTheme();
  
  useEffect(() => {
    const ip = sessionDetails?.ip;
    if (ip) {
      ipLookup.mutate(ip);
    }
  }, [sessionDetails?.ip, ipLookup.mutate]);

  const formattedDate = React.useMemo(() => 
    formatDate(new Date(sessionDetails.createdAt)),
    [sessionDetails.createdAt]
  );

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="body1">
            {formattedDate}
          </Typography>
          
          {sessionDetails.computeInstance?.hostname && (
            <Typography 
              variant="body2" 
              color="text.secondary"
            >
              CXVH Instance: {sessionDetails.computeInstance.hostname}
            </Typography>
          )}
          
          <Stack 
            direction="row" 
            justifyContent="space-between" 
            alignItems="center" 
            sx={{ width: '100%', pt: 1 }}
          >
            <LocationDisplay 
              ipLookupData={ipLookup.data} 
              showNA={!sessionDetails?.ip} 
            />
            <StatusPill isExpired={expired} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
});

LocationDisplay.displayName = 'LocationDisplay';
StatusPill.displayName = 'StatusPill';
SessionInfoCard.displayName = 'SessionInfoCard';

export default SessionInfoCard;