import { Tooltip } from '@mui/material';
import VideocamTwoToneIcon from '@mui/icons-material/VideocamTwoTone';

export const cameraSize = 18;
export const activeScale = 1.2; // This will make the active icon 50% larger
interface CameraIconProps {
  camera: {
    name: string;
    angle: number;
  };
  xVal?: number;
  yVal?: number;
  theme: {
    palette: {
      error: {
        main: string;
      };
    };
  };
  isActive: boolean;
  onClick: () => void;
  isFlipped?: boolean;
  enabled?: boolean; // This prop is declared but not used in the component
}

export const CameraIcon = ({ camera, xVal, yVal, theme, isActive, onClick, isFlipped, enabled }:CameraIconProps) => {
  const cameraAngle = isFlipped ? 360 - camera.angle + 180 : 360 - camera.angle;

  return (
    <Tooltip title={camera.name} arrow>
      <VideocamTwoToneIcon
        onClick={enabled ? onClick : undefined}
        style={{
          position: 'absolute',
          left: xVal || 0,
          bottom: yVal || 0,
          transform: `rotate(${cameraAngle}deg) scale(${isActive ? activeScale : 1})`,
          width: cameraSize,
          height: cameraSize,
          cursor: enabled ? 'pointer' : 'default',
          color: isActive ? theme.palette.error.main : '#888888',
          opacity: enabled ? 1: 0,
          transition: 'all 0.3s ease', // Smooth transition for both color and size
          transformOrigin: 'center', // Makes the scaling happen from the center
        }}
      />
    </Tooltip>
  );
};