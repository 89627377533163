import './styles/styles.scss';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';
import { bugsnagApiKey, enableBugSnag, nodeEnv } from '@/shared/constants';

import { xsrfTokenCheck } from './features/scaffolding/services';
import Loading from './Loading';
// Root and Loading need to stay out here for HMR purposes
import Root from './Root';
import { launchDarkly } from './shared/constants';
import { configureHttp, configureStore } from './util';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

// setup
const appElement = document.getElementById('app');

if (!appElement) {
  throw new Error('Failed to find the app element');
}

const root = createRoot(appElement);
const store = configureStore();

configureHttp({});

if (enableBugSnag) {
  Bugsnag.start({
    apiKey: bugsnagApiKey,
    plugins: [new BugsnagPluginReact()],
    releaseStage: nodeEnv.startsWith('prod')
      ? 'production'
      : nodeEnv.startsWith('st')
        ? 'staging'
        : 'development',
  });
}

// render paths
const renderApplication = () => {
  (async () => {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: launchDarkly,
    });

    root.render(
      <LDProvider>
        <Root store={store} />
      </LDProvider>,
    );
  })();
};

const renderLoading = () => {
  root.render(<Loading onRender={renderApplication} />);
};

// boot
if (xsrfTokenCheck()) {
  renderApplication();
} else {
  renderLoading();
}
