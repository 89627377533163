import { DurationWithSubseconds } from '../types';

const displayDurationWithSubseconds = (
  duration: DurationWithSubseconds,
  includeSubseconds?: boolean,
) => {

  const display = [
    duration.years > 0 ? duration.hours : null,
    duration.weeks > 0 ? duration.hours : null,
    duration.days > 0 ? duration.hours : null,
    duration.hours > 0 ? duration.hours : 0,
    duration.minutes >0? duration.minutes : 0,
    duration.seconds > 0 ? duration.seconds : 0,
  ]
    .filter((n) => n !== null)
    .map((n) => `${n}`.padStart(2, '0'))
    .join(':');

  const result = includeSubseconds
    ? `${display}.${duration.subSecondPercent.toFixed(3).split('.')[1]}`
    : display;
    return result;
};

export default displayDurationWithSubseconds;
