import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { Delete, Edit, MoreVert, Send } from '@mui/icons-material';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { User } from '@/types/user';

const ActionMenu: React.FC<{
  currentUser: User;
  user: User;
  onEdit: () => void;
  onDelete: () => void;
  onDisable: () => void;
  onInvite: ()=> void;
  invitees: boolean
}> = ({ currentUser, user, onEdit, onDelete, onDisable,onInvite, invitees }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton edge="end" onClick={handleMenuOpen}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        {/* Edit User Group Action */}
        {invitees && (
          <MenuItem
            disabled={currentUser.id === user.id}
            onClick={() => {
              handleMenuClose();
              onInvite();
            }}
          >
            <ListItemIcon>
              <Send fontSize="small" />
            </ListItemIcon>
            <ListItemText>Resend Invite</ListItemText>
          </MenuItem>
        )}

        <MenuItem
          disabled={currentUser.id === user.id}
          onClick={() => {
            handleMenuClose();
            onEdit();
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit User</ListItemText>
        </MenuItem>

        <MenuItem
          disabled={currentUser.id === user.id}
          onClick={() => {
            handleMenuClose();
            onDisable();
          }}
        >
          <ListItemIcon>
            {user.disabled ? (
              <PersonAddAltIcon fontSize="small" />
            ) : (
              <NoAccountsIcon fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText>{user.disabled ? 'Enable' : 'Disable'} Access for User</ListItemText>
        </MenuItem>

        {/* Delete User Action */}
        <MenuItem
          disabled={currentUser.id === user.id}
          onClick={() => {
            handleMenuClose();
            onDelete();
          }}
          sx={{ color: 'error.main' }} // Error styling for destructive action
        >
          <ListItemIcon>
            <Delete fontSize="small" sx={{ color: 'error.main' }} />
          </ListItemIcon>
          <ListItemText>Remove User</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ActionMenu;
