import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { FormValues as GamesFormValues } from '../components/GamesForm';
import { GetGamesResult } from '../types/GetGamesResult';
import { fetchGames } from '../services/fetchGames';
import { isInvalid } from '@/shared/services/isInvalid';

export const PAGE_SIZE = 10;

export function getQueryKey(formValues: GamesFormValues) {
  return ['Games', { formValues }];
}

const getNextPageParam = (lastPage: GetGamesResult) =>
  lastPage.offset + lastPage.results.length < lastPage.total ? lastPage.offset + PAGE_SIZE : undefined;

export const useGamesQuery = (formValues: GamesFormValues) => {
  const queryClient = useQueryClient();
  const isQueryEnabled = !isInvalid(Number(formValues.leagueId), 'number') && 
                         !isInvalid(Number(formValues.orgId), 'number');
  
  // Effect to remove invalid queries from the cache
  useEffect(() => {
    if (!isQueryEnabled) {
      queryClient.removeQueries({ queryKey: getQueryKey(formValues) });
    }
  }, [formValues, isQueryEnabled, queryClient]);

  return useInfiniteQuery({
    queryKey: getQueryKey(formValues),
    queryFn: async ({ pageParam: offset = 0 }) => {
      const { data } = await fetchGames({ offset, limit: PAGE_SIZE, ...formValues });
      return data;
    },
    refetchOnWindowFocus: true,
    getNextPageParam,
    initialPageParam: 0,
    enabled: isQueryEnabled, // Disable the query if leagueId or orgId is invalid
  });
};