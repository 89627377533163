import { ClipType } from '@/features/clips/types';
import { Box, Tooltip } from '@mui/material';
import { green, blue } from '@mui/material/colors';
import { Triangle } from 'lucide-react';

const ICON_SIZE = 16;

export type Props = {
  marks: any;
  endPositionSeconds: number;
  handleView: any;
  getToolTipTitle: any;
};

export const BookmarkMarker = ({
  marks,
  endPositionSeconds,
  handleView,
  getToolTipTitle,
}: Props) => (
  <Box
    sx={{
      position: 'absolute',
      top: `${-ICON_SIZE}px`,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
    }}
  >
    {marks.map((mark) => (
      <Tooltip title={getToolTipTitle(mark)} key={`mark-${mark.id}`} placement="top">
        <Box
          onClick={() => handleView(mark.model)}
          sx={{
            transition: 'all 0.2s ease-in-out',
            position: 'absolute',
            cursor: 'pointer',
            left: mark.value
              ? `calc(${(mark.value / endPositionSeconds) * 100}% - ${ICON_SIZE / 2}px)`
              : 0,
            marginBottom: mark.editing ? 5 : undefined,
            pointerEvents: 'all',
            opacity: mark.editing ? 1 : 0.6,
            '&:hover': {
              transform: 'scale(1.5)',
              opacity: 1,
              cursor: 'pointer',
            },
          }}
        >
          {mark.type === ClipType.Bookmark ? (
            <Triangle
              style={{ transform: 'rotate(180deg)' }}
              size={ICON_SIZE}
              color={blue[900]}
              fill={blue[700]}
            />
          ) : (
            <Triangle
              style={{ transform: 'rotate(180deg)' }}
              size={ICON_SIZE}
              color={green[900]}
              fill={green[700]}
            />
          )}
        </Box>
      </Tooltip>
    ))}
  </Box>
);
