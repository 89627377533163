import { User } from '@/types/user';
import { useQuery } from '@tanstack/react-query';

import { get } from '@truefit/http-utils';


const getCurrentUserQuery = () =>
  useQuery({
    queryKey: ['User'],
    queryFn: async () => get<User>('user'),
    enabled: false,
    gcTime: 0,
  });

export default getCurrentUserQuery;
