import { useQuery, useQueryClient } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

import toClipModel from '../services/toClipModel';
import { ClipModelFromServer } from '../types';
import { isUUID } from 'validator';
import { useEffect } from 'react';

export const getQueryKey = (gameId: string) => ['Games', gameId, 'Clips'];

export const useCurrentGameClipsQuery = () => {
  const gameId = useCurrentGameId();
  const queryClient = useQueryClient();
  
  const queryKey = getQueryKey(gameId);
  const isValidGame = isUUID(gameId);

    // Effect to remove invalid queries
    useEffect(() => {
      if (!isValidGame) {
        queryClient.removeQueries({ queryKey});
      }
    }, [gameId, queryClient, isValidGame]);


  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const { data } = await get<ClipModelFromServer[]>(`games/${gameId}/clips`);
      return data.map((c) => toClipModel(c));
    },
    enabled: isValidGame,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0, // Treat data as immediately stale so it will refetch
  });
  
  // Add a method to invalidate and immediately refetch
  const invalidateAndRefetch = async () => {
    await queryClient.invalidateQueries({ queryKey });
    return queryClient.refetchQueries({ queryKey });
  };
  
  return {
    ...query,
    invalidateAndRefetch,
  };
};

export default useCurrentGameClipsQuery;