import { CognitoUserAttribute } from 'amazon-cognito-identity-js';

 
const findAttributeValue = (name: string, attributes: CognitoUserAttribute[]) =>
{
  
  
  return attributes.find((attribute) => attribute.getName() === name).getValue();
}

export default findAttributeValue;
