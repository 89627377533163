import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Tracking } from '@/shared/services/tracking';
import UserStateProvider from '@/shared/components/UserStateProvider';
import { isProduction } from '@/shared/constants';
import { getTimeApiData } from '@/shared/services/getTimeApiDate';

import { getTheme } from '../../../styles/theme';
import history from '../history';
import QueryClientProvider from './QueryClientProvider';
import Routes from './Routes';
import StyledSnackbarProvider from './StyledSnackbarProvider';

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const App = () => {
  const [mode, setMode] = React.useState<PaletteMode>('dark');
  const theme = React.useMemo(() => getTheme(mode), [mode]);

  function trackPageView() {
    Tracking.getInstance().navigation(window.location.pathname + window.location.search, {
      name: document.title,
    });
  }

  useEffect(() => {
    trackPageView();

    const unlisten = history.listen(trackPageView); // Track all subsequent pageviews
    return unlisten;
  }, [history]);

  useEffect(() => {
    getTimeApiData();
  }, []);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );
  
  return (
    <Router >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            {/* using StyledEngineProvider because of https://next.material-ui.com/guides/migration-v4/#style-library */}
            <StyledEngineProvider injectFirst>
              <StyledSnackbarProvider>
                <CssBaseline />
                <QueryClientProvider>
                  <UserStateProvider>
                    <Routes />
                  </UserStateProvider>
                  {!isProduction && <ReactQueryDevtools initialIsOpen={false} position="bottom" />}
                </QueryClientProvider>
              </StyledSnackbarProvider>
            </StyledEngineProvider>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </LocalizationProvider>
    </Router>
  );
};

export default App;
