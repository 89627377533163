import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Chip } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import ActionMenu from './ActionMenu';
import { colorTag } from '@/shared/services/colorTag';
import ZenDataGrid from '@/shared/components/ZenDataGrid';
import { User } from '@/types/user';
import { UserStateContext } from '@/shared/components/UserStateProvider';

interface UsersTableProps {
  users: User[];
  setSelectedUser?: (user: User) => void;
  setGroupOpen?: (open: boolean) => void;
  handleDelete: (id: number) => void;
  handleDisable: (id: number) => void;
  handleEditUserGroup: (user: User) => void;
  handleInvite?: (user: User) => void;
  height: number;
  disabledColumns?: string[];
  invitees: boolean
}

export const UsersTable: React.FC<UsersTableProps> = ({
  users,
  handleDelete,
  handleEditUserGroup,
  handleDisable,
  handleInvite,
  height,
  disabledColumns = [], // Default to empty array if not provided
  invitees
}) => {
  const { userProfile } = React.useContext(UserStateContext);
  
  if (!userProfile) {
    return null;
  }

  const allColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <>{`${params.row.firstName || ''} ${params.row.lastName || ''}`}</>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.value}
          {params.row.disabled && (
            <Chip label="Disabled" size="small" color="error" style={{ marginLeft: 8 }} />
          )}
          {params.row.id === userProfile.id && (
            <Chip label="You" size="small" color="primary" style={{ marginLeft: 8 }} />
          )}
        </>
      ),
    },
    {
      field: 'lastSeen',
      headerName: 'Last Seen',
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => {
        if (!params.value) {
          return '-';
        }
        return `${formatDistanceToNow(new Date(params.value))} ago`;
      },
    },
    {
      field: 'groups',
      headerName: 'Groups',
      flex: 1,
      renderCell: (params: GridRenderCellParams<string[]>) => (
        <>
          {params.value.map((group) => (
            <Chip
              key={group}
              label={group}
              size="small"
              style={{ marginRight: 4, color: colorTag(group).c, background: colorTag(group).b }}
            />
          ))}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      renderCell: (params: GridRenderCellParams<User>) => {
          if(params.row.id === userProfile.id) return null;
          return (   <ActionMenu
            invitees={invitees}  
            currentUser={userProfile}
            user={params.row}
            onEdit={() => handleEditUserGroup(params.row)}
            onDelete={() => handleDelete(params.row.id)}
            onDisable={() => handleDisable(params.row.id)}
            onInvite={() => handleInvite && handleInvite(params.row)}
          />)
      }
     
      ,
    },
  ];

  // Filter out disabled columns
  const filteredColumns = allColumns.filter((column) => !disabledColumns.includes(column.field));


  return (
    <div style={{ width: '100%', height }}>
      <ZenDataGrid
        sx={{
          '& .disabled-row': {
            color: (theme) => theme.palette.text.disabled,
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
        }}
        rows={users}
        columns={filteredColumns}
        getRowId={(row) => row.id}
        disableRowSelectionOnClick
        loading={users.length === 0}
        autoPageSize={true}
        pageSizeOptions={[0, 5, 10, 20]}
        getRowClassName={(params) => (params.row.disabled ? 'disabled-row' : '')}
        rowHeight={50}
      />
    </div>
  );
};

export default UsersTable;
