import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { signOut as signOutAction } from '../actions';
import useAuth from './useAuth';

const useSignOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate('/sign-in');
    }
  }, [isAuthenticated, history]);

  const signOut = () => {
    dispatch(signOutAction());
  };

  return {
    signOut,
  };
};

export default useSignOut;
