import { Navigate, Route, Routes } from 'react-router';
import { AccessDenied, NotFound, SystemError } from '@/shared/components';

import {
  ChangePasswordPage,
  ForgotPasswordPage,
  SignOutPage,
} from '../../authentication/components';
import ResetPasswordPage from '../../authentication/components/ResetPasswordPage';
import SignInPage from '../../authentication/components/SignInPage';
import { ClipsPage } from '../../clips/components';
import { GamePage, GamesPage } from '../../games/components';
import AuthenticateRoute from './AuthenticateRoute';
import { OrganizationSettingsPage, AccountSettingsPage } from '../../settings/components';
import ManageGames from '@/features/settings/components/ManageGames';
import { GroupManagement } from '@/features/GroupManagement';
import ApiKeyManagementPage from '@/features/apiKey/components/ApiKeyManagementPage';
import ArenaEditor from '@/features/arena/components/ArenaEditor';
import ManageSessionsPage from '@/features/session/components/ManageSessionsPage';

export default () => (
  <Routes>
    <Route
      path="/organization-settings"
      element={
        <AuthenticateRoute>
          <OrganizationSettingsPage />
        </AuthenticateRoute>
      }
    />
    <Route
      path="/account-settings"
      element={
        <AuthenticateRoute>
          <AccountSettingsPage />
        </AuthenticateRoute>
      }
    />
    <Route path="/" element={<Navigate to="/games" />} />
    <Route
      path="/games/:gameId"
      element={
        <AuthenticateRoute>
          <GamePage />
        </AuthenticateRoute>
      }
    />
    <Route
      path="/games"
      element={
        <AuthenticateRoute>
          <GamesPage />
        </AuthenticateRoute>
      }
    />
    <Route
      path="/clips"
      element={
        <AuthenticateRoute>
          <ClipsPage />
        </AuthenticateRoute>
      }
    />
    <Route
      path="/manage-games"
      element={
        <AuthenticateRoute>
          <ManageGames />
        </AuthenticateRoute>
      }
    />
    <Route
      path="/manage-groups"
      element={
        <AuthenticateRoute>
          <GroupManagement />
        </AuthenticateRoute>
      }
    />
    <Route
      path="/camera-editor"
      element={
        <AuthenticateRoute>
          <ArenaEditor />
        </AuthenticateRoute>
      }
    />
      <Route
      path="/api-key"
      element={
        <AuthenticateRoute>
          <ApiKeyManagementPage />
        </AuthenticateRoute>
      }
    />
        <Route
      path="/manage-sessions"
      element={
        <AuthenticateRoute>
          <ManageSessionsPage />
        </AuthenticateRoute>
      }
    />
    <Route path="/sign-in" element={<SignInPage />} />
    <Route path="/sign-out" element={<SignOutPage />} />
    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
    <Route path="/reset-password" element={<ResetPasswordPage />} />
    <Route path="/change-password" element={<ChangePasswordPage />} />
    <Route path="/access-denied" element={<AccessDenied />} />
    <Route path="/system-error" element={<SystemError />} />

    {/* Catch-all 404 route */}
    <Route path="*" element={<NotFound />} />
  </Routes>
);
