import { addMilliseconds } from 'date-fns';

import { ClipType } from '../types';

export const toClipWithSyncedTimes = <
  TModel extends {
    id?: number;
    startTimestamp: Date;
    endTimestamp: Date;
    type: ClipType;
  },
>(
  model: TModel,
  playbackPositionTimestamp: Date,
): TModel => {
  if (model.type !== ClipType.Bookmark && model.id) return model;

  console.warn('this is a bookmark code, the endTimestamo will be 60 seconds after the playbackPositionTimestamp, modify the code for clip to take the minimum between the end of the content and the playbackPositionTimestamp + 60s');

  const endTimestamp = addMilliseconds(playbackPositionTimestamp, 60 * 1000)

  if (!model.id && model.type === ClipType.Clip) {
    return { ...model, endTimestamp };
  }

  return {
    ...model,
    startTimestamp:playbackPositionTimestamp,
    endTimestamp,
  };
};

export default toClipWithSyncedTimes;
