import useWebSocketBase from 'react-use-websocket';

import { GetWebsocketUrl } from '../../../util';

type WebSocketOptions<T> = {
  onMessage: (message: T) => unknown;
};

const useWebSocket = <T>(url: string, options?: WebSocketOptions<T>) => {
  const webSocketUrl = GetWebsocketUrl(url);

  const socket = useWebSocketBase(webSocketUrl, {
    filter: () => false, // prevents re-render on messages
    share: true,

    shouldReconnect: (e) => {

      // https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
      if (e.code === 1006) {
        // looks like code 1006 happens when the API closes the connection

        return true;
      }

      return false;
    },

    onMessage: ({ data }) => {
      if (options?.onMessage) {
        const message = JSON.parse(data);
        options.onMessage(message);
      }
    },

    onError: (e) => {
      //  console.error(e);
      return;
    },
  });

  const send = (message: T) => {
    const timestamp = new Date().getTime() * 1_000_000;
    socket.sendJsonMessage({ timestamp, ...message });
  };

  return { send };
};

export default useWebSocket;