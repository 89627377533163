import React from 'react';
import { Stack, Typography, Select, MenuItem } from '@mui/material';
import { Group } from '@/types/user';

interface DefaultRoleSectionProps {
  currentOrg: any; // Type this properly based on your org structure
  groups: Group[];
  onDefaultRoleChange: (groupId: number) => void;
}

export const DefaultRoleSection: React.FC<DefaultRoleSectionProps> = ({
  currentOrg,
  groups,
  onDefaultRoleChange,
}) => {
  if(!currentOrg) return <></>;
  return (
    <Stack direction="column" alignItems="left" spacing={2} p={3}>
      <Typography sx={{ minWidth: 400 }} variant="subtitle1">
        Default Members Role
      </Typography>
      <Typography sx={{ minWidth: 400 }} variant="body2">
        Set the default role for new members added to the organization.
      </Typography>
      <Select
        sx={{ maxWidth: 400, mt: 2, mb: 2 }}
        value={currentOrg?.defaultGroupId}
        onChange={(e) => onDefaultRoleChange(e.target.value as number)}
        fullWidth
      >
        {groups.map((group) => (
          <MenuItem key={group.id} value={group.id}>
            {group.name}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};
