import useClipStateContext from '@/shared/hooks/useClipStateContext';
import { useTimestampState } from '@/shared/hooks/websocket/useTimestamp';
import { displayWallClock } from '@/shared/services';
import { useTheme } from '@emotion/react';
import { styled, TooltipProps, Tooltip, Slider } from '@mui/material';
import { yellow, grey } from '@mui/material/colors';
import zIndex from '@mui/material/styles/zIndex';
import {
  addMilliseconds,
  addSeconds,
  differenceInMilliseconds,
  differenceInSeconds,
} from 'date-fns';
import { useState, useMemo, useEffect } from 'react';
import { getSliderStyle } from '../../video/utils/PlaybackPositionStyledComponents';
import { GamePlayBackPositions } from '../../video/components/PlayerControls';
import {
  buildIntervals,
  findContainingIntervals,
  findMatchingIntervals,
} from '@/shared/services/findCountainingIntervals';
import useDebouncedScrub from '@/shared/hooks/useDebouncedScrub';

// Types
interface ClipSliderProps {
  clipSliderRef?: React.RefObject<HTMLDivElement>;
  gamePlaybackPositions: GamePlayBackPositions;
  show: boolean;
  onMouseMove: (e: React.MouseEvent, playbackPosSeconds: number) => void;
}

export interface ClipSliderBoundaries {
  min: number;
  max: number;
}

// Styled Components
const CustomTooltip = styled(({ className, index, ...props }: { index: number } & TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, index }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: yellow[400],
    color: grey[900],
    padding: '4px 13px',
    fontSize: 13,
    //  borderRadius: index === 0 ? '5px 0px 0px 5px' : '0px 5px 5px 0px',
    borderRadius: '5px',
    boxShadow: '0 2px 8px rgba(255, 228, 54, 0.4)',
    zIndex: zIndex.tooltip,
    /* '&::before': {
      content: '""',
      position: 'absolute',
      top: 3,
      bottom: 1,
      width: '1px',
      height: '43px',
      backgroundColor: yellow[500],
      ...(index === 0
        ? {
            right: 1,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
          }
        : {
            left: 1,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          }),
    },*/
  },
}));

// Utility Functions
const formatTimeLabel = (date: Date, seconds: number): string => {
  if (Number.isNaN(seconds)) return '00:00:00';
  return displayWallClock(addSeconds(date, seconds));
};

const calculateEndPositionSeconds = (live: Date, start: Date): number =>
  Math.round(differenceInMilliseconds(live, start) / 1000);

// Components
const ValueLabel: React.FC<{
  children: React.ReactElement;
  value: string;
  index: number;
}> = ({ children, value, index }) => {
  const tooltipOffset = index === 0 ? [-47, 0] : [47, 0];

  return (
    <CustomTooltip
      index={index}
      open
      enterTouchDelay={0}
      placement="top"
      title={value}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: { offset: tooltipOffset },
            },
          ],
        },
      }}
    >
      {children}
    </CustomTooltip>
  );
};

export const ClipSlider: React.FC<ClipSliderProps> = ({
  gamePlaybackPositions,
  show,
  onMouseMove,
  zoom,
}) => {
  const theme = useTheme();
  const [, setTimestamp] = useTimestampState();
  const {
    state: { isEditingClip, editingModel },
    handleSetEndTimeOnSlider,
    handleSetStartTimeOnSlider,
  } = useClipStateContext();

  const endPositionSeconds = calculateEndPositionSeconds(
    gamePlaybackPositions.live,
    gamePlaybackPositions.start,
  );

  const [boundaries, setBoundaries] = useState<ClipSliderBoundaries>({
    min: 0,
    max: endPositionSeconds,
  });

  const clipValues = useMemo(
    () => ({
      start:
        isEditingClip && editingModel?.startTimestamp
          ? differenceInSeconds(editingModel.startTimestamp, gamePlaybackPositions.start)
          : 0,
      end:
        isEditingClip && editingModel?.endTimestamp
          ? differenceInSeconds(editingModel.endTimestamp, gamePlaybackPositions.start)
          : 0,
    }),
    [isEditingClip, editingModel, gamePlaybackPositions.start],
  );
  const intervals = useMemo(() => buildIntervals(endPositionSeconds), [gamePlaybackPositions]);

  const matchingIntervals = useMemo(
    () => findMatchingIntervals(clipValues.start, clipValues.end, intervals),
    [clipValues, intervals],
  );

  useEffect(() => {
    const defaultValue = { min: 0, max: endPositionSeconds };
    if (zoom === 1) {
      setBoundaries(defaultValue);
      return;
    }
    const intervalName: string = Object.keys(matchingIntervals).reverse()[zoom - 2];
    if (!intervalName || !intervals[intervalName]) {
      setBoundaries(defaultValue);
      return;
    }
    try {
      const computedBoundaries = findContainingIntervals(
        clipValues.start,
        clipValues.end,
        intervals[intervalName],
      );
      const min = (computedBoundaries.start as number) || 0;
      const max = (computedBoundaries.end as number) || endPositionSeconds;
      setBoundaries({ min, max });
    } catch (e) {
      console.error('Failed to compute boundaries', e);
      setBoundaries(defaultValue);
    }
  }, [matchingIntervals, zoom]);

  const handleScrub = useDebouncedScrub(
    endPositionSeconds,
    setTimestamp as any,
    gamePlaybackPositions,
  );

  const handleClipChange = (e: Event, values: number[], thumbIndex: number) => {
    const seconds = values[thumbIndex];
    const newTime = addMilliseconds(gamePlaybackPositions.start, seconds * 1000);

    if (thumbIndex === 0) {
      handleSetStartTimeOnSlider(newTime);
    } else {
      handleSetEndTimeOnSlider(newTime);
    }

    handleScrub(e, seconds);
  };

  const [valueLabelDisplay, setValueLabelDisplay] = useState<'on' | 'off'>('off');

  const step = useMemo(() => {
    const diff = boundaries.max - boundaries.min;
    if (diff <= 60) return 0.001;
    if (diff <= 300) return 1;
    if (diff <= 900) return 1;
    if (diff <= 1800) return 2;
    return 10;
  }, [boundaries]);

  return (
    <>
      <Slider
        aria-label="clipSlider"
        getAriaValueText={String}
        onChange={handleClipChange}
        size="small"
        sx={() => ({
          ...getSliderStyle(theme),
          color: yellow[500],
        })}
        min={boundaries.min}
        max={boundaries.max}
        value={[clipValues.start, clipValues.end]}
        valueLabelDisplay={valueLabelDisplay}
        valueLabelFormat={(seconds) => formatTimeLabel(gamePlaybackPositions.start, seconds)}
        onMouseMove={onMouseMove}
        onMouseLeave={setValueLabelDisplay.bind(null, 'off')}
        onMouseEnter={setValueLabelDisplay.bind(null, 'on')}
        slots={{
          valueLabel: ValueLabel,
        }}
        step={step}
      />
    </>
  );
};

export default ClipSlider;
