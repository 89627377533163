import { defaultGcTime, defaultStaleTime } from '@/shared/constants';
import { useQuery } from '@tanstack/react-query';

import { get } from '@truefit/http-utils';

export function getQueryKey(orgId) {
  return ['OrgGroups', orgId];
}

export const useOrgGroupsQuery = (orgId?: number) =>
  useQuery({
    queryKey: getQueryKey(orgId),
    queryFn: async () => {
      const { data } = await get<any[]>(`org/${orgId}/groups`);
      return data;
    },
    enabled: true,
    staleTime: defaultStaleTime, // Data considered fresh for 30 seconds
    gcTime: defaultGcTime, // Data will be garbage collected after 5 minutes
  });

export default useOrgGroupsQuery;
