function hexToRgb(hex) {
    // Remove the hash if present
    hex = hex.replace(/^#/, '');
    
    // Parse the hex values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    
    // Check if the parsing was successful
    if (isNaN(r) || isNaN(g) || isNaN(b)) {
      throw new Error('Invalid hex color format');
    }
    
    return { r, g, b };
  }
  
  function interpolateColor(color1, color2, factor) {
    const rgb1 = hexToRgb(color1);
    const rgb2 = hexToRgb(color2);
    
    const r = Math.round(rgb1.r + (rgb2.r - rgb1.r) * factor);
    const g = Math.round(rgb1.g + (rgb2.g - rgb1.g) * factor);
    const b = Math.round(rgb1.b + (rgb2.b - rgb1.b) * factor);
    
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  }
  
  export function generateGradient(color1, color2, transparency = 1, steps = 5) {
    // Validate inputs
    if (!color1 || !color2) {
      throw new Error('Both colors are required');
    }
    
    if (!color1.match(/^#[0-9A-Fa-f]{6}$/) || !color2.match(/^#[0-9A-Fa-f]{6}$/)) {
      throw new Error('Colors must be in valid hex format (#RRGGBB)');
    }
    
    if (transparency < 0 || transparency > 1) {
      throw new Error('Transparency must be between 0 and 1');
    }
    
    // Generate intermediate colors
    const colors = [];
    for (let i = 0; i < steps; i++) {
      const factor = i / (steps - 1);
      colors.push(interpolateColor(color1, color2, factor));
    }
    
    // Add transparency if less than 1
    const colorStrings = colors.map(color => {
      if (transparency === 1) {
        return color;
      }
      const rgb = hexToRgb(color);
      return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${transparency})`;
    });
    
    // Generate the gradient string
    return `linear-gradient(to left , ${colorStrings.join(', ')});`;
  }