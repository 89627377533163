export type TagColor = {
  b: string;
  c: string;
};
export function colorTag(str: string): TagColor {
  if (!str) {
    return { b: 'black', c: 'white' };
  }

  const tagString = str?.toLowerCase();

  const materialColors: Array<TagColor> = [
    { b: '#4A90E2', c: 'white' },  // Blue
    { b: '#9B59B6', c: 'white' },  // Purple
    { b: '#F5F7FA', c: 'black' },  // Light Gray
    { b: '#5DADE2', c: 'white' },  // Sky Blue
    { b: '#8E44AD', c: 'white' },  // Deep Purple
    { b: '#3498DB', c: 'white' },  // Ocean Blue
    { b: '#E74C3C', c: 'white' },  // Red
    { b: '#2ECC71', c: 'white' },  // Green
    { b: '#F1C40F', c: 'black' },  // Yellow
    { b: '#E67E22', c: 'white' },  // Orange
    { b: '#1ABC9C', c: 'white' },  // Turquoise
    { b: '#27AE60', c: 'white' },  // Emerald
    { b: '#8E44AD', c: 'white' },  // Violet
    { b: '#2980B9', c: 'white' },  // Royal Blue
    { b: '#F39C12', c: 'black' },  // Golden
    { b: '#16A085', c: 'white' },  // Sea Green
    { b: '#C0392B', c: 'white' }   // Dark Red
  ];


  // create the hex for the random string
  let hash = 0;
  for (let i = 0; i < tagString.length; i += 1) {
    hash = tagString.charCodeAt(i) + ((hash << 5) - hash);
  }

  return materialColors[Math.abs(hash) % materialColors.length];
}
