import { differenceInMilliseconds, Interval, intervalToDuration } from 'date-fns';

import displayDurationWithSubseconds from './displayDurationWithSubseconds';
import durationToMilliseconds from './durationToMilliseconds';

const displayInterval = (interval: Interval, includeMilliseconds?: boolean): string => {
  try {
  
    if (!interval.end) return '00:00:00';

    const duration = intervalToDuration(interval);
    const totalMilliseconds = differenceInMilliseconds(interval.end, interval.start);

    const milliseconds = includeMilliseconds
      ? totalMilliseconds - durationToMilliseconds(duration)
      : 0;

    return displayDurationWithSubseconds(
      { ...duration, subSecondPercent: milliseconds / 1000 },
      includeMilliseconds,
    );
  } catch (e) {
    console.error(e);
    return '00:00:00';
  }
};

export default displayInterval;
