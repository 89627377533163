import { getTeamLogoPath } from '@/shared/hooks/getTeamLogoPath';
import { Stack, Box, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { TeamModel } from '../types/TeamModel';
import { motion } from 'framer-motion'; // Import motion
import { getTeamColor } from '../functions/getTeamColor';
import useCurrentGameQuery from '@/features/games/hooks/useCurrentGameQuery';

type Props = {
  team: TeamModel;
};

export const TeamHeader = ({ team }: Props) => {
  const theme = useTheme();
  const currentGame = useCurrentGameQuery();

  const teamColor = useMemo(() => {
    const fallbackColor = '#000000';
    if (currentGame?.data) return getTeamColor(team, currentGame?.data, fallbackColor);
    return fallbackColor;
  }, [team, currentGame?.data]);
  
  const teamLogoPath = useMemo(() => getTeamLogoPath(team), [team]);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        backgroundImage: `linear-gradient( ${teamColor} 0%, ${teamColor.concat('00')} 100%)`,
        padding: 1, // Add padding for better visual spacing
        justifyContent: 'center',
        alignItems: 'center',
        width: '50px',
      }}
    >
      {/* Logo with animation */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <img loading="lazy" width="40" src={teamLogoPath} alt={team.abbreviation} />
      </motion.div>

      {/* Abbreviation with animation */}
      <motion.div
        style={{ height: '20%' }}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }} // Delay the abbreviation animation
      >
        <Box>
          <Typography variant="body1">{team.abbreviation}</Typography>
        </Box>
      </motion.div>

      {/* Name with animation */}
      <motion.div
        style={{ height: '50%' }}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }} // Delay the name animation
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            variant="caption"
            sx={{
              color: theme.palette.grey[400],
              transform: 'rotate(-90deg)', // Rotate text 90 degrees counterclockwise
              whiteSpace: 'nowrap', // Prevent text from wrapping
            }}
          >
            {team.name}
          </Typography>
        </Box>
      </motion.div>
    </Stack>
  );
};

export default TeamHeader;
