import { Box, Container, Divider, Typography } from '@mui/material';

import { AppPage } from '@/shared/components';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import ApiKeyManagementComponent from '@/features/apiKey/components/ApiKeyManagement';

const ApiKeyManagementPage = () => {
  const title = 'Clip Export Api Key';

  useDocumentTitle(title);

  return (
    <AppPage title={title} requiredPermissions={['menu:api']}>
      <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
          Clip export API key allows you to export your clips.
        </Typography>
        <Divider sx={{ marginTop: 3 }} />
        <Box sx={{ marginTop: 4 }}>
        <ApiKeyManagementComponent />
        </Box>
      </Container>
    </AppPage>
  );
};

export default ApiKeyManagementPage;
